import React, { useEffect, useRef, useState } from "react";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import InputDescription from "../../Components/SiteComponants/FormElements/InputDescription";
import SpecialButton from "../../Components/SiteComponants/SpecialButton";
import { getNotice, updateNotice } from "../../Firebase/Realtime/Notice";
import { notify } from "../../Datas/Functions/toast";
import { TitleSetup } from "../../Datas/Functions/titleSetup";
import moment from "moment";
import Textinput from "../../Components/SiteComponants/FormElements/Textinput";
import { useForm } from "react-hook-form";
import { getCommon, updateCommon } from "../../Firebase/Realtime/Common";
import { createCommon } from "./../../Firebase/Realtime/Common";

const CommonSection = (meta) => {
  TitleSetup({ ...meta });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    getCommon("social")
      .then((res) => {
        // console.log(res);
        reset(res.data);
      })
      .catch((err) => {});
  }, []);
  const onSubmit = (data) => {
    // console.log({ data });
    updateCommon(data, "social")
      .then((res) => {
        notify("Success", res.message, "success");
      })
      .catch((err) => {
        notify("Warning", err.message, "error");
      });
  };
  return (
    <div className="container">
      <SectionHeader heading={"SiteData Management"} className={"text-left"} />
      <BreadCrumbs texts={["Dashboard", "Manage Site Data"]} />
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 my-10 ">
        <div className="col-span-1">
          <div className="box p-5 box">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Textinput
                title={"Whatapp Number"}
                name={"whatsapp"}
                type="text"
                className={`border rounded w-full py-2 px-4 border-orange-300`}
                placeholder="Enter Whatsapp Number Example : (8801833534123)"
                register={register("whatsapp")}
              />
              <Textinput
                title={"Telegram Link"}
                name={"telegram"}
                type="text"
                className={`border rounded w-full py-2 px-4 border-orange-300`}
                placeholder="Enter Telegram Link"
                register={register("telegram")}
              />
              <Textinput
                title={"Facebook Link"}
                name={"facebook"}
                type="text"
                className={`border rounded w-full py-2 px-4 border-orange-300`}
                placeholder="Enter Facebook Link"
                register={register("facebook")}
              />
              <div className="text-center my-3">
                <SpecialButton text="Submit" type="submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonSection;
