const BreadCrumbs = ({ texts }) => {
  return (
    <div className={" my-5"}>
      <h2 className="text-xl   relative py-1 pr-16 inline-block">
        {texts.map((txt, i) =>
          i === texts.length - 1 ? (
            <span key={i}>{txt}</span>
          ) : (
            <span key={i} className="font-semibold">
              {txt + " / "}
            </span>
          )
        )}
      </h2>
    </div>
  );
};

export default BreadCrumbs;
