import React from "react";
import { TitleSetup } from "../Datas/Functions/titleSetup";
import { useState } from "react";
import ReviewTop from "../Components/ReviewSections/ReviewTop";
import ReviewBottom from "../Components/ReviewSections/ReviewBottom";
import SectionHeader from "../Components/SectionHeader/SectionHeader";
import { useEffect } from "react";
import { getReviews } from "../Firebase/Realtime/Review";
const Reviews = (meta) => {
  TitleSetup({ ...meta });
  const [userReviews, setUserReviews] = useState([]);
  useEffect(() => {
    getReviews()
      .then((res) => {
        // notify("Success", res.message, "success");
        setUserReviews(res.data.filter((rev) => rev.isAccepted));
      })
      .catch((err) => {
        // notify("Warning", err.message, "error");
      });
  }, []);
  return (
    <>
      <div className="container">
        <SectionHeader heading={"Reviews"} />
        <div className="grid grid-cols-1 md:gap-4">
          <ReviewTop />
          <ReviewBottom userReviews={userReviews} />
        </div>
      </div>
    </>
  );
};

export default Reviews;
