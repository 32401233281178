import React from "react";
import loading from "../../Imgs/loading.svg";
const LoadingBox = () => {
  return (
    <div className="text-center my-5 py-5">
      <img className="mx-auto" src={loading} alt="Loading Logo" />
    </div>
  );
};

export default LoadingBox;
