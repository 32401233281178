import { useEffect } from "react";
import { useSiteData } from "../../Contexts/SiteContext";

export function TitleSetup({ title = "", description = "", keywords = "" }) {
  const { setMetaInfo } = useSiteData();
  useEffect(() => {
    const newMetainfo = {
      title,
      description,
      keywords,
    };
    setMetaInfo(newMetainfo);
  }, [title, description, keywords]);
}
