import AddProduct from "../../Pages/DashboardPages/AddProduct";
import DashboardHome from "../../Pages/DashboardPages/DashboardHome";
import OrderList from "../../Pages/DashboardPages/OrderList";
import Profile from "../../Pages/DashboardPages/Profile";
import UserRoute from "../../Components/PrivateRoutes/UserRoute";
import AdminRoute from "../../Components/PrivateRoutes/AdminRoute";
import UserList from "./../../Pages/DashboardPages/UserList";
import ProductList from "../../Pages/DashboardPages/ProductList";
import ManageBanner from "../../Pages/DashboardPages/ManageBanner";
import ManageNotice from "./../../Pages/DashboardPages/ManageNotice";
import ManageFaq from "../../Pages/DashboardPages/ManageFaq";
import Invoice from "../../Pages/DashboardPages/Invoice";
import ManageReview from "../../Pages/DashboardPages/ManageReview";
import ChangePassword from "../../Pages/DashboardPages/ChangePassword";
import CommonSection from "../../Pages/DashboardPages/CommonSection";

export const dashboardRoutes = [
  {
    alias: "",
    title: "Dashboard",
    link: "",
    isAdminRoute: false,
    isPrivateRoute: true,
    isNavListed: true,
    comp: (
      <UserRoute>
        <DashboardHome
          title="Dashboard"
          description="Dashboard Home Page"
          keywords=""
        />
      </UserRoute>
    ),
    icon: "fa fa-gear",
  },
  {
    alias: "",
    title: "My Profile",
    link: "profile/",
    isNavListed: true,
    isAdminRoute: false,
    isPrivateRoute: true,
    comp: (
      <UserRoute>
        <Profile
          title="Dashboard : Profile "
          description="Profile Section of Thanos Gaming E-shop"
          keywords=""
        />
      </UserRoute>
    ),
    icon: "fa fa-user",
  },
  {
    alias: "List Section",
    title: "User Profile",
    link: "manage-user/:id",
    isAdminRoute: true,
    isPrivateRoute: true,
    isNavListed: false,
    comp: (
      <AdminRoute>
        <Profile
          title="Dashboard : Profile "
          description="User Profile Section"
          keywords=""
        />
      </AdminRoute>
    ),
    icon: "fa fa-user",
  },
  {
    alias: "List Section",
    title: "Change Password ",
    link: "change-password/",
    isAdminRoute: false,
    isPrivateRoute: true,
    isNavListed: false,
    comp: (
      <UserRoute>
        <ChangePassword
          title="Dashboard : Change Passsword "
          description="Password change section"
          keywords=""
        />
      </UserRoute>
    ),
    icon: "fa fa-lock",
  },
  {
    alias: "Manage Section",
    title: "Add New Product",
    link: "add-product/",
    isAdminRoute: true,
    isPrivateRoute: true,
    isNavListed: true,
    comp: (
      <AdminRoute>
        <AddProduct
          title="Dashboard : Add New Product "
          description="Product Management Page"
          keywords=""
        />
      </AdminRoute>
    ),
    icon: "fas fa-plus-circle",
  },
  {
    alias: "Manage Section",
    title: "Manage Product",
    link: "add-product/:pid",
    isAdminRoute: true,
    isPrivateRoute: true,
    isNavListed: false,
    comp: (
      <AdminRoute>
        <AddProduct
          title="Dashboard : Add Product "
          description="Product Management Page"
          keywords=""
        />
      </AdminRoute>
    ),
    icon: "fas fa-plus-circle",
  },
  {
    alias: "List Section",
    title: "Manage Products",
    link: "manage-product/",
    isAdminRoute: true,
    isPrivateRoute: true,
    isNavListed: true,
    comp: (
      <AdminRoute>
        <ProductList
          title="Dashboard : Product List "
          description="Product List management and view section"
          keywords=""
        />
      </AdminRoute>
    ),
    icon: "fa fa-list",
  },

  {
    alias: "List Section",
    title: "Order List",
    link: "manage-order/",
    isAdminRoute: false,
    isPrivateRoute: true,
    isNavListed: true,
    comp: (
      <UserRoute>
        <OrderList
          title="Dashboard : Order List "
          description="Order List Management section"
          keywords=""
        />
      </UserRoute>
    ),
    icon: "fa fa-list",
  },
  {
    alias: "List Section",
    title: "Manage Order",
    link: "manage-order/:id",
    isAdminRoute: true,
    isPrivateRoute: true,
    isNavListed: false,
    comp: (
      <UserRoute>
        <Invoice
          title="Dashboard : Order List "
          description="Order List Management section"
          keywords=""
        />
      </UserRoute>
    ),
    icon: "fa fa-list",
  },
  {
    alias: "List Section",
    title: "Manage User",
    link: "manage-user/",
    isAdminRoute: true,
    isPrivateRoute: true,
    isNavListed: true,
    comp: (
      <AdminRoute>
        <UserList
          title="Dashboard : User List "
          description="User list Section of thanos gaming E-shop"
          keywords=""
        />
      </AdminRoute>
    ),
    icon: "fa fa-user-plus",
  },
  {
    alias: "Manage Section",
    title: "Manage Banner",
    link: "manage-banner/",
    isAdminRoute: true,
    isPrivateRoute: true,
    isNavListed: true,
    comp: (
      <AdminRoute>
        <ManageBanner
          title="Dashboard : Manage Banner "
          description="Banner Management Page"
          keywords=""
        />
      </AdminRoute>
    ),
    icon: "fas fa-newspaper ",
  },
  {
    alias: "Manage Section",
    title: "Manage Notice",
    link: "manage-notice/",
    isAdminRoute: true,
    isPrivateRoute: true,
    isNavListed: true,
    comp: (
      <AdminRoute>
        <ManageNotice
          title="Dashboard : Manage Notice "
          description="Management of Notice section"
          keywords=""
        />
      </AdminRoute>
    ),
    icon: "fa fa-plus-square",
  },
  {
    alias: "Manage Section",
    title: "Manage FAQ",
    link: "manage-faq/",
    isAdminRoute: true,
    isPrivateRoute: true,
    isNavListed: true,
    comp: (
      <AdminRoute>
        <ManageFaq
          title="Dashboard : Manage FAQ "
          description="Frequently Asked Question management section"
          keywords=""
        />
      </AdminRoute>
    ),
    icon: "fa fa-question-circle",
  },
  {
    alias: "Manage Section",
    title: "Manage Review",
    link: "manage-review/",
    isAdminRoute: true,
    isPrivateRoute: true,
    isNavListed: true,
    comp: (
      <AdminRoute>
        <ManageReview
          title="Dashboard : Manage Review "
          description="Feedback management section"
          keywords=""
        />
      </AdminRoute>
    ),
    icon: "fa fa-star",
  },
  {
    alias: "Manage Section",
    title: "Manage SiteData",
    link: "manage-sitedata/",
    isAdminRoute: true,
    isPrivateRoute: true,
    isNavListed: true,
    comp: (
      <AdminRoute>
        <CommonSection
          title="Dashboard : Manage Review "
          description="Feedback management section"
          keywords=""
        />
      </AdminRoute>
    ),
    icon: "fa fa-coffee",
  },
];
