import React from "react";
import ReviewCard from "./ReviewCard";
// import Swiper core and required modules
import { Navigation } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import LoadingBox from "../SiteComponants/LoadingBox";
import { useState } from "react";
import { useEffect } from "react";

const ReviewBottom = ({ userReviews = [] }) => {
  const [avgStars, setAvgStars] = useState(0);
  useEffect(() => {
    const newAvgStars = (
      userReviews.reduce((res, review) => review.rating + res, 0) /
      userReviews.length
    ).toFixed(1);
    setAvgStars(newAvgStars);
  }, [userReviews]);
  return (
    <div className="mb-4 shadow-lg rounded-lg p-3 bg-front dark:dark-bg-front">
      <h2 className="text-4xl font-bold mb-2 text-center ">User Reviews</h2>
      <strong className="text-md mb-2 text-center block">
        <span className=" text-yellow-300">
          {avgStars}
          <i
            className={`fa fa-star  mx-1 text-yellow-300`}
            aria-hidden="true"
          ></i>
        </span>
        ({userReviews.length} Reviews)
      </strong>
      <div className="w-auto">
        <Swiper
          // install Swiper modules
          modules={[Navigation]}
          spaceBetween={50}
          // slidesPerView={4}
          navigation
          loop
          className="w-auto"
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
        >
          {userReviews.length > 0 ? (
            userReviews
              .sort((a, b) => Math.random() - 0.45)
              .map(
                (rev) =>
                  rev.isAccepted && (
                    <SwiperSlide key={rev.id} className="w-auto">
                      <ReviewCard rev={rev} />
                    </SwiperSlide>
                  )
              )
          ) : (
            <LoadingBox />
          )}
        </Swiper>
      </div>
    </div>
  );
};

export default ReviewBottom;
