import React from "react";

const SpecialButton = ({
  text = "",
  icon = "",
  type = "button",
  className = "",
  ...rest
}) => {
  return (
    <button
      type={type}
      {...rest}
      className={`relative inline-flex items-center px-6 md:px-12 text-center  py-3 overflow-hidden md:text-lg text-sm font-medium dark:dark-text border-2 border-orange-300 rounded-full group ${className}`}
    >
      <span className="absolute left-0 block w-full  transition-all  opacity-0 group-hover:opacity-100 bg-orange-500 h-full top-0 duration-300 ease"></span>
      <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform -translate-x-full group-hover:translate-x-0 opacity-0 group-hover:opacity-100 ease">
        {icon}
      </span>
      <span className="relative mx-auto group-hover:scale-110 duration-300">
        {text}
      </span>
    </button>
  );
};

export default SpecialButton;
