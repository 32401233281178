import React, { useEffect, useState } from "react";
import { changePassword, verifyAccount } from "../../Firebase/Authentication";
import { TitleSetup } from "../../Datas/Functions/titleSetup";
import { useForm } from "react-hook-form";
import Textinput from "../../Components/SiteComponants/FormElements/Textinput";
import SpecialButton from "../../Components/SiteComponants/SpecialButton";
import { notify } from "../../Datas/Functions/toast";

const ChangePassword = (meta) => {
  // setting up title of this page
  TitleSetup({ ...meta });
  //********** */
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [showpass, setShowpass] = useState(false);
  useEffect(() => {
    // verifyAccount()
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);
  const onSubmit = (data) => {
    if (data.newPass !== data.confirmNewPass) {
      notify("Warning", "Password not matched", "error");
      return;
    }
    changePassword(data.newPass)
      .then((res) => {
        notify("Success", res.message, "success");
      })
      .catch((err) => {
        notify("Warning", err.message, "error");
      });
    // console.log({ data });
  };
  return (
    <div className="container my-10">
      <div className="grid grid-cols-1">
        <div className="col-span-1">
          <div className="box p-5 w-full md:w-1/2 md:mx-auto ">
            <h1 className="text-3xl font-semibold text-center">
              Change Password
            </h1>
            <form
              className="w-full"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <Textinput
                title={"New Password"}
                name={"newPass"}
                type={showpass ? "text" : "password"}
                placeholder="Enter New Password"
                errors={errors}
                className={`border rounded w-full py-2 px-4 ${
                  errors.newPass ? "border-red-500" : "border-orange-300"
                }`}
                register={register("newPass", {
                  required: "Password is Required",
                  minLength: {
                    value: 6,
                    message: "Password must have 6 characters",
                  },
                })}
              />
              <Textinput
                title={"Confirm Password"}
                name={"confirmNewPass"}
                type={showpass ? "text" : "password"}
                placeholder="Confirm New Password"
                errors={errors}
                className={`border rounded w-full py-2 px-4 ${
                  errors.confirmNewPass ? "border-red-500" : "border-orange-300"
                }`}
                register={register("confirmNewPass", {
                  required: "Confirm Password is Required",
                })}
              />
              <p>
                <label>
                    {" "}
                    <input
                      type="checkbox"
                      name="showpass"
                      defaultValue={showpass}
                      className="scale-150 mr-4 ml-2"
                      onClick={(e) => setShowpass((prev) => !prev)}
                    />
                    {showpass ? "Hide Password" : "Show Password"}
                </label>
              </p>
              <div className="text-center my-4">
                <SpecialButton
                  type="submit"
                  text="Change Password"
                  className="mx-auto click-effect transition-all duration-300"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
