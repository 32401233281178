import React from "react";

const DashboardMiniCard = ({ text, value }) => {
  return (
    <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12  h-full">
      <div className="box py-3 md:py-10 bg-back dark:dark-bg-back shadow border box rounded-md  h-full p-5  font-mono">
        <h3 className="text-sm md:text-2xl">{text} </h3>
        <h3 className="text-sm md:text-2xl text-brand font-semibold">{value}</h3>
      </div>
    </div>
  );
};

export default DashboardMiniCard;
