import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import HoverItemText from "../SiteComponants/SmallComponents/HoverItemText";
import SpecialButton from "../SiteComponants/SpecialButton";
import { numToBDT } from "../../Datas/Functions/common";
import { Fade } from "react-swift-reveal";

const PopularCard = ({ prodInfo = {}, selectedPrice, setSelectedPrice }) => {
  const [product, setProduct] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setProduct(prodInfo);
  }, [prodInfo]);
  return (
    <div className="col-span-12 sm:col-span-6 md:col-span-4">
      <Fade>
        <div className="w-full min-h-full bg-front box dark:dark-bg-front mx-auto">
          <div
            onClick={() => navigate("/products/" + product?.id)}
            className="max-h-[200px] h-auto overflow-hidden relative group cursor-pointer"
          >
            <img
              className="object-contain rounded-t-lg max-h-full max-w-full mx-auto group-hover:scale-110 duration-200 -z-10"
              src={product?.photo}
              alt="product img"
            />
            <HoverItemText text={"View Product"} />
          </div>
          <div className="px-5 pb-5">
            <h5 className="text-base md:text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
              {product?.name}
            </h5>
            <div className="text-center">
              {product?.productInfo?.map((item, i) => (
                <button
                  key={i}
                  onClick={() =>
                    setSelectedPrice({ ...item, id: product?.id, key: i })
                  }
                  className={`${
                    selectedPrice?.key === i &&
                    product?.id === selectedPrice?.id
                      ? "border-orange-600 "
                      : ""
                  } border-2 px-2 py-1 rounded-md mr-3 my-2 text-xs md:text-sm hover:bg-orange-500`}
                >
                  {item.title}
                </button>
              ))}
            </div>
            <div className="text-center mt-3">
              {selectedPrice && product?.id === selectedPrice?.id && (
                <span className="text-xl font-bold border bg-orange-600 px-2 py-1 my-6 rounded-tr-lg rounded-bl-lg">
                  Price :{" "}
                  {numToBDT(product?.productInfo[selectedPrice?.key]?.price)}
                </span>
              )}
            </div>
            <div className="mt-3 text-center">
              <SpecialButton
                text="View Product"
                icon={
                  <i
                    className="fa fa-link text-gray-950"
                    aria-hidden="true"
                  ></i>
                }
                onClick={() => navigate("/products/" + product?.id)}
              />
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default PopularCard;
