import React from "react";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import { useState } from "react";
import InputDescription from "../../Components/SiteComponants/FormElements/InputDescription";
import { useRef } from "react";
import { useEffect } from "react";
import SpecialButton from "../../Components/SiteComponants/SpecialButton";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { TitleSetup } from "../../Datas/Functions/titleSetup";
import { getNotice, updateNotice } from "../../Firebase/Realtime/Notice";
import { notify } from "../../Datas/Functions/toast";
import moment from "moment";

const ManageNotice = (meta) => {
  TitleSetup({ ...meta });
  const [notice, setNotice] = useState("loading . . . ");
  const [lastUpdated, setLastUpdated] = useState(new Date().getTime());
  const noticeRef = useRef(null);
  useEffect(() => {
    updateNoticePreview();
  }, []);
  useEffect(() => {
    noticeRef.current.innerHTML = notice;
  }, [notice]);
  // get all faq everytime
  const updateNoticePreview = () => {
    getNotice()
      .then((res) => {
        setNotice(res.data.notice);
        setLastUpdated(res.data.created_at);
        //console.log({ notice: res });
      })
      .catch((err) => {
        //console.log(err);
        notify("Warning", err.message, "error");
      });
  };
  const onSubmit = (data) => {
    updateNotice(data)
      .then((res) => {
        notify("Success", res.message, "success");
      })
      .catch((err) => {
        notify("Warning", err.message, "error");
      });
  };
  return (
    <div className="container">
      <SectionHeader heading={"Notice Management"} className={"text-left"} />
      <BreadCrumbs texts={["Dashboard", "Manage Notice"]} />
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 my-10 ">
        <div className="col-span-1">
          <div className="box p-5 box">
            <InputDescription
              title={"Notice Board"}
              value={notice}
              setValue={setNotice}
              className={`border  placeholder:dark:text-white w-full`}
              placeholder="Write your Notice . . . "
            />
            <div className="text-center my-3">
              <SpecialButton
                text="Submit"
                onClick={() => onSubmit({ notice })}
              />
            </div>
          </div>
        </div>
        <div className="col-span-1 ">
          <div className="box w-full px-5 py-2">
            <h2 className="font-bold text-xl text-center py-3">Preview </h2>
            <div
              className="w-full description-output  dark:dark-bg-front bg-front mb-4  p-10 text-base text-primary-600  relative pl-10 border"
              role="alert"
              ref={noticeRef}
            ></div>
            <p className="text-center">
              Last Updated :{" "}
              {moment(lastUpdated).fromNow()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageNotice;
