import axios from "axios";
import icon from "./../../Imgs/brand_logo_transparent.png";
import { orderConfig } from "../SiteData/AllinOne";

export function notifyMe(msg = "new Notification", tag = "") {
  //  console.log(Notification.permission);
  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  } else {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        // console.log("first last");
        const notification = new Notification("Order notification", {
          icon,
          body: msg,
          tag,
        });
        notification.onclick = function (event) {
          event.preventDefault(); // prevent the browser from focusing the Notification's tab
          window.open(
            "https://thanoseshop.com/dashboard/manage-order/",
            "_blank"
          );
          // window.open(
          //   "http://localhost:3000/dashboard/manage-order/",
          //   "_blank"
          // );
        };
        window.navigator.vibrate(500);
      }
    });
  }
}

export function notifyInMail(data) {
  // return
  axios
    .post(orderConfig.backendURL + "/new-order", {
      data,
      apikey: orderConfig.mailEncryptionData,
    })
    .then((res) => {})
    .catch((err) => {});
}
