import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../../Contexts/AuthContext";

const AdminRoute = ({ children }) => {
  const { isAdmin, isLoggedin } = useAuthContext();
  const location = useLocation();
  if (!isAdmin) {
    if (isLoggedin)
      return (
        <Navigate
          to={location.state ? location.state.from : "/dashboard"}
          replace
        />
      );
    return <Navigate to={"/auth"} replace state={{ from: location }} />;
  }
  return children;
};

export default AdminRoute;
