export const emptyBannerValues = {
  title: "",
  src: "",
  link: "",
};
export const emptyFaqValues = {
  id: "",
  question: "",
  answer: "",
  category: "",
};
export const emptyProductValues = {
  id: "",
  name: "",
  photo: "",
  category: "",
  visibiity: "",
  customerInput: [],
  isPopular: false,
  productInfo: [{ title: "", price: "", prevPrice : "", stock: "Available" }],
  description: "",
};
