import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../../Contexts/AuthContext";

const PublicRoute = ({ children }) => {
  const { isLoggedin } = useAuthContext();
  const location = useLocation();
  
  return isLoggedin ? (
    <Navigate
      to={location.state ? location.state.from : "/dashboard"}
      replace
    />
  ) : (
    children
  );
};

export default PublicRoute;
