import React from "react";
import { TitleSetup } from "../Datas/Functions/titleSetup";
import { useForm } from "react-hook-form";
import SectionHeader from "../Components/SectionHeader/SectionHeader";
import { useState } from "react";
import { profileData, profileData2 } from "../Datas/ProfileDatas/selfProfile";
import { useAuthContext } from "../Contexts/AuthContext";
import loginPNG from "./../Imgs/login.png";
import { SignIn, SignUp } from "../Firebase/Authentication";
import { useSiteData } from "../Contexts/SiteContext";
import BreadCrumbs from "../Components/BreadCrumbs/BreadCrumbs";
import { useNavigate } from "react-router-dom";
import Textinput from "../Components/SiteComponants/FormElements/Textinput";
import { notify } from "../Datas/Functions/toast";
import SpecialButton from "../Components/SiteComponants/SpecialButton";
import { createUser } from "../Firebase/Realtime/Users";
const Login = (meta) => {
  TitleSetup({ ...meta });
  const { login, setUser } = useAuthContext();
  const { setIsLoading } = useSiteData();
  const navigate = useNavigate();
  const [showpass, setShowpass] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [authError] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    //console.log(data);
    if (!isLogin && data.password !== data.confirmPassword) {
      notify("Password not Matched", "", "error");
      return;
    }
    setIsLoading(true);
    if (isLogin) {
      SignIn(data.email, data.password)
        .then((res) => {
          notify("Success", res.message, "success");
          if (!!res.data) {
            const newUser = res.data;
            if (newUser.uid) {
              createUser(
                {
                  id: newUser.uid,
                  name: newUser.displayName,
                  email: newUser.email,
                  img: "",
                  auth: {
                    isAdmin: false,
                    isSuperAdmin: false,
                  },
                },
                newUser.uid
              )
                .then((res) => {
                  setUser(res.data);
                })
                .catch((err) => {
                  notify("Warning", err.message, "error");
                });
            } else {
              setUser(newUser);
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          notify("Warning", err.message, "error");
          setIsLoading(false);
        });
    } else {
      SignUp(data.email, data.password, data.name)
        .then((res) => {
          notify("Success", res.message, "success");
          //       console.log(res);
          setIsLoading(false);
          setIsLogin(true);
          reset();
        })
        .catch((err) => {
          notify("Warning", err.message, "error");
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="container my-5">
      <SectionHeader heading={isLogin ? "LOGIN" : "REGISTER"} />
      <BreadCrumbs texts={["Auth", isLogin ? "Login" : "Register"]} />
      <div className="grid grid-cols-1">
        <div className="col-span-1">
          <div className="box grid md:grid-cols-2 grid-cols-1 md:p-4  dark:dark-bg-front bg-front ">
            <div
              className={`w-full col-span-1 md:flex justify-center items-center px-6 my-auto mx-auto transition-all duration-700 z-10 hidden sm:block ${
                isLogin ? "" : "md:translate-x-full"
              } `}
            >
              <img
                className="w-auto mx-auto aspect-square"
                src={loginPNG}
                alt={"Login PNg"}
              />
            </div>
            <div
              className={`w-full  col-span-1 md:px-3 py-5 my-2 transition-all flex items-center flex-col justify-center duration-700 order-1  ${
                isLogin ? "" : "md:-translate-x-full"
              }`}
            >
              <h1 className="text-3xl font-semibold">
                {isLogin ? "Login Form" : "Register Form"}
              </h1>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="py-10 px-7 w-full md:w-10/12 mx-auto"
              >
                {!isLogin && (
                  <Textinput
                    title={"Name"}
                    name={"name"}
                    type={"text"}
                    icon={<i className="fa fa-user " aria-hidden="true"></i>}
                    placeholder="Enter your Name"
                    errors={errors}
                    className={`border rounded w-full py-2 px-4 ${
                      errors.name ? "border-red-500" : "border-orange-300"
                    }`}
                    register={register("name", {
                      required: "Name is required",
                    })}
                  />
                )}
                <Textinput
                  title={"Email"}
                  name={"email"}
                  type={"email"}
                  icon={<i className="fa fa-envelope" aria-hidden="true"></i>}
                  placeholder="Enter your Email"
                  errors={errors}
                  className={`border rounded w-full py-2 px-4 ${
                    errors.email ? "border-red-500" : "border-orange-300"
                  }`}
                  register={register("email", {
                    required: "Email is required",
                  })}
                />
                <Textinput
                  title={"Password"}
                  name={"password"}
                  type={showpass ? "text" : "password"}
                  icon={<i className="fa fa-lock" aria-hidden="true"></i>}
                  placeholder="Enter your Password"
                  errors={errors}
                  className={`border rounded w-full py-2 px-4 ${
                    errors.password ? "border-red-500" : "border-orange-300"
                  }`}
                  register={register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must have atleast 6 characters",
                    },
                  })}
                />
                {!isLogin && (
                  <Textinput
                    title={"Confirm Password"}
                    name={"confirmPassword"}
                    type={showpass ? "text" : "password"}
                    icon={<i className="fa fa-lock" aria-hidden="true"></i>}
                    placeholder="Confirm Your Password"
                    errors={errors}
                    className={`border rounded w-full py-2 px-4 ${
                      errors.confirmPassword
                        ? "border-red-500"
                        : "border-orange-300"
                    }`}
                    register={register("confirmPassword", {
                      required: "Confirm Password is required",
                      minLength: {
                        value: 6,
                        message: "Password must have atleast 6 characters",
                      },
                    })}
                  />
                )}
                <p>
                  <label>
                    <input
                      type="checkbox"
                      defaultValue={showpass}
                      className="scale-110 md:scale-150 mr-4 ml-2 my-2"
                      onClick={(e) => setShowpass((prev) => !prev)}
                    />
                    {showpass ? "Hide Password" : "Show Password"}
                  </label>
                </p>
                <div className="text-center mt-4 w-full">
                  <SpecialButton
                    className="w-[70%] md:w-a uto text-center"
                    icon={
                      <i className="fa fa-sign-in ml-2" aria-hidden="true"></i>
                    }
                    type="submit"
                    text={isLogin ? "Login" : "Register"}
                  />
                </div>
              </form>
              <div className="flex flex-wrap items-center justify-evenly flex-row  my-3 w-full">
                <p
                  className="text-orange-500 cursor-pointer "
                  onClick={() => setIsLogin((prev) => !prev)}
                  title={
                    isLogin
                      ? "Click if you dont have an account"
                      : "Click if you have an account"
                  }
                >
                  {isLogin ? "Create Account" : "Go to Login"}
                </p>
                <p
                  onClick={() => navigate("/forgot-password")}
                  className="text-orange-500 cursor-pointer"
                  title="CLick if you forgot your password"
                >
                  Forgot Password{" "}
                </p>
              </div>
              <p className="text-red-500 text-xs mt-1">{authError}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
