import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { getNotice } from "../../Firebase/Realtime/Notice";

const NoticeAlert = () => {
  const [isNoticeClosed, setIsNoticeClosed] = useState(false);
  const [notice, setNotice] = useState("");
  const noticeRef = useRef(null);
  useEffect(() => {
    if (notice) noticeRef.current.innerHTML = notice;
  }, [notice]);
  useEffect(() => {
    updateNoticePreview();
  }, []);
  // get all faq everytime
  const updateNoticePreview = () => {
    getNotice()
      .then((res) => {
        setNotice(res.data.notice);
        //   console.log({ notice: res });
      })
      .catch((err) => {
        //   console.log(err);
        // notify("Warning", err.message, "error");
      });
  };
  return (
    !!notice && (
      <div
        className={`duration-150 transition-all  w-full md:container origin-top p-2  mx-auto  my-1 ${
          isNoticeClosed && "hidden"
        }`}
      >
        <div className="dark:dark-bg-front bg-front mb-4 box pt-12 md:px-12 px-3 pb-6 text-primary-600  relative w-full block">
          <span className="absolute top-2 left-5 cursor-pointer text-base md:text-lg font-semibold text-brand pr-4">
            <i class="fa fa-exclamation-triangle text-red-600 mr-1" aria-hidden="true"></i>Important Notice 
          </span>
          <i
            className="fa fa-times absolute top-2 right-2 cursor-pointer w-5 h-5 hover:border hover:text-brand hover:border-orange-300  flex justify-center items-center duration-150 text-2xl"
            aria-hidden="true"
            onClick={() => setIsNoticeClosed(true)}
          ></i>
          <div className="w-full description-output text-justify  text-sm" ref={noticeRef}></div>
        </div>
      </div>
    )
  );
};

export default NoticeAlert;
