import React from "react";
import { TitleSetup } from "../Datas/Functions/titleSetup";
import ImageInfoBox from "../Components/ImageInfoBox/ImageInfoBox";
import NoticeAlert from "../Components/NoticeAlert/NoticeAlert";
import ItemSection from "../Components/ItemSection/ItemSection";
import { useState, useEffect } from "react";
import SliderBanner from "../Components/SliderBanner/SliderBanner";
import { useSiteData } from "../Contexts/SiteContext";
import PopularProducts from "../Components/PopulerCard/PopularProducts";
import Last5Order from "../Components/Last5Order/Last5Order";

const Home = (meta) => {
  const { categories } = useSiteData();

  const [showProducts, setShowProducts] = useState([]);

  useEffect(() => {
    setShowProducts(categories);
  }, [categories]);
  TitleSetup({ ...meta });
  return (
    <div className="w-full">
      <SliderBanner />
      <NoticeAlert />
      {showProducts.length > 0 &&
        showProducts.map((prodCat, i) => (
          <ItemSection
            key={i}
            heading={prodCat.title}
            showAll={true}
            prods={prodCat.prods}
            showCount={-1}
            badge={"Sales"}
          />
        ))}
      <PopularProducts />
      <Last5Order />
      <ImageInfoBox />
    </div>
  );
};

export default Home;
