import React from "react";
import { isIncluded } from "./../../Datas/Functions/shareFunctions";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../Contexts/AuthContext";
import { numToBDT } from "../../Datas/Functions/common";

const ProductPrices = ({ selectedItem, setSelecteditem, productItem }) => {
  const { id, productInfo } = productItem;
  const navigate = useNavigate();
  const { isAdmin } = useAuthContext();
  return (
    <div className="relative  bg-front dark:dark-bg-front box my-3">
      {isAdmin ? (
        <span
          className="absolute inline-block bottom-full right-0  px-2 py-1 cursor-pointer"
          onClick={() => [navigate("/dashboard/add-product/" + id)]}
        >
          <i className="fas fa-edit mr-2"></i>Edit Product
        </span>
      ) : (
        ""
      )}
      <h1 className="font-bold my-3 py-2 text-lg md:text-xl text-left block border-b-2 border-b-orange-400">
        <i className="fas fa-dollar-sign text-center mx-2  inline-block p-1 rounded-full bg-orange-500 text-white w-7 aspect-square  "></i>
        Select Price Info
      </h1>
      <div className="p-4">
        {/* <h2 className="text-xl font-bold block">Prices : </h2> */}
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-1  text-center">
          {productInfo?.map((pinf, i) => (
            <Tooltip
              key={i}
              title={
                isIncluded(pinf.stock, "unav") ? (
                  "Out of Stock"
                ) : (
                  <span>{numToBDT(pinf.price)} </span>
                )
              }
              arrow
            >
              <span className="col-span-1 block relative">
                {isIncluded(pinf.stock, "unav") ? (
                  <span className="text-xs text-white font-semibold z-10 absolute -top-1 right-1 md:px-2 px-1 md:py-1 rounded bg-gray-600 text-center">
                    Out of Stock
                  </span>
                ) : parseInt(pinf?.prevPrice) > parseInt(pinf.price) ? (
                  <span className="text-xs  font-semibold z-10 absolute -top-1 py-[1px] right-1 md:px-2 px-1  rounded bg-orange-600 text-center">
                    {(
                      ((parseInt(pinf?.prevPrice) - parseInt(pinf.price)) *
                        100) /
                      parseInt(pinf?.prevPrice)
                    ).toFixed(0) + "% OFF"}
                  </span>
                ) : (
                  ""
                )}

                <button
                  type="button"
                  className={`w-full overflow-hidden min-h-full relative border-2 dark:disabled:bg-gray-500  disabled:bg-gray-300 disabled:hover:cursor-not-allowed disabled:text-gray-800 my-1 mr-2  rounded py-0 cursor-pointer flex justify-center items-center flex-wrap flex-col ${
                    isIncluded(pinf.stock, "unav")
                      ? ""
                      : "hover:border-orange-500"
                  } ${
                    selectedItem?.key === i
                      ? "border-orange-600 border-2 text-brand"
                      : ""
                  }`}
                  key={i}
                  onClick={() => {
                    !isIncluded(pinf.stock, "unav") &&
                      setSelecteditem({ key: i, ...pinf });
                  }}
                  disabled={isIncluded(pinf.stock, "unavai")}
                >
                  <span className="text-xs  font-bold ">
                    <span className="text-[14px] md:text-base mr-1">
                      {pinf.title}
                    </span>{" "}
                    <br />
                    {!isIncluded(pinf.stock, "unav") && (
                      <>
                        {pinf?.prevPrice && (
                          <span className="text-gray-600 dark:text-gray-300 opacity-60 relative after:absolute after:top-1/2 after:left-0 after:w-full after:h-[2px]  after:opacity-40 after:bg-gray-900 ml-1 text-[10px]">
                            {pinf?.prevPrice}
                            <i className="mx-1 fa-solid fa-bangladeshi-taka-sign fa-sm"></i>
                          </span>
                        )}
                        <span className="text-brand text-[10px]">
                          {pinf.price}
                          <i className="mx-1 fa-solid fa-bangladeshi-taka-sign fa-sm"></i>
                        </span>
                      </>
                    )}
                  </span>
                </button>
              </span>
            </Tooltip>
          ))}
        </div>

        {selectedItem !== null && (
          <div>
            <button
              onClick={() => setSelecteditem(null)}
              className="px-2 border border-red-500 rounded text-sm flex flex-wrap items-baseline my-4"
            >
              Clear Selection
              <i
                className="fa fa-times text-red-700 ml-1"
                aria-hidden="true"
              ></i>
            </button>
            <div className="w-full py-2 text-sm md:text-xl text-center border rounded dark:dark-text font-bold">
              You have to pay
              <span className="mx-1 text-orange-400">
                {numToBDT(selectedItem?.price)}/=
              </span>
              to buy this product
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductPrices;
