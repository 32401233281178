import React, { useEffect, useState } from "react";
import { notify } from "../../Datas/Functions/toast";
import { useForm } from "react-hook-form";
import { useSiteData } from "../../Contexts/SiteContext";
import { useAuthContext } from "../../Contexts/AuthContext";
import { createOrder } from "../../Firebase/Realtime/Order";
import CustomerAccountInfo from "./CustomerAccountInfo";
import ProductPrices from "./ProductPrices";
import PaymentSection from "./PaymentSection";
import { paymentNumbers } from "../../Datas/SiteData/AllinOne";
import ProductDetailsBottom from "./ProductDetailsBottom";
import { Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import SpecialButton from "../SiteComponants/SpecialButton";
const ProductDetails = ({ productItem = {} }) => {
  const { setIsLoading, isDark } = useSiteData();
  const { user } = useAuthContext();
  const [selectedItem, setSelecteditem] = useState(null);
  const [isCheckOut, setIsCheckOut] = useState(false);

  const [accountNumber, setAccountNumber] = useState("");
  const [selectedPaymentMethod, setSelectedPayementMethod] = useState(
    paymentNumbers[0].value
  );
  const navigate = useNavigate();
  const { id, name, category, customerInput } = productItem;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (!isCheckOut) {
      setIsCheckOut((prev) => !prev);
      return;
    }
    if (!selectedItem) {
      notify("Warning", "You must Select a Price First", "error");
      return;
    }
    if (!user) {
      notify("Warning", "You must login first", "error");
      return;
    }
    if (accountNumber === "") {
      notify("Empty value", "Please Enter Your Account Number", "error");
      return;
    }
    setIsLoading(true);
    const userInput = Object.entries(data)?.map(([key, val]) => {
      return { key, val };
    });
    let finalOrderData = {
      order_status: "Proccessing",
      order_payment_method: selectedPaymentMethod,
      order_account: accountNumber,
      customer_id: user?.id,
      customer_name: user?.name,
      product_id: id,
      product_category: category,
      product_name: name,
      order_name: selectedItem.title,
      order_price: selectedItem.price,
      userInput,
      orderCancelReason: "",
    };
    // console.log({ finalOrderData });
    createOrder(finalOrderData)
      .then((res) => {
        notify("Order Submitted", "Thanks for Ordering", "success");
        Swal.fire({
          title: "Order Placed",
          text: "Thanks for Ordering . Please wait for order to get Completed",
          icon: "success",
          confirmButtonText: "Done",
          denyButtonText: "Give a review",
          background: isDark === "dark" ? "#111827" : "",
          showConfirmButton: true,
          showDenyButton: true,
        }).then((res) => {
          if (res.isDenied) {
            navigate("/reviews");
          }
        });
        reset();
        setAccountNumber("");
        setIsCheckOut(false);
        setSelectedPayementMethod(paymentNumbers[0].value);
        setIsLoading(false);
      })
      .catch((err) => {
        notify("Warning", err.message, "error");
        setIsLoading(false);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isCheckOut]);
  return (
    <div className="w-full ">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="grid grid-cols-1 justify-between relative overflow-hidden">
          <div
            className={`${
              // isCheckOut ? "translate-x-[10000px]" : "translate-x-0"
              isCheckOut
                ? "opacity-0 invisible max-h-0 -translate-y-[10000px]"
                : "opacity-100 visible max-h-full translate-y-0"
            }  duration-500 col-span-1 my-3 transition-all`}
          >
            <ProductPrices
              selectedItem={selectedItem}
              setSelecteditem={setSelecteditem}
              productItem={productItem}
            />
            {customerInput && customerInput.length > 0 && (
              <CustomerAccountInfo
                customerInput={customerInput}
                register={register}
                errors={errors}
              />
            )}
            <div className="text-right">
              <Tooltip
                title={
                  !selectedItem ? "Select a price First" : "Click to Proceed"
                }
                arrow
              >
                <span>
                  <button
                    type="submit"
                    disabled={!selectedItem}
                    className="py-1 px-3 bg-orange-500 text-gray-900 font-semibold rounded click-effect disabled:bg-gray-500"
                  >
                    Proceed to Payment
                    <i
                      className="fa fa-arrow-right ml-1"
                      aria-hidden="true"
                    ></i>
                  </button>
                </span>
              </Tooltip>
            </div>
            <ProductDetailsBottom productItem={productItem} />
            {/* review button section  */}
            <div>
              <Link to={"/reviews"}>
                <SpecialButton
                  text="Give A review"
                  icon={<i className="fa fa-star " aria-hidden="true"></i>}
                />
              </Link>
            </div>
          </div>
          <div
            className={`${
              // isCheckOut ? "translate-x-0" : "-translate-x-[10000px]"
              !isCheckOut
                ? "opacity-0 invisible max-h-0 translate-y-[10000px]"
                : "opacity-100 visible max-h-full translate-y-0"
            }  duration-500 col-span-1 transition-all`}
          >
            <div className="text-left">
              <button
                onClick={() => setIsCheckOut((prev) => !prev)}
                type="button"
                className="py-1 px-3 bg-orange-500 text-gray-800 font-semibold rounded click-effect"
              >
                <i className="fa fa-arrow-left mr-1" aria-hidden="true"></i>Back
                to Product
              </button>
            </div>
            <PaymentSection
              accountNumber={accountNumber}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPayementMethod={setSelectedPayementMethod}
              selectedItem={selectedItem}
              setAccountNumber={setAccountNumber}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              errors={errors}
              setIsCheckOut={setIsCheckOut}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProductDetails;
