import React from "react";

const MenuButton = ({ children, text = "Info" }) => {
  return (
    <div className="w-auto h-auto relative group">
      <button className="fake-action-btn border group-hover:visible flex items-center justify-between px-3 py-2 border-gray-400 rounded duration-300 active:scale-95 hover:bg-orange-600">
        <span className="mr-2">{text}</span>
        <span className="group-hover:rotate-180 duration-300">
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        </span>
      </button>
      <div
        className={` bg-front w-44 dark:dark-bg-front rounded border shadow-2xl z-40 absolute invisible transl ate-y-28 rota te-[-90deg] grou p-hover:rotate-0 opacity-0 sc ale-0 origin-top
        group-hover:visible group-hover:opacity-100 group-hover:trans late-y-0 group-hover:sc ale-100 
        top-full right-0 duration-300`}
      >
        {children}
      </div>
    </div>
  );
};

export default MenuButton;
