import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSiteData } from "../../Contexts/SiteContext";
import { notify } from "../../Datas/Functions/toast";
import { uploadImage } from "../../Datas/Functions/upload";
import Textinput from "./FormElements/Textinput";
import ImageInput from "./FormElements/ImageInput";
import Spinner from "./SmallComponents/Spinner";
import { createBanner, updateBanner } from "../../Firebase/Realtime/Banner";
import { emptyBannerValues } from "../../Datas/emptyValues";

const AddBannerSection = ({
  selectedBanner,
  setSelectedBanner,
  updateBannerTable,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [imgUrl, setimgUrl] = useState("");
  const [uploading, setUploading] = useState(false);

  const { setIsLoading } = useSiteData();
  const handleUploadImage = async (e) => {
    setUploading(true);
    const res = await uploadImage(e.target.files[0]);
    if (res.status === 200) setimgUrl(res.data.data.url);
    // if (res.status === 200) setimgUrl(res.data.data.url);
    else notify("Error", "Something Went Wrong", "error");
    //console.log(res);
    setUploading(false);
  };

  const onSubmit = (data) => {
    if (uploading) {
      notify("Warning", "Photo is not Uploaded yet", "error");
      return;
    }
    setIsLoading(true);

    // setting image in data
    data.src = imgUrl;

    if (!!selectedBanner ) {
     // console.log(data);
      updateBanner(data, data.id)
        .then((res) => {
          notify("Success", res.message, "success");
          setSelectedBanner(null);
          updateBannerTable();
          setIsLoading(false);
        })
        .catch((err) => {
          notify("Warning", err.message, "error");
          setIsLoading(false);
        });
    } else {
      createBanner(data)
        .then((res) => {
          notify("Success", res.message, "success");
          updateBannerTable();
          setIsLoading(false);
        })
        .catch((err) => {
          notify("Warning", err.message, "error");
          setIsLoading(false);
        });
    }
    setIsLoading(false);
    reset(emptyBannerValues);
    setimgUrl("");
  };
  useEffect(() => {
    if (!!selectedBanner) {
      reset({ ...selectedBanner });
      setimgUrl(selectedBanner.src);
    }
  }, [selectedBanner]);
  return (
    <div className="col-span-12 md:col-span-5 ">
      <div className="rounded p-7 dark:dark-bg-front bg-front box">
        <h1 className="text-center text-2xl font-bold">Banner Data</h1>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Textinput
            title={"Banner Title"}
            name={"title"}
            type="text"
            errors={errors}
            className={`border rounded w-full py-2 px-4 ${
              errors.title ? "border-red-500" : "border-orange-300"
            }`}
            placeholder="Enter Banner Image Title"
            register={register("title", {
              required: "Banner Title is required",
            })}
          />
          {imgUrl !== "" && (
            <div className="w-full aspect-video p-4 text-center">
              <p>Banner Image</p>
              <img
                className="max-w-full max-h-full mx-auto"
                src={imgUrl}
                alt="Selected img"
              />
            </div>
          )}
          <ImageInput
            title={"Banner Image"}
            name={"src"}
            errors={errors}
            className={`border rounded w-full py-2 px-4 ${
              errors?.src ? "border-red-500" : "border-orange-300"
            }`}
            placeholder="Upload Your Photo"
            register={register("src", {
              required: !imgUrl ? "Photo is required" : false,
            })}
            onChange={handleUploadImage}
          />

          <Textinput
            title={"Banner Youtube/ Other Link"}
            name={"link"}
            type="text"
            errors={errors}
            className={`border rounded w-full py-2 px-4 ${
              errors.link ? "border-red-500" : "border-orange-300"
            }`}
            placeholder="Enter Backgrond Link / Youtube Link"
            register={register("link")}
          />
          <button
            className="site-btn px-6 py-2 w-full disabled:cursor-not-allowed"
            disabled={uploading}
          >
            {uploading && <Spinner />}{" "}
            {uploading ? "Image uploading" : "Add Banner"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddBannerSection;
