import React, { useEffect, useRef } from "react";

const ProductDetailsBottom = ({ productItem }) => {
  const prodDescRef = useRef();
  useEffect(() => {
    // prodDescRef.current.innerHTML = productItem?.description;
    prodDescRef.current.innerHTML = productItem.description;
  }, [productItem]);
  return (
    <div className="relative  bg-front dark:dark-bg-front box my-3">
      <h1 className="font-bold my-3 py-2 text-lg md:text-xl text-left block border-b-2 border-b-orange-400">
        <i className="fas fa-dollar-sign text-center mx-2  inline-block p-1 rounded-full bg-orange-500 text-white w-7 aspect-square  "></i>
        Product Details
      </h1>
      <div className="px-4 py-1">
        <div className="my-1 description-output " ref={prodDescRef}></div>
      </div>
    </div>
  );
};

export default ProductDetailsBottom;
