import React, { useEffect, useState } from "react";
import { useSiteData } from "../../Contexts/SiteContext";
import SectionHeader from "../SectionHeader/SectionHeader";
import PopularCard from "./PopularCard";
import { extractPopularProduct } from "./../../Datas/Functions/shareFunctions";

const PopularProducts = () => {
  const [popularProd, setPopularProd] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const { products } = useSiteData();
  useEffect(() => {
    const newPopularprod = extractPopularProduct(products);
    setPopularProd(newPopularprod);
  }, [products]);
  return (
    popularProd?.length > 0 && (
      <div className="my-6">
        <SectionHeader heading={"Populer Products"} className={"text-center"} />
        <div className="w-full md:container mx-auto  p-2">
          <div className={`grid grid-cols-12  gap-4 md:gap-6  auto-rows-auto`}>
            {popularProd.map(
              (pprd, i) =>
                pprd?.visibility === "Public" && (
                  <PopularCard
                    key={i}
                    prodInfo={pprd}
                    selectedPrice={selectedPrice}
                    setSelectedPrice={setSelectedPrice}
                  />
                )
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default PopularProducts;
