import React from "react";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import AddBannerSection from "../../Components/SiteComponants/AddBannerSection";
import BannerListSection from "../../Components/SiteComponants/BannerListSection";
import BreadCrumbs from "./../../Components/BreadCrumbs/BreadCrumbs";
import { TitleSetup } from "../../Datas/Functions/titleSetup";
import { useState } from "react";
import { getBanners } from "../../Firebase/Realtime/Banner";
import { notify } from "../../Datas/Functions/toast";

const ManageBanner = (meta) => {
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [bannerList, setBannerList] = useState([]);
  TitleSetup({ ...meta });
  
  // update the banner list
  const updateBannerTable = () => {
    getBanners()
      .then((res) => {
  //      console.log({ res });
        setBannerList(res.data);
      })
      .catch((err) => {
        notify("Warning", err.message, "error");
      });
  };
  return (
    <div className="container">
      <SectionHeader heading={"Banner Management"} />
      <BreadCrumbs texts={["Dashboard", "Manage Banner"]} />
      <div className="grid grid-cols-12 gap-4 my-10 ">
        <AddBannerSection
          selectedBanner={selectedBanner}
          setSelectedBanner={setSelectedBanner}
          updateBannerTable={updateBannerTable}
        />
        <BannerListSection
          setSelectedBanner={setSelectedBanner}
          updateBannerTable={updateBannerTable}
          bannerList={bannerList}
        />
      </div>
    </div>
  );
};

export default ManageBanner;
