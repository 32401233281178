import {
  child,
  get,
  getDatabase,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import { app } from "./../firebase.config";
import { uuid } from "../../Datas/Functions/common";
import { getAuth } from "firebase/auth";

const database = getDatabase(app);
const auth = getAuth(app);
const mainPath = "notice/"


// Notice Creation create (this function used only once while development to set the id )
// i have used it, now it is commented out
// export const createNotice = (data) => {
//   return new Promise((res, err) => {
//     const id = "single_notice";
//     data.id = id;
//     data.created_at = new Date().getTime();
//     try {
//       set(ref(database, mainPath + id), data);
//       return res({
//         message: "Notice Successfully uploaded",
//       });
//     } catch (error) {
//       return err({
//         message: "Something went wrong",
//       });
//     }
//   });
// };

// Notice update
export const updateNotice = (data) => {
  return new Promise((res, err) => {
    const id = "single_notice";
    data.created_at = new Date().getTime();
    try {
      update(ref(database, mainPath + id), data);
      return res({
        message: "Notice Successfully updated",
      });
    } catch (error) {
      return err({
        error,
        message: "Something went wrong",
      });
    }
  });
};

// Notice delete
//   export const deleteNotice = (id) => {
//     return new Promise((res, err) => {
//       if (!id) {
//         return err({
//           message: "Item is not Seleted or not valid",
//         });
//       }
//       try {
//         remove(ref(database, mainPath + id));
//         return res({
//           message: "Selected Notice is successfully deleted",
//         });
//       } catch (error) {
//         return err({
//           message: "Something went wrong",
//         });
//       }
//     });
//   };

// get The Notice
export const getNotice = () =>
  new Promise((res, err) => {
    get(child(ref(database), mainPath))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const bannerSnap = snapshot.val();
          return res({
            message: "Notice data fetched",
            data: Object.values(bannerSnap)[0],
          });
        } else {
          err({
            message: "No data found",
          });
        }
      })
      .catch((error) => {
        err({
          message: "Something went wrong",
        });
      });
  });
