import React from "react";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import { Link, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Tooltip } from "@mui/material";
import FakeTableComponent from "../../Components/FakeTableComponent/FakeTableComponent";
import NoItemFound from "../../Components/SiteComponants/NoItemFound";
import moment from "moment/moment";
import { useAuthContext } from "../../Contexts/AuthContext";
import { notify } from "../../Datas/Functions/toast";
import { clickToCopy, isIncluded } from "../../Datas/Functions/shareFunctions";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { TitleSetup } from "../../Datas/Functions/titleSetup";
import MenuButton from "../../Components/SiteComponants/MenuButton";
import { useSiteData } from "../../Contexts/SiteContext";
import Swal from "sweetalert2";
import { deleteOrder, updateOrder } from "../../Firebase/Realtime/Order";
import { swalColors } from "./../../Datas/SiteData/colorRelated";
import { numToBDT } from "../../Datas/Functions/common";

const OrderList = (meta) => {
  // setting up title of this page
  TitleSetup({ ...meta });
  //********** */
  const { user, isAdmin } = useAuthContext();
  const { orderList, setOrderList, isDark } = useSiteData();

  const [searchedText, setSearchedText] = useState("");
  const [selectedItem, setSelectedItem] = useState("order_account");
  const [datalist, setDatalist] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();

  const column = [
    {
      name: "Paid from",
      selector: (row) => (
        <Tooltip title={"Paid to : " + row.order_payment_method} arrow>
          <span
            onClick={() => {
              clickToCopy(row.order_account);
              notify("Payment Account Copied to clipboard", "", "success");
            }}
          >
            {row.order_account}
          </span>
        </Tooltip>
      ),
    },
    {
      name: "Purchased Item",
      selector: (row) => (
        <div>
          <p>
            {row.order_name} - ({numToBDT(row.order_price)} )
          </p>
        </div>
      ),
    },
    {
      name: "Product Name",
      selector: (row) => row.product_name,
      sortable: "product_name",
    },
    {
      name: "Purchased",
      selector: (row) => {
        const time = moment(parseInt(row.created_at)).format(
          "MMMM Do YYYY, hh:mm:ss"
        );
        return (
          <Tooltip title={time}>
            <span>{moment(parseInt(row.created_at)).fromNow()}</span>
          </Tooltip>
        );
      },
      sortable: "created_at",
    },
    {
      name: "Details",
      selector: (row) =>
        isAdmin || isIncluded(row.order_status, "com") ? (
          <Link className="nav-item active inline-block" to={"" + row.id}>
            View Info
          </Link>
        ) : isIncluded(row.order_status, "canc") ? (
          <span
            onClick={() => {
              Swal.fire({
                text: row.orderCancelReason
                  ? row.orderCancelReason
                  : "Not Available",
                showConfirmButton: true,
                confirmButtonText: "Ok",
                background: isDark === "dark" ? swalColors.darkBgFront : "",
                color: isDark === "dark" ? swalColors.textDark : "#000000",
              });
            }}
            className="nav-item active inline-block px-1"
          >
            Cancel Reason
          </span>
        ) : (
          <span className="inline-block">N/A</span>
        ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          {isAdmin ? (
            <div>
              {isIncluded(row.order_status, "proc") ? (
                <div className="flex flex-wrap">
                  <MenuButton text={"Action"}>
                    <ul className="cursor-pointer ">
                      <li
                        className="border-y py-2 px-4 flex justify-between hover:bg-gray-300 hover:text-gray-900"
                        onClick={() => handleCompleteOrder(row)}
                      >
                        <span>
                          <i
                            className="fa fa-check text-green-500"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span>Complete</span>
                      </li>
                      <li
                        className="border-y py-2 px-4 flex justify-between hover:bg-gray-300 hover:text-gray-900"
                        onClick={() => handleCancelOrder(row)}
                      >
                        <span>
                          <i
                            className="fa fa-times text-red-500"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span>Cancel</span>
                      </li>
                    </ul>
                  </MenuButton>
                </div>
              ) : (
                <span
                  className={`font-semibold ${
                    row.order_status === "Completed"
                      ? "text-green-600"
                      : row.order_status === "Canceled"
                      ? "text-red-600"
                      : "text-yellow-700"
                  }`}
                >
                  {row.order_status}
                  <span
                    className="mx-1 px-1 click-effect"
                    onClick={() => handleDeletingOrder(row)}
                  >
                    <i
                      className="fa fa-trash hover:text-red-800 duration-150"
                      aria-hidden="true"
                    ></i>
                  </span>
                </span>
              )}
            </div>
          ) : (
            <span
              className={`font-bold ${
                row.order_status === "Completed"
                  ? "text-green-600"
                  : row.order_status === "Canceled"
                  ? "text-red-600"
                  : "text-yellow-700"
              }`}
            >
              {row.order_status}
            </span>
          )}
        </div>
      ),
    },
  ];

  const selectionOption = [
    {
      title: "Product Name",
      value: "product_name",
    },
    {
      title: "Invoice ID",
      value: "id",
    },
    {
      title: "Order Status",
      value: "order_status",
    },
    {
      title: "Account Number",
      value: "order_account",
    },
    {
      title: isAdmin ? "Customer Name" : "",
      value: isAdmin ? "customer_name" : "",
    },
    {
      title: isAdmin ? "Customer Uid" : "",
      value: isAdmin ? "customer_id" : "",
    },
  ];

  useEffect(() => {
    // admin or user check logic here
    const newDatalist = orderList.filter(
      (ord) => ord.customer_id === user?.id || isAdmin
    );
    setDatalist(newDatalist);
  }, [user, isAdmin, orderList]);

  useEffect(() => {
    // search by options on the top of the table
    const newDatalist = orderList
      .filter((ord) => ord.customer_id === user?.id || isAdmin)
      .filter(
        (data) =>
          isIncluded(String(data[selectedItem]), searchedText) ||
          searchedText === ""
      );
    setDatalist(newDatalist);
  }, [searchedText, selectedItem, isAdmin, user]);
  useEffect(() => {
    if (searchParams.get("search_item"))
      setSelectedItem(searchParams.get("search_item"));
    if (searchParams.get("search_value"))
      setSearchedText(searchParams.get("search_value"));
  }, [searchParams]);

  // for deleting review
  const handleDeletingOrder = (order) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Review for " + order?.customer_name + " will be deleted",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: `Back`,
      background: isDark === "dark" ? swalColors.darkBgFront : "",
      color: isDark === "dark" ? "#ffffff" : "#000000",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteOrder(order.id)
          .then((res) => {
            notify("Success", res.message, "success");
          })
          .catch((err) => {
            notify("Warning", err.message, "error");
          });
      }
    });
  };
  // for accepting review
  const handleCompleteOrder = (order) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Order for " + order?.customer_name + " will be Completed",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: `Back`,
      background: isDark === "dark" ? swalColors.darkBgFront : "",
      color: isDark === "dark" ? "#ffffff" : "#000000",
    }).then((result) => {
      if (result.isConfirmed) {
        updateOrder({ order_status: "Completed" }, order.id)
          .then((res) => {
            notify("Success", res.message, "success");
          })
          .catch((err) => {
            notify("Warning", err.message, "error");
          });
      }
    });
  };
  const handleCancelOrder = (order) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Order for " + order?.customer_name + " will be Cancelled",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: `Back`,
      background: isDark === "dark" ? swalColors.darkBgFront : "",
      color: isDark === "dark" ? "#ffffff" : "#000000",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: "Select a reason to cancel the order",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Confirm & Cancel",
          cancelButtonText: `Back`,
          background: isDark === "dark" ? swalColors.darkBgFront : "",
          color: isDark === "dark" ? "#ffffff" : "#000000",
          input: "select",
          inputOptions: {
            "Player Info is not correct": "Player Info is not correct",
            "Payment Account is not correct": "Payment Account is not correct",
            "Order is not paid corretly": "Order is not paid corretly",
            "User didn't give proper information": "User didn't give proper information",
            "Inbox in Whatsapp to know the reason": "Inbox in Whatsapp to know the reason",
          },
          inputPlaceholder: "Write a Reason to Cancel",
          inputValidator: (value) => {
            if (value) {
              // console.log({ value });
              updateOrder(
                { order_status: "Canceled", orderCancelReason: value },
                order.id
              )
                .then((res) => {
                  notify("Success", res.message, "success");
                })
                .catch((err) => {
                  notify("Warning", err.message, "error");
                });
            } else {
              notify(
                "You must select a reason to cancel the order",
                "",
                "info"
              );
            }
          },
        });
      }
    });
  };
  return (
    <div className="w-full md:container md:mx-auto">
      <SectionHeader heading={"Order List"} />
      <BreadCrumbs texts={["Dashboard", "Orderlist"]} />
      <FakeTableComponent
        datalist={datalist}
        column={column}
        pagination
        cursorPointer
        rowPerPageItems={[5, 10, 15, 20, 30, 40, 50, 100]}
        tableScroll
        tableScrollHeight={"500px"}
        tableHeaderComponent={
          <h2 className="text-4xl text-center font-semibold">Order List</h2>
        }
        filterComponents={[
          <div className="border-2 rounded-sm my-2 mx-3 grid grid-cols-1 md:grid-cols-2 ">
            <select
              className="px-3 py-2 h-full col-span-1 sm:border-r-2 sm:border-b-0 border-b focus:outline-0 "
              onChange={(e) => setSelectedItem(e.target.value)}
              // defaultValue={selectedItem}
              value={selectedItem}
            >
              {selectionOption.map((opt, i) => {
                return (
                  opt.title && (
                    <option key={i} value={opt.value}>
                      Seacrh by {opt.title}
                    </option>
                  )
                );
              })}
            </select>
            <input
              onChange={(e) => setSearchedText(e.target.value)}
              value={searchedText}
              className="px-3 py-2 col-span-1 focus:outline-0"
              placeholder={`Search...🔎`}
            />
          </div>,
        ]}
        nodataComponent={<NoItemFound msg={"No data found"} />}
      />
    </div>
  );
};

export default OrderList;
