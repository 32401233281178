import React from "react";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import Textinput from "../../Components/SiteComponants/FormElements/Textinput";
import InputDescription from "../../Components/SiteComponants/FormElements/InputDescription";
import SpecialButton from "../../Components/SiteComponants/SpecialButton";
import { useState } from "react";
import { useEffect } from "react";
import { TitleSetup } from "../../Datas/Functions/titleSetup";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSiteData } from "../../Contexts/SiteContext";
import { notify } from "../../Datas/Functions/toast";
import {
  createFaq,
  deleteFaq,
  getFaqs,
  updateFaq,
} from "../../Firebase/Realtime/Faq";
import { emptyFaqValues } from "../../Datas/emptyValues";
import Swal from "sweetalert2";
import { swalColors } from "../../Datas/SiteData/colorRelated";

const ManageFaq = (meta) => {
  TitleSetup({ ...meta });
  const [faqList, setFaqlist] = useState([]);
  const [answer, setAnswer] = useState("");
  // const [isEditing, setisEditing] = useState(false);
  const { isDark } = useSiteData();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    updateFaqTable();
  }, []);
  const { setIsLoading } = useSiteData();

  // if submit button clicked
  const onSubmit = (data) => {
    if (!answer) {
      notify("Warning", "You must provide answer for every question", "error");
      return;
    }
    data.answer = answer;
    //console.log({ data });
    setIsLoading(true);
    if (data.id) {
    //  console.log(data);
      updateFaq(data, data.id)
        .then((res) => {
          notify("Success", res.message, "success");
          setIsLoading(false);
          updateFaqTable();
        })
        .catch((err) => {
          notify("Warning", err.message, "error");
          setIsLoading(false);
        });
    } else {
      createFaq(data)
        .then((res) => {
        //  console.log({ faqres: res });
          notify("Success", res.message, "success");
          setIsLoading(false);
          updateFaqTable();
        })
        .catch((err) => {
       //   console.log({ faqErr: err });
          notify("Success", err.message, "success");
          setIsLoading(false);
        });
    }
    clearAll();
  };

  // if delte button clicked
  const deleteFAQ = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Faq ID " + item.id + " will be deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      background: isDark === "dark" ? swalColors.darkBgFront : "",
      color: isDark !== "dark" ? "#ffffff" : "",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFaq(item.id)
          .then((res) => {
         //   console.log({ delete: res });
            notify("Deleted!", res.message, "success");
            updateFaqTable();
          })
          .catch((err) => {
         //   console.log(err);
            notify("Warning", err.message, "error");
          });
      }
    });
  };

  // if edit button clicked
  const setSelectedValue = (item) => {
    reset(item);
    setAnswer(item.answer);
  };

  // after every time submitted all value clear
  const clearAll = () => {
    reset(emptyFaqValues);
    setAnswer("");
  };

  // get all faq everytime
  const updateFaqTable = () => {
    getFaqs()
      .then((res) => {
        setFaqlist(res.data);
    //    console.log({ res });
      })
      .catch((err) => {
        //console.log(err);
        // notify("Warning", err.message, "error");
      });
  };

  return (
    <div className="container">
      <SectionHeader heading={"FAQ Management"} className={"text-left"} />
      <BreadCrumbs texts={["Dashboard", "Manage FAQ"]} />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 my-10 ">
        <div className="col-span-1 md:col-span-2">
          <div className="box p-5">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Textinput
                title={"Question"}
                name={"question"}
                type="text"
                placeholder="Enter Question"
                errors={errors}
                className={`border rounded w-full py-2 px-4 ${
                  errors.title ? "border-red-500" : "border-orange-300"
                }`}
                register={register("question", {
                  required: "Question is required",
                })}
              />
              <InputDescription
                title={"Answer of the Question"}
                value={answer}
                setValue={setAnswer}
                className={`border  placeholder:dark:text-white w-full`}
                placeholder="Write the Question . . . "
              />
              <Textinput
                title={"Category"}
                name={"category"}
                type="text"
                placeholder="Enter Category"
                errors={errors}
                datalist={[...new Set(faqList?.map((faq) => faq.category))]}
                className={`border rounded w-full py-2 px-4 ${
                  errors.title ? "border-red-500" : "border-orange-300"
                }`}
                register={register("category", {
                  required: "Category must be selected",
                })}
              />
              <div className="text-center my-3">
                <SpecialButton text="Submit Faq" type="submit" />
              </div>
            </form>
          </div>
        </div>
        <div className="md:col-span-3 col-span-1">
          <div className="box w-full max-h-screen overflow-scroll dark:dark-bg-front bg-front">
            <h2 className="font-bold text-xl text-center my-3">Preview </h2>
            <table className=" w-full overflow-x-scroll table">
              <thead>
                <tr>
                  <th className="p-3 font-bold uppercase bg-back dark:dark-bg-back   dark:dark-text">
                    Question
                  </th>
                  <th className="p-3 font-bold uppercase  bg-back dark:dark-bg-back  dark:dark-text">
                    Answer
                  </th>
                  <th className="p-3 font-bold uppercase  bg-back dark:dark-bg-back    dark:dark-text">
                    Category
                  </th>
                  <th className="p-3 font-bold uppercase  bg-back dark:dark-bg-back    dark:dark-text">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {faqList.length > 0 ? (
                  faqList.map((item, i) => (
                    <tr
                      key={i}
                      className="cursor-pointer text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800  duration-200"
                    >
                      <td className="w-auto p-3">
                        {item.question}
                      </td>
                      <td className="w-auto p-3 text-center">
                        <Link to={"/faq"}>
                          <span className="text-brand underline">
                            View Answer
                          </span>
                        </Link>
                      </td>
                      <td className="w-auto p-3 text-center">
                        {item.category}
                      </td>
                      <td className="w-auto p-3 text-center">
                        <button
                          className="pr-3 duration-150 transition-all hover:border-orange-400"
                          onClick={() => setSelectedValue(item)}
                        >
                          <i className="fas fa-edit    "></i>
                        </button>
                        <button
                          className="pl-3  duration-150 transition-all hover:border-orange-400"
                          onClick={() => deleteFAQ(item)}
                        >
                          <i
                            className="fa fa-trash text-red-600"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={"100%"}>
                      <h1 className="text-3xl text-center">
                        No FAQ Data Available
                      </h1>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageFaq;
