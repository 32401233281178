import React from "react";

const ImageInput = ({
  title = "",
  icon = "",
  name = "",
  errors,
  register,
  ...rest
}) => {
  return (
    <div className="mb-4">
      <label className="block my-2">
        {title && <h5 className="font-bold my-1">{title}</h5>}
        <div className="flex items-center">
          {icon && <span className="inline-block w-10 h-10 mr-2 text-3xl">{icon}</span>}
          <input className="bg-back dark:dark-bg-back" type="file" {...register} {...rest} />

        </div>
      </label>
      {errors[name] && (
        <p className="text-red-500 text-xs mt-1">{errors[name].message}</p>
      )}
    </div>
  );
};

export default ImageInput;
