export const monthlist = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const paymentNumbers = [
  {
    title: "01799361321 - (Bkash/Nagad)",
    value: "01799361321",
  },
  {
    title: "01833534123 - (Bkash/Nagad)",
    value: "01833534123",
  },
];

// dont change this
export const orderConfig = {
  mailEncryptionData: "akenosRhaIfadonerTth99",
  backendURL: "https://thanos-e-shop-backend.onrender.com",
  // backendURL: "http://localhost:2525",
};
