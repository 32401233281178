import React, { useEffect, useState } from "react";
import { notify } from "../../Datas/Functions/toast";
import { deleteBanner, getBanners } from "../../Firebase/Realtime/Banner";
import Swal from "sweetalert2";
import { useSiteData } from "../../Contexts/SiteContext";
import { swalColors } from "../../Datas/SiteData/colorRelated";

const BannerListSection = ({
  setSelectedBanner,
  updateBannerTable,
  bannerList,
}) => {
  const { isDark } = useSiteData();

  useEffect(() => {
    updateBannerTable();
  }, []);

  const deleteBannerItem = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Banner ID " + item.id + " will be deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      background: isDark === "dark" ? swalColors.darkBgFront : "",
      color: isDark !== "dark" ? "#ffffff" : "",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBanner(item.id)
          .then((res) => {
        //    console.log({ delete: res });
            notify("Deleted!", res.message, "success");
            updateBannerTable();
          })
          .catch((err) => {
       //     console.log({ err });
            notify("Warning", err.message, "error");
          });
      }
    });
  };

  return (
    <div className="col-span-12 md:col-span-7 ">
      <div className="box max-h-screen overflow-scroll dark:dark-bg-front bg-front">
        <h1 className="text-center text-2xl font-bold mb-5 py-5">
          Banner List
        </h1>
        <table className=" w-full overflow-x-scroll table">
          <thead>
            <tr>
              <th className="p-3 text-left font-bold uppercase bg-back dark:dark-bg-back   dark:dark-text ">
                Title
              </th>
              <th className="p-3 font-bold uppercase  bg-back dark:dark-bg-back  dark:dark-text ">
                Image Source
              </th>
              <th className="p-3 font-bold uppercase  bg-back dark:dark-bg-back    dark:dark-text ">
                Link
              </th>
              <th className="p-3 font-bold uppercase  bg-back dark:dark-bg-back    dark:dark-text ">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {bannerList.length > 0 ? (
              bannerList.map((item, i) => (
                <tr
                  key={i}
                  className="cursor-pointer text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800  duration-200 text-center"
                >
                  <td className="w-auto p-3 text-left">
                    {item.title}
                  </td>
                  <td className="w-auto p-3">
                    <a
                      className="text-blue-600 underline"
                      href={item.src}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {" "}
                      View Image
                    </a>
                  </td>
                  <td className="w-auto p-3">
                    {item.link ? (
                      <a
                        className="text-blue-600 underline"
                        href={item.link}
                        rel="noreferrer noopener"
                      >
                        View Link
                      </a>
                    ) : (
                      "Not Available"
                    )}
                  </td>
                  <td className="w-auto p-3">
                    <button
                      className="pr-3"
                      onClick={() => setSelectedBanner(item)}
                    >
                      <i className="fas fa-edit    "></i>
                    </button>
                    <button
                      className="pl-3"
                      onClick={() => deleteBannerItem(item)}
                    >
                      <i
                        className="fa fa-trash text-red-600"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={"100%"}>
                  <h1 className="text-3xl text-center">
                    No Banner Data Available
                  </h1>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BannerListSection;
