import Dashboard from "../../Pages/Dashboard";
import Faq from "../../Pages/Faq";
import Home from "../../Pages/Home";
import Login from "../../Pages/Login";
import ProductItem from "../../Pages/ProductItem";
import Products from "../../Pages/Products";
import Reviews from "../../Pages/Reviews";
import NotFound from "./../../Pages/NotFound";
import PublicRoute from "../../Components/PrivateRoutes/PublicRoute";
import ForgotPassword from "../../Pages/ForgotPassword";

export const routeDatas = [
  {
    title: "Home",
    route: "/",
    icon: "fa fa-home",
    visibility: true,
    isNavbarItem: true,
    child: (
      <Home
        title="Home : Thanos E-shop"
        description="From ThanosE-shop, you can quickly Top Up PUBG,othr game currency  via bKash,Nagad and bank.We prioritize speedy delivery"
        keywords="Thanos E-shop, UC shop, PUBG UC Shop, Free Fire UC Shop, GIft Card, Bangladeshi, Gaming shop, pubg uc store, pubg bd"
      />
    ),
  },
  {
    title: "Shop",
    route: "/products",
    icon: "fas fa-project-diagram ",
    visibility: true,
    isNavbarItem: false,
    keywords:
      "Thanos E-shop, UC shop, PUBG UC Shop, Free Fire UC Shop, GIft Card, Bangladeshi, Gaming shop, pubg uc store, pubg bd, products, shop, E-shop",
    child: (
      <Products
        title="Products list : Thanos Gaming E-shop"
        description="A product list of Thanos E-shop, from where you can buy and sell pubg UC"
        keywords="GamingCurrency,pubg,subscription,freefire"
      />
    ),
  },
  {
    title: "Shop Product",
    route: "/products/:pid",
    icon: "fas fa-project-diagram ",
    visibility: true,
    isNavbarItem: false,
    keywords: "",
    child: (
      <ProductItem
        title="A Product of Thanos E-shop"
        description="A product of Thanos E-shop, from where you can buy and sell pubg UC"
        keywords="pubg,netflix,hotstar,freefire"
      />
    ),
  },
  {
    title: "Dashboard",
    route: "/dashboard",
    icon: "fa fa-gear",
    visibility: true,
    isNavbarItem: false,
    child: (
      <Dashboard
        title="Dashboard : Thanos E-shop"
        description="A product of Thanos E-shop, from where you can buy and sell pubg UC"
        keywords=""
      />
    ),
  },
  {
    title: "Reviews",
    route: "/reviews",
    icon: "fa fa-star-half",
    visibility: true,
    isNavbarItem: true,
    child: (
      <Reviews
        title="Reviews : Thanos E-shop"
        description="All the Reviews of Thanos E-shop is Here. You can  purchase item item and give us feedback about our service"
        keywords="site,review,star,feedback"
      />
    ),
  },
  {
    title: "FAQ",
    route: "/faq",
    icon: "fas fa-bolt",
    visibility: true,
    isNavbarItem: true,
    child: (
      <Faq
        title="FAQ : Thanos E-shop"
        description="Frequently Asked Question about the Site or service"
        keywords="faq,thanosgaming,thanosgamingfaqpage"
      />
    ),
  },
  {
    title: "Login",
    route: "/auth",
    icon: "fa fa-sign-in",
    visibility: true,
    isNavbarItem: false,
    child: (
      <PublicRoute>
        <Login
          title="Authentication : Thanos E-shop"
          description="Authentication page for  the user, make sure to provide the correct information"
          keywords="login,thanosgaming,thanosgamingloginpage"
        />
      </PublicRoute>
    ),
  },
  {
    title: "Login",
    route: "/forgot-password",
    icon: "fa fa-sign-in",
    visibility: true,
    isNavbarItem: false,
    child: (
      <PublicRoute>
        <ForgotPassword
          title="Authentication : Thanos E-shop"
          description="Authentication page for  the user, make sure to provide the correct information"
          keywords="login,thanosgaming,thanosgamingloginpage"
        />
      </PublicRoute>
    ),
  },
  {
    title: "Not Found",
    route: "/*",
    icon: "fa fa-sign-in",
    visibility: true,
    isNavbarItem: false,
    child: (
      <NotFound
        title="Page Not Found : Thanos E-shop"
        description="404 Error, page not found. Go back to home, or provide more valid routes to visit page"
        keywords="pagenotfound,404"
      />
    ),
  },
];
