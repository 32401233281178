import {
  child,
  get,
  getDatabase,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import { app } from "./../firebase.config";
import { uuid } from "../../Datas/Functions/common";
import { getAuth } from "firebase/auth";

const database = getDatabase(app);
const auth = getAuth(app);
//console.log(auth.currentUser);
const mainPath = "review/";

// Review create
export const createReview = (data) => {
  return new Promise((res, err) => {
    const id = uuid({
      start: "rev_",
      len: 25,
      digit: true,
      lower: true,
      upper: true,
      hypen: false,
    });
    data.id = id;
    data.created_at = new Date().getTime();
    data.isAccepted = false;
    try {
      set(ref(database, mainPath + id), data);
      return res({
        message: "Review Successfully Submited",
      });
    } catch (error) {
      return err({
        message: "Something went wrong",
      });
    }
  });
};

// Review update
export const updateReview = (data, id) => {
  return new Promise((res, err) => {
    if (!id) {
      return err({
        message: "Item is not Seleted or not valid",
      });
    }
    try {
      update(ref(database, mainPath + id), data);
      return res({
        message: "Review Successfully updated",
      });
    } catch (error) {
      return err({
        error,
        message: "Something went wrong",
      });
    }
  });
};

// Review delete
export const deleteReview = (id) => {
  return new Promise((res, err) => {
    if (!id) {
      return err({
        message: "Item is not Seleted or not valid",
      });
    }
    try {
      remove(ref(database, mainPath + id));
      return res({
        message: "Selected Review is successfully deleted",
      });
    } catch (error) {
      return err({
        message: "Something went wrong",
      });
    }
  });
};

// get all faq
export const getReviews = () =>
  new Promise((res, err) => {
    get(child(ref(database), mainPath))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const bannerSnap = snapshot.val();
          return res({
            message: "All Review fetched",
            data: Object.values(bannerSnap)
            .sort(
              (a, b) => b.created_at - a.created_at
            ),
          });
        } else {
          err({
            message: "No review Found",
          });
        }
      })
      .catch((error) => {
        err({
          message: "Something went wrong",
        });
      });
  });
