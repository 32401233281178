import React, { useState } from "react";
import NoItemFound from "../../Components/SiteComponants/NoItemFound";
import FakeTableComponent from "../../Components/FakeTableComponent/FakeTableComponent";
import SectionHeader from "./../../Components/SectionHeader/SectionHeader";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSiteData } from "../../Contexts/SiteContext";
import MenuButton from "../../Components/SiteComponants/MenuButton";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { TitleSetup } from "../../Datas/Functions/titleSetup";
import Swal from "sweetalert2";
import { deleteProduct } from "../../Firebase/Realtime/Products";
import { notify } from "../../Datas/Functions/toast";
import { swalColors } from "../../Datas/SiteData/colorRelated";

const ProductList = (meta) => {
  // setting up title of this page
  TitleSetup({ ...meta });
  //********** */
  const [searchedText, setSearchedText] = useState("");
  const [selectedItem, setSelectedItem] = useState("id");
  const [datalist, setDatalist] = useState([]);
  const { products, isDark } = useSiteData();
  const navigate = useNavigate();
  useEffect(() => {
    
    setDatalist(products);
  }, [products]);
  useEffect(() => {
    const newDatalist = products.filter((data) =>
      data[selectedItem].toLowerCase().includes(searchedText.toLowerCase())
    );
    setDatalist(newDatalist);
  }, [searchedText, selectedItem]);

  const handleOptionClick = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Product : " + item?.name + " will be deleted",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: `Back`,
      background: isDark === "dark" ? swalColors.darkBgFront : "",
      color: isDark === "dark" ? "#ffffff" : "#000000",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProduct(item.id)
          .then((res) => {
            notify("Success", res.message, "success");
          })
          .catch((err) => {
            notify("Warning", err.message, "error");
          });
      }
    });
  };

  const column = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: "id",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      sortable: "name",
    },
    {
      name: "CATEGORY",
      selector: (row) => row.category,
      sortable: "category",
    },
    {
      name: "Visibility",
      selector: (row) => <span className="font-bold">{row.visibility}</span>,
      sortable: "visibility",
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex flex-wrap">
          <MenuButton text="Info">
            <ul className="cursor-pointer ">
              <li
                className="border-y py-2 px-4 flex justify-between hover:bg-gray-300 hover:text-gray-900"
                onClick={() => navigate("/products/" + row.id)}
              >
                <span>
                  <i className="fa fa-eye" aria-hidden="true"></i>
                </span>
                <span>View Product</span>
              </li>
              <li
                className="border-y py-2 px-4 flex justify-between hover:bg-gray-300 hover:text-gray-900"
                onClick={() => navigate("/dashboard/add-product/" + row.id)}
              >
                <span>
                  <i className="fa fa-edit" aria-hidden="true"></i>
                </span>
                <span>Edit Product </span>
              </li>
              <li
                className="border-y py-2 px-4 flex justify-between hover:bg-gray-300 hover:text-gray-900"
                onClick={() => handleOptionClick(row)}
              >
                <span>
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </span>{" "}
                <span>Delete Product</span>
              </li>
            </ul>
          </MenuButton>
        </div>
      ),
    },
  ];
  const selectionOption = [
    {
      title: "ID",
      value: "id",
    },
    {
      title: "Name",
      value: "name",
    },
    {
      title: "Category",
      value: "category",
    },
  ];

  return (
    <div className="w-full md:container md:mx-auto">
      <SectionHeader heading={"Product List"} />
      <BreadCrumbs texts={["Dashboard", "Product List"]} />
      <div className="w-full text-right">
        <button className="ml-auto my-auto px-1 font-semibold" onClick={()=> navigate("../add-product/")}>
          Add New Product <i className="fa fa-plus" aria-hidden="true"></i>
        </button>
      </div>

      <FakeTableComponent
        datalist={datalist}
        column={column}
        pagination
        tableScroll
        tableScrollHeight={"500px"}
        tableHeaderComponent={
          <h2 className="text-4xl text-center font-semibold">Product List</h2>
        }
        filterComponents={[
          <div className="border rounded-sm my-2 mx-3 grid grid-cols-1 md:grid-cols-2 ">
            <select
              className="px-3 py-2 h-full col-span-1  sm:border-r-2 sm:border-b-0 border-b focus:outline-0 "
              onChange={(e) => setSelectedItem(e.target.value)}
              defaultValue={selectedItem}
            >
              {selectionOption.map((opt, i) => (
                <option key={i} value={opt.value}>
                  Seacrh by {opt.title}
                </option>
              ))}
            </select>
            <input
              onChange={(e) => setSearchedText(e.target.value)}
              className="px-3 py-2 col-span-1  focus:outline-0"
              placeholder={`Search...🔎`}
            />
          </div>,
        ]}
        nodataComponent={<NoItemFound msg={"No data found"} />}
      />
    </div>
  );
};

export default ProductList;
