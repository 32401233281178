import { Avatar, Tooltip } from "@mui/material";
import React from "react";
import moment from "moment";
const ReviewCard = ({ rev }) => {
  return (
    <div
      key={rev.id}
      className="text-center bg-front shadow-xl dark:dark-bg-front w-auto md:w-80 aspect-square my-8 p-8   rounded-lg mx-5  cursor-pointer hover:bg-orange-500 duration-50 flex flex-col justify-center items-center"
    >
      <div className="">
        <div className="my-2">
          <span className="block">{rev.name}</span>
          <Avatar className="mx-auto" alt={rev.name} src={rev.img}>
            {rev.name.toUpperCase()[0]}
          </Avatar>
        </div>
        <div className="h-auto overflow-y-scr oll no-scrollbar duration-300 ">
          <p className="text-lg font-bold mb-2  ">
            {[1, 2, 3, 4, 5].map((rating) => (
              <i
                key={rating}
                className={`fa fa-star  mx-1 ${
                  rating <= rev.rating ? "text-yellow-300" : "text-gray-200"
                }`}
                aria-hidden="true"
              ></i>
            ))}
          </p>
          <p>
            {rev.review.split(" ").length > 25
              ? rev.review.split(" ").slice(0, 25).join(" ") + " . . ."
              : rev.review}
          </p>
          <p className="text-xs text-gray-400 tracking-wider mt-2">
            <Tooltip
              arrow
              title={
                <span>
                  {moment(rev.created_at).format("DD/MM/yyyy - hh:mm:ss")}
                </span>
              }
            >
              <span>Reviewed {moment(rev.created_at).fromNow()}</span>
            </Tooltip>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
