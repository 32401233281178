import {
  child,
  get,
  getDatabase,
  onValue,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import { app } from "./../firebase.config";
import { uuid } from "../../Datas/Functions/common";
import { getAuth } from "firebase/auth";

const database = getDatabase(app);
const auth = getAuth(app);
// console.log(auth.currentUser);
const mainPath = "products/";

// Product create
export const createProduct = (data) => {
  return new Promise((res, err) => {
    const id = uuid({
      start: "prd_",
      len: 25,
      digit: true,
      lower: false,
      upper: true,
      hypen: false,
    });
    data.id = id;
    data.created_at = new Date().getTime();
    try {
      set(ref(database, mainPath + id), data);
      return res({
        message: "Product Successfully Submited",
      });
    } catch (error) {
      //   console.log({ productUpdateError: error });
      return err({
        message: "Product Creation :Something went wrong",
      });
    }
  });
};

// Product update
export const updateProduct = (data, id) => {
  return new Promise((res, err) => {
    if (!id) {
      return err({
        message: "Item is not Seleted or not valid",
      });
    }
    try {
      update(ref(database, mainPath + id), data);
      return res({
        message: "Product Successfully Updated",
      });
    } catch (error) {
      return err({
        error,
        message: "Something went wrong",
      });
    }
  });
};

// Product delete
export const deleteProduct = (id) => {
  return new Promise((res, err) => {
    if (!id) {
      return err({
        message: "Item is not Seleted or not valid",
      });
    }
    try {
      remove(ref(database, mainPath + id));
      return res({
        message: "Selected Product is successfully deleted",
      });
    } catch (error) {
      return err({
        message: "Something went wrong",
      });
    }
  });
};

// get a single Product
export const getSingleProducts = (id) =>
  new Promise((res, err) => {
    get(child(ref(database), mainPath + id))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const productSnap = snapshot.val();
          return res({
            message: "The Product is fetched",
            data: productSnap,
          });
        } else {
          err({
            message: "No data found",
          });
        }
      })
      .catch((error) => {
        err({
          message: "Something went wrong",
        });
      });
  });

// get all Products
// export const getProducts = () =>
//   new Promise((res, err) => {
//     onValue(ref(database, mainPath), (snapshot) => {
//       if (snapshot.exists()) {
//         const productSnap = snapshot.val();
//         return res({
//           message: "All Products fetched",
//           data: Object.values(productSnap).sort(
//             (a, b) => a.created_at - b.created_at
//           ),
//         });
//       } else {
//         err({
//           message: "No Product found",
//         });
//       }
//     });
//     // get(child(ref(database), mainPath))
//     //   .then((snapshot) => {
//     //     if (snapshot.exists()) {
//     //       const productSnap = snapshot.val();
//     //       return res({
//     //         message: "All Products fetched",
//     //         data: Object.values(productSnap).sort(
//     //           (a, b) => b.created_at - a.created_at
//     //         ),
//     //       });
//     //     } else {
//     //       err({
//     //         message: "No Product found",
//     //       });
//     //     }
//     //   })
//     //   .catch((error) => {
//     //     err({
//     //       message: "Something went wrong",
//     //     });
//     //   });
//   });
