import React from "react";
import loadingImg from "./../../Imgs/loading.gif";
import loadingSvg from "./../../Imgs/loading.svg";
import { useSiteData } from "../../Contexts/SiteContext";
const LoadingScreen = () => {
  const { isloading, setIsLoading } = useSiteData();
  return (
    <>
      <div
        className={`w-screen h-screen bg-black flex justify-center items-center origin-center transform fixed top-0 left-0   duration-500 ${
          isloading
            ? "bg-opacity-100 opacity-100 z-100 visible"
            : "opacity-0 invisible bg-opacity-0 -z-100"
        }`}
      >
        <div className={`flex flex-col items-center justify-center `}>
          <h3 className="text-lg md:text-4xl bg-clip-text text-transparent bg-gradient-to-r from-orange-500 to-orange-300 animate-pulse ">
            Thanos E-shop
          </h3>
          {/* <img className="block" src={loadingImg} alt={"Loading . . ."} /> */}
          <img className="block" src={loadingSvg} alt={"Loading . . ."} />
          {/* {loadingSvg} */}
        </div>
      </div>
    </>
  );
};

export default LoadingScreen;
