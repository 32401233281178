import React, { useEffect, useState } from "react";
import { Swiper } from "swiper/react";
import { SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { getBanners } from "../../Firebase/Realtime/Banner";
const SliderBanner = () => {
  const [bannerData, setBannerData] = useState([]);
  useEffect(() => {
    getBanners()
      .then((res) => {
        setBannerData(res.data);
      })
      .catch((err) => {
        //    console.log({ BannerSiderError: err });
        // notify("Warning", err.message, "error");
      });
  }, []);
  return (
    <div className="overflow-hidden w-full">
      <Swiper
        spaceBetween={10}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="banner-container overflow-hidden h-[4 50px] w-full   aspect-banner"
      >
        {bannerData.map((val, i) => (
          <SwiperSlide
            key={i}
            title={val.title}
            className="rounded-md overflow-hidden"
          >
            <img
              onClick={() => {
                if (val.link) window.open(val.link, "_blank");
              }}
              className="w-full h-full cursor-pointer"
              src={val.src}
              alt={val.title}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SliderBanner;
