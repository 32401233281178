import React, { useState } from "react";
import { TitleSetup } from "../Datas/Functions/titleSetup";
import NoItemFound from "../Components/SiteComponants/NoItemFound";
import FAQcard from "../Components/FAQcard/FAQcard";
import faqimg from "./../Imgs/faq.png";
import { useEffect } from "react";
import { getFaqs } from "../Firebase/Realtime/Faq";
import LoadingBox from "../Components/SiteComponants/LoadingBox";

const Faq = (meta) => {
  TitleSetup({ ...meta });
  const [isOpen, setIsOpen] = useState("");
  const [faqs, setFaqs] = useState(null);
  const categorisedIt = (data) => {
    const newItems = data.reduce((obj, faq) => {
      if (!obj[faq.category]) {
        obj[faq.category] = [faq];
      } else {
        obj[faq.category].push(faq);
      }

      return obj;
    }, {});
    // console.log(newItems);
    let newCategories = Object.entries(newItems).map(([title, val]) => {
      return { heading: title, qna: val };
    });
    // console.log({newCategories})
    return newCategories || [];
  };

  /// fetching section
  useEffect(() => {
    getFaqs()
      .then((res) => {
        const newCategories = categorisedIt(res.data);
        setFaqs(newCategories);
      })
      .catch((err) => {
         setFaqs([]);
      });
  }, []);

  return (
    <div className="container">
      <div className="text-center my-10">
        <img
          className="mx-auto max-w-96 md:w-auto"
          src={faqimg}
          alt={"photoFAQ"}
        />
      </div>
      {faqs ? (
        faqs.length > 0 ? (
          faqs.map(
            (item, i) =>
              item.qna?.length > 0 && (
                <div
                  className="box w-full md:w-3/4 mx-auto bg-front dark:dark-bg-front my-4"
                  key={i}
                >
                  <h1 className="text-4xl font-bold text-center py-5 capitalize">
                    {item.heading}
                  </h1>
                  <div className="p-4">
                    {item.qna.map((qna, i) => (
                      <FAQcard
                        ques={qna.question}
                        ans={qna.answer}
                        heading={item.heading}
                        key={i}
                        id={i}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                      />
                    ))}
                  </div>
                </div>
              )
          )
        ) : (
          <NoItemFound msg={"No FAQ Added yet"} />
        )
      ) : (
        <div
          className="box w-full md:w-3/4 mx-auto bg-front dark:dark-bg-front my-4"
        >
          <LoadingBox />
        </div>
      )}
    </div>
  );
};

export default Faq;
