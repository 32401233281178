import React from "react";
import WrongPath from "../Components/WrongPath/WrongPath";
import { TitleSetup } from "../Datas/Functions/titleSetup";

const NotFound = (meta) => {
  TitleSetup({...meta})
  return (
    <div>
      <WrongPath statusCode={404} msg="Page not Found" link={"/"} linkText={"Back to Home"}/>
    </div>
  );
};

export default NotFound;
