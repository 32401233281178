import React, { useEffect, useState } from "react";
import SpecialButton from "../Components/SiteComponants/SpecialButton";
import Textinput from "../Components/SiteComponants/FormElements/Textinput";
import { TitleSetup } from "../Datas/Functions/titleSetup";
import { useForm } from "react-hook-form";
import { passwordResetEmail } from "../Firebase/Authentication";
import { notify } from "../Datas/Functions/toast";
import { useNavigate } from "react-router-dom";

const ForgotPassword = (meta) => {
  // setting up title of this page
  TitleSetup({ ...meta });
  //********** */
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const onSubmit = (data) => {
    passwordResetEmail(data.email)
      .then((res) => {
        notify("Success", res.message, "success");
        reset();
        navigate("/auth");
      })
      .catch((err) => {
        notify("Warning", err.message, "error");
      });

   // console.log({ data });
  };
  return (
    <div className="container my-10">
      <div className="grid grid-cols-1">
        <div className="col-span-1">
          <div className="box p-5 w-1/2 mx-auto ">
            <h1 className="text-3xl font-semibold text-center">
              Password Reset
            </h1>
            <form
              className="w-full"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <Textinput
                title={"Type your Email"}
                name={"email"}
                type={"email"}
                placeholder="Enter New Email"
                errors={errors}
                className={`border rounded w-full py-2 px-4 ${
                  errors.email ? "border-red-500" : "border-orange-300"
                }`}
                register={register("email", {
                  required: "Email is Required",
                })}
              />
              <div className="text-center my-4">
                <SpecialButton
                  type="submit"
                  text="Change Password"
                  className="mx-auto click-effect transition-all duration-300"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
