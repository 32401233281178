import React, { useEffect, useState } from "react";
import Viewmore from "../SiteComponants/Viewmore";
import NoItemFound from "../SiteComponants/NoItemFound";
import SectionHeader from "../SectionHeader/SectionHeader";
import ItemCard from "../ItemCard/ItemCard";

const ItemSection = ({ heading, showCount, showAll, prods, badge }) => {
  const [showProducts, setShowProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (showAll) {
      const publiceProducts = prods?.filter(
        (product) => product.visibility === "Public"
      );
      setShowProducts(publiceProducts);
    } else {
      const shoprod = prods
        .slice(0, showCount)
        .filter((product) => product.visibility === "Public");
      setShowProducts(shoprod);
    }

    setIsLoading(false);
  }, [prods]);
  return showProducts?.length > 0 ? (
    <div className="">
      <div className="w-full md:container mx-auto">
        <SectionHeader heading={heading} />
        <div
          className={`grid grid-cols-2 sm:gr id-cols-2 md:grid-cols-3 lg:grid-cols-5  gap-3 md:gap-4 auto-rows-auto`}
        >
          {showProducts?.length > 0 ? (
            showProducts.map((prd, i) => (
              <ItemCard badge={badge} key={i} prod={prd} />
            ))
          ) : (
            <NoItemFound msg={`No ${heading} Available Now`} />
          )}
        </div>
      </div>
      {heading === "Sales" && prods.length > 0 ? (
        <Viewmore link={"/products"} />
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  );
};

export default ItemSection;
