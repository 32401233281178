import React from "react";
import { routeDatas } from "../../Datas/RouteDatas/MainRoutes";
import { Link, NavLink } from "react-router-dom";
import logo from "./../../Imgs/brand_logo.png";
import { useSiteData } from "../../Contexts/SiteContext";
import { useAuthContext } from "../../Contexts/AuthContext";
import { Tooltip } from "@mui/material";
import { notifyMe } from "../../Datas/Functions/BrowserNotify";

const Navbar = () => {
  const { isDark, setIsDark } = useSiteData();
  const { logout, isLoggedin } = useAuthContext();
  function switchMode(value) {
    localStorage.setItem("theme", value);
    setIsDark(value);
  }
  return (
    <div className="w-full md-navbar z-50 capitalize m-0 py-2">
      <div className="w-full md:container mx-auto p-0">
        <nav className="flex justify-between flex-col md:flex-row text-sm md:text-lg dark:dark-text">
          <div className=" md:h-auto md:w-1/5 rounded fixed top-0 left-0 w-screen md:relative  bg-front dark:dark-bg-front md:inline-block flex flex-row justify-between items-center border- b-2 border-b-g ray-200 md:border-b-0 py-0 text-sm">
            <div>
              <Link
                className="w-auto h-10 md:h- full  hid den  inline-block"
                to="/"
              >
                <img
                  className=" h-full my-auto "
                  src={logo}
                  alt="Thanos E-shop LOGO"
                />
              </Link>{" "}
              {/* <button onClick={notifyMe}>notif</button> */}
              {/* <Link to="/">
                <img
                  className="my-auto h-12 rounded-full inline-block md:hidden mx-3"
                  src={minilogo}
                  alt="Thanos E-shop LOGO"
                />
              </Link> */}
            </div>
            <div className="md:hidden">
              <span className="inline-block text-sm md:text-lg mx-2">
                <button
                  className="ml-1 rounded-full ring-1 ring-orange-500 w-10 h-10"
                  onClick={() => switchMode(isDark ? "" : "dark")}
                >
                  {isDark ? (
                    <i className="fa fa-sun" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-moon" aria-hidden="true"></i>
                  )}
                </button>
              </span>
            </div>
          </div>
          <ul className="w-auto  grid grid-flow-col items-center gap-4 overflow-x-scroll no-scrollbar py-1">
            {routeDatas.map(
              (route, i) =>
                (route.isNavbarItem ||
                  (route.route === "/dashboard" && isLoggedin) ||
                  (route.route === "/auth" && !isLoggedin)) && (
                  <li key={i} className="text-base  mx-2 inline-block">
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "nav-item active font-bold"
                          : "nav-item nav-item-hover"
                      }
                      to={route.route}
                    >
                      <i
                        className={
                          route.icon +
                          "  md:hidden lg:inline-block inline-block"
                        }
                        title={route.title}
                        aria-hidden="true"
                      ></i>
                      <span className="ml-1">{route.title}</span>
                    </NavLink>
                  </li>
                )
            )}
            <li className="hidden md:inline-block text-sm md:text-lg mx-2 ">
              <Tooltip
                title={
                  isDark === "dark"
                    ? "Switch to Light Mode"
                    : "Switch to Dark Mode"
                }
                arrow
              >
                <button
                  className="ml-1 rounded-full ring-1 ring-orange-500 w-10 h-10"
                  onClick={() =>
                    switchMode(isDark === "dark" ? "light" : "dark")
                  }
                >
                  {isDark === "dark" ? (
                    <i className="fa fa-sun" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-moon" aria-hidden="true"></i>
                  )}
                </button>
              </Tooltip>
            </li>
            {isLoggedin && (
              <li
                className="hidden md:inline-block text-sm  mx-2 "
                title={isDark ? "Switch to Light Mode" : "Switch to Dark Mode"}
              >
                <button className="ml-1 site-btn px-3 py-2" onClick={logout}>
                  {" "}
                  <i
                    className="fa fa-sign-out mr-2 hidden md:inline-block"
                    aria-hidden="true"
                  ></i>{" "}
                  <span> Logout</span>
                </button>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
