import React, { useEffect, useState } from "react";
import {
  clickToCopy,
  isIncluded,
} from "../../Datas/Functions/shareFunctions";
import { notify } from "../../Datas/Functions/toast";
import Textinput from "../SiteComponants/FormElements/Textinput";
import { useAuthContext } from "../../Contexts/AuthContext";
import { paymentNumbers } from "../../Datas/SiteData/AllinOne";
import { useNavigate } from "react-router-dom";
import { findLink } from "../../Datas/Functions/common";
import { useSiteData } from "../../Contexts/SiteContext";

const PaymentSection = ({
  selectedItem,
  setIsCheckOut,
  handleSubmit,
  setAccountNumber,
  onSubmit,
  selectedPaymentMethod,
  setSelectedPayementMethod,
  errors,
  accountNumber,
}) => {
  const { isLoggedin } = useAuthContext();
  const navigate = useNavigate();
  const { allSocial } = useSiteData();
  const [whatsapp, setWhatsapp] = useState({});
  useEffect(() => {
    const newData = allSocial?.find((item) => isIncluded(item?.key, "whatsapp"));
    setWhatsapp(newData);
  }, [allSocial]);

  return (
    <div className="relative  bg-front dark:dark-bg-front box my-3">
      <h1 className="font-bold my-3 py-2 text-lg md:text-xl text-left block border-b-2 border-b-orange-400">
        <i className="fas fa-dollar-sign text-center mx-2  inline-block p-1 rounded-full bg-orange-500 text-white w-7 aspect-square  "></i>
        Payment Info
      </h1>
      <div className="p-4">
        <div>
          <div className="">
            <h5 className="font-bold my-1 capitalize">
              Select Payment Methods:
            </h5>
          </div>

          <div className="p-4 border rounded my-2">
            <ul className="font-bold">
              {paymentNumbers.map((item, i) => (
                <li key={i}>
                  <label>
                    <input
                      onClick={() => {
                        clickToCopy(item.value);
                        notify(
                          "Number " + item.value + " is Copied ro clipboard"
                        );
                      }}
                      name="payment"
                      checked={selectedPaymentMethod === item.value}
                      type="radio"
                      value={item.value}
                      onChange={() => setSelectedPayementMethod(item.value)}
                    />
                    <span className="ml-2">{item.title}</span>
                  </label>
                </li>
              ))}
            </ul>
            <p className="text-lg  font-mono  text-left">
              অর্ডার করার জন্য উপরে দেওয়া নম্বরগুলোর মধ্যে যেকোনো একটিতে বিকাশ
              অথবা নগদের মাধ্যমে Send money অথবা Cash In করতে হবে। যেই নম্বরে
              টাকা পাঠাবেন, সেটি সিলেক্ট করে দিন। Payment করার পর, যে নাম্বার
              থেকে পেমেন্ট করা হয়েছে সে নম্বরটি অথবা নম্বরটির শেষ ৪ ডিজিট নিচে
              দেওয়া বক্স এ লিখে Place Order বাটনে ক্লিক করুন
            </p>
          </div>

          <p className="text-center my-4">
            বিকাশ কিংবা নগদ ব্যতীত অন্য কোনো উপায়ে পেমেন্ট করতে হোয়াটসআপে
            যোগাযোগ করুন{" "}
            <a
              href={findLink(whatsapp)}
              target="_blank"
              rel="noreferrer noopenner"
              className="text-brand"
            >
              (+{whatsapp?.value})
            </a>
          </p>
          <div className="my-5">
            <Textinput
              title={"Account Number (or Last 4 Digit)"}
              name={"order_account"}
              type={"text"}
              placeholder={"Enter Your Bkash/Nagad Number"}
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              className={`border rounded w-full py-2 px-4 border-orange-300`}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
            {!isLoggedin && (
              <button
                type="button"
                className={`font-semibold p-3 col-span-1 site-btn`}
                onClick={() => navigate("/auth")}
              >
                Login First
                <i className={`fa fa-sign-in ml-2`} aria-hidden="true"></i>
              </button>
            )}

            <button
              type="submit"
              disabled={!isLoggedin}
              className={`font-semibold site-btn p-3 col-span-1 `}
            >
              Place Order
              <i className={`fa fa-shopping-cart ml-2`} aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSection;
