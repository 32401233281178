import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  updatePassword,
  sendPasswordResetEmail,
  updateProfile,
  signOut,
} from "firebase/auth";
import { errorMsgHandler } from "../Datas/Functions/shareFunctions";
import { child, get, getDatabase, ref } from "firebase/database";

const auth = getAuth();

// setPersistence(auth, browserLocalPersistence)
//   .then(() => {
//     console.log({ persistanceUser: auth.currentUser });
//   })
//   .catch((err) => {
//     console.log({ statePErsistance: err });
//   });

// Sign Up section
export const SignUp = (email, password, name) => {
  return new Promise((res, err) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // first stroring the name onto auth
        updateProfile(auth.currentUser, {
          displayName: name,
        })
          .then((r) => {
            // then sending verifcation email
            verifyAccount()
              .then((response) => {
                return res({
                  message:
                    "Verification Mail Sent! Please verify your Account & Login",
                });
              })
              .catch((error) => {
                return err({
                  message: error.code
                    ? errorMsgHandler(error.code)
                    : "Something happend in verification",
                });
              });
          })
          .catch((er) => {
            return err({
              message: er.code
                ? errorMsgHandler(er.code)
                : "Something happend with the Name",
            });
          });
      })
      .catch((error) => {
        //   console.log({ error });
        return err({
          message: error.code
            ? errorMsgHandler(error.code)
            : "Authentication Error",
        });
      });
  });
};

// Sign IN section
export const SignIn = (email, password) => {
  return new Promise((res, err) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        // checking user email is verifed or not
        if (!user.emailVerified) {
          // if not , send verificaion mail
          verifyAccount()
            .then((response) => {
              return res({
                message: "Verification Mail Sent! Please verify your Account",
                data: null,
              });
            })
            .catch((error) => {
              return err({
                message: "Verification is not done yet",
              });
            });
        } else {
          // if email verified, check user already has a profile or not
          get(child(ref(getDatabase()), `users/${user.uid}`))
            .then((snapshot) => {
              // if yes, then send the profile
              if (snapshot.exists()) {
                return res({
                  message: "Successfully Logged In",
                  data: snapshot.val(),
                });
              }
              // if not, create a profile, and send the same profile
              else {
                return res({
                  message: "Successfully Logged In",
                  data: user,
                });
              }
            })
            .catch((error) => {
              return err({
                message: error.code
                  ? errorMsgHandler(error.code)
                  : "User Profile Not Found",
              });
            });
        }
      })
      .catch((error) => {
        // console.log({ error });
        return err({
          message: error.code ? errorMsgHandler(error.code) : "Login Error",
        });
      });
  });
};

// sign out
export const logOutFromDevice = () => {
  return new Promise((res, err) => {
    signOut(auth)
      .then(() => {
        return res({
          message: "User Logged Out",
        });
      })
      .catch((error) => {
        return err({
          message: "Auth : Something went Wrong",
        });
      });
  });
};

// verify user acount
export const verifyAccount = () => {
  return new Promise((res, err) => {
    sendEmailVerification(auth.currentUser)
      .then((response) => {
        return res({
          message: "Verification mail has been sent to your email",
        });
      })
      .catch((error) => {
        return err({
          message: error.code
            ? errorMsgHandler(error.code)
            : "Verification error occured",
          error,
        });
      });
  });
};

//Change Password
export const changePassword = (newPass) => {
  return new Promise((res, err) => {
    updatePassword(auth.currentUser, newPass)
      .then((response) => {
        //    console.log({ response });
        return res({
          message: "Password Successfully Updated",
        });
      })
      .catch((error) => {
        return err({
          message: error.code
            ? errorMsgHandler(error.code)
            : "Password update error occured",
        });
      });
  });
};

// reset password / forgot passowrd
export const passwordResetEmail = (email) => {
  return new Promise((res, err) => {
    sendPasswordResetEmail(auth, email)
      .then((response) => {
        //    console.log({ response });
        return res({
          message: "Password Reset Mail successfully sent",
        });
      })
      .catch((error) => {
        return err({
          message: error.code
            ? errorMsgHandler(error.code)
            : "Password Reset : error occured",
        });
      });
  });
};
