import React from "react";

const InputSelect = ({
  title = "",
  icon = "",
  name = "",
  datalist,
  errors,
  register,
  ...rest
}) => {
  return (
    <div className="mb-4">
      <label className="block my-2">
        {title && <h5 className="font-bold my-1">{title}</h5>}
        <div className="flex items-center">
          {icon && <span className="inline-block w-6 h-6 mr-2">{icon}</span>}
          <select
            type="text"
            id={title}
            className={`border rounded w-full py-2 px-4 ${
              errors.visibility ? "border-red-500" : ""
            }`}
            {...register}
            defaultValue={"Public"}
            {...rest}
          >
            {datalist.length > 0 &&
              datalist.map((li, i) => (
                <option key={i} value={li.value}>
                  {li.title}
                </option>
              ))}
          </select>
        </div>
      </label>
      {errors[name] && (
        <p className="text-red-500 text-xs mt-1">{errors[name].message}</p>
      )}
    </div>
  );
};

export default InputSelect;
