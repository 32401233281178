import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../../Contexts/AuthContext";

const UserRoute = ({ children }) => {
  let location = useLocation();
  const { isLoggedin } = useAuthContext();

  if (!isLoggedin) {
    return <Navigate to={"/auth"} replace state={{ from: location }} />;
  } else return children;
};

export default UserRoute;
