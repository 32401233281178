import React from "react";
import { Link } from "react-router-dom";
import HoverItemText from "../SiteComponants/SmallComponents/HoverItemText";
import { useEffect } from "react";
import { useState } from "react";
import { Fade } from "react-swift-reveal";

const ItemCard = ({ prod }) => {
  const { id, name, photo, category, productInfo } = prod;
  const [badge, setBadge] = useState(false);
  useEffect(() => {
    const newBage = productInfo.find(
      (item) =>
        item.prevPrice !== "" && parseInt(item.prevPrice) > parseInt(item.price)
    );
    setBadge(!!newBage === true);
  }, [productInfo]);
  return (
    <Link to={"/products/" + id}>
      <div className="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2 text-center">
        <Fade>
          <div className="my-2 group hover:-translate-y-2 duration-150 relative h-full  mx-2 flex flex-col justify-between">
            <div className="relative overflow-hidden aspect-[4/3] bg-front dark:dark-bg-front shadow-lg  rounded-lg">
              {badge && (
                <span className="text-sm inline-block absolute top-1 left-1 bg-orange-500 px-3 py-1 text-gray-200 rounded-lg z-10">
                  Offer
                </span>
              )}
              <img
                className="h-full mx-auto duration-300 group-hover:scale-150"
                src={photo}
                alt={name}
                loading="lazy"
              />
              {/* <HoverItemText text={"View Product"} /> */}
            </div>
            <div className="py-2 overflow-hidden">
              <h2 className=" text-center text-sm  lg:text-lg font-mono font-bold duration-300 group-hover:text-orange-500">
                {name?.split(" ").length > 5
                  ? name?.split(" ").slice(0, 5).join(" ") + "..."
                  : name}
              </h2>
            </div>
          </div>
        </Fade>
      </div>
    </Link>
  );
};

export default ItemCard;
