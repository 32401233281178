import React, { useEffect, useState } from "react";
const Textinput = ({
  title = "",
  icon = "",
  name = "",
  datalist = [],
  errors = {},
  register = {},
  className = "",
  ...rest
}) => {
  return (
    <div className="mb-4">
      <label className="block my-2 relative">
        {title && <h5 className="font-bold my-1 capitalize">{title}</h5>}
        <div className="flex items-center">
          {icon && <span className="inline-block w-6 h-6 mr-2">{icon}</span>}
          <input
            className={`bg-back dark:dark-bg-back ${className}`}
            list={title + "datalist"}
            {...register}
            {...rest}
          />
        </div>
      </label>
      {datalist.length > 0 && (
        <datalist id={title + "datalist"}>
          {datalist.length > 0 &&
            datalist.map((li, i) => (
              <option key={i} value={li}>
                {title + " - " + li}
              </option>
            ))}
        </datalist>
      )}
      {errors[name] && (
        <p className="text-red-500 text-xs mt-1 capitalize">{errors[name].message}</p>
      )}
    </div>
  );
};

export default Textinput;
