import React from "react";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import LoadingScreen from "./LoadingScreen";
import FloatingSupport from "./FloatingSupport";
import { Toaster } from "react-hot-toast";
import DynamicMetatag from "./DynamicMetatag";
import SomeSpace from "./SomeSpace";
import "./../../Datas/Functions/event.js";

const AllExtraComponantInOne = () => {
  return (
    <>
      <SomeSpace />
      <DynamicMetatag />
      <FloatingSupport />
      <ScrollToTop />
      <LoadingScreen />
      <div id="custom-mouse"></div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        // pauseDuration={1000}
        toastOptions={{
          duration: 2000,
        }}
      />
    </>
  );
};

export default AllExtraComponantInOne;
