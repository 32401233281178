import {
  child,
  get,
  getDatabase,
  onValue,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import { app } from "./../firebase.config";
import { uuid } from "../../Datas/Functions/common";
import { getAuth } from "firebase/auth";

const database = getDatabase(app);
const auth = getAuth(app);
//console.log(auth.currentUser);
const mainPath = "banners/";
// banner create
export const createBanner = (data) => {
  return new Promise((res, err) => {
    const id = uuid("ban_");
    data.id = id;
    data.created_at = new Date().getTime();
    try {
      set(ref(database, mainPath + id), data);
      return res({
        message: "Banner Successfully uploaded",
      });
    } catch (error) {
      return err({
        message: "Something went wrong",
      });
    }
  });
};

// bannner update
export const updateBanner = (data, id) => {
  return new Promise((res, err) => {
    if (!id) {
      return err({
        message: "Id is not Provided",
      });
    }
    try {
      update(ref(database, mainPath + id), data);
      return res({
        message: "Banner is Successfully updated",
      });
    } catch (error) {
      return err({
        message: "Something went wrong",
      });
    }
  });
};

// bannner delete
export const deleteBanner = (id) => {
  return new Promise((res, err) => {
    if (!id) {
      return err({
        message: "Item is not Seleted or not valid",
      });
    }
    try {
      remove(ref(database, mainPath + id));
      return res({
        message: "Banner Item Deleted",
      });
    } catch (error) {
      return err({
        message: "Something went wrong",
      });
    }
  });
};

// get all banners
export const getBanners = () =>
  new Promise((res, err) => {
    get(child(ref(database), mainPath)).then((snapshot) => {
      if (snapshot.exists()) {
        const bannerSnap = snapshot.val();
        return res({
          message: "All data fetched",
          data: Object.values(bannerSnap).sort(
            (a, b) => b.created_at - a.created_at
          ),
        });
      } else {
        return err({
          message: "Database not found",
        });
      }
    });
  });
