import React, { useEffect } from "react";
import NoItemFound from "../../Components/SiteComponants/NoItemFound";
import { useState } from "react";
import ProfileLeft from "../../Components/PorfileSections/ProfileLeft";
import ProfileRight from "../../Components/PorfileSections/ProfileRight";
import { TitleSetup } from "../../Datas/Functions/titleSetup";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import { useAuthContext } from "../../Contexts/AuthContext";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { getOneUser } from "../../Firebase/Realtime/Users";

const Profile = (meta) => {
  // setting up title of this page
  TitleSetup({ ...meta });
  //********** */
  const { user } = useAuthContext();
  const [showProfile, setShowProfile] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const { id } = useParams();
  // console.log({ id });
  useEffect(() => {
    if (id) {
      getOneUser(id)
        .then((res) => {
          // console.log({ oneUsergetResponse: res });
          setShowProfile(res.data);
        })
        .catch((err) => {
          // console.log({ oneUsergetError: err });
        });
    } else {
      setShowProfile(user);
    }
    // console.log({ check: user["auth.isAdmin"] });
  }, [user, id]);
  return (
    <div className="container mx-auto p-4 my-4">
      <SectionHeader heading={"Profile"} />
      <BreadCrumbs texts={["Dashboard", "Profile Section"]} />
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
        {showProfile ? (
          <>
            <ProfileLeft
              user={showProfile}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
            />
            <ProfileRight
              user={showProfile}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
            />
          </>
        ) : (
          <NoItemFound msg={"No User Found"} />
        )}
      </div>
    </div>
  );
};

export default Profile;
