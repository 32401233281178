import React, { useEffect, useRef } from "react";
const FAQcard = ({ ques, ans, id, heading, isOpen, setIsOpen }) => {
  const descRef = useRef(null);
  useEffect(() => {
    descRef.current.innerHTML = ans || "";
  }, [ans]);
  return (
    <div className="border first:rounded-t-md last:rounded-b-md overflow-hidden">
      <div
        className={`flex justify-start items-center  py-3 px-4 cursor-pointer border-b bg-back dark:dark-bg-back`}
        onClick={() =>
          setIsOpen((prev) => (heading + id === prev ? "" : heading + id))
        }
      >
        <span className={` pr-3 inline-block origin-center text-center`}>
          <i
            className={`${
              isOpen === heading + id
                ? "rotate-0 fa-minus"
                : "rotate-90 fa-plus"
            } duration-300 fa `}
            aria-hidden="true"
          ></i>
        </span>
        <h3 className="text-xl font-light">{ques}</h3>
      </div>
      <div
        className={` transition-all description-output w-full duration-500 px-4 overflow-auto ${
          isOpen === heading + id ? "max-h-96 pt-3 pb-14" : "max-h-0 pt-0 pb-0"
        } `}
      >
        <div ref={descRef}></div>
      </div>
    </div>
  );
};

export default FAQcard;
