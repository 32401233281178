import React from "react";
import { TitleSetup } from "../Datas/Functions/titleSetup";
import { Outlet } from "react-router-dom";
import DashboardSivenav from "../Components/SiteComponants/DashboardSivenav";
const Dashboard = (meta) => {
  TitleSetup({...meta});
  return (
    <div>
      <DashboardSivenav />
      <Outlet/>
    </div>
  );
};

export default Dashboard;
