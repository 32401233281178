import {
  child,
  get,
  getDatabase,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import { app } from "./../firebase.config";
import { getAuth } from "firebase/auth";

const database = getDatabase(app);
const auth = getAuth(app);
//console.log(auth.currentUser);
const mainPath = "users/";
// User create
export const createUser = (data, id) => {
  return new Promise((res, err) => {
    data.id = id;
    data.created_at = new Date().getTime();
    try {
      set(ref(database, mainPath + id), data);
      return res({
        message: "User Successfully Created",
        data: data,
      });
    } catch (error) {
   //   console.log({ acc: error });
      return err({
        message: "Account Creation : Something went wrong",
      });
    }
  });
};

// User update
export const updateUser = (data, id) => {
  return new Promise((res, err) => {
    if (!id) {
      return err({
        message: "Id is not Provided",
      });
    }
    try {
      update(ref(database, mainPath + id), data);
      return res({
        message: "User is Successfully updated",
      });
    } catch (error) {
      return err({
        message: "Something went wrong",
      });
    }
  });
};

// User delete
export const deleteUser = (id) => {
  return new Promise((res, err) => {
    if (!id) {
      return err({
        message: "User ID is not Seleted or not valid",
      });
    }
    try {
      remove(ref(database, mainPath + id));
      return res({
        message: "User Item Deleted",
      });
    } catch (error) {
      return err({
        message: "Something went wrong",
      });
    }
  });
};

// get all Users
export const getUsers = () =>
  new Promise((res, err) => {
    get(child(ref(database), mainPath)).then((snapshot) => {
      if (snapshot.exists()) {
        const bannerSnap = snapshot.val();
        return res({
          message: "All User Data fetched",
          data: Object.values(bannerSnap).sort(
            (a, b) => b.created_at - a.created_at
          ),
        });
      } else {
        return err({
          message: "No User Found",
        });
      }
    });
  });

// get One User
export const getOneUser = (id) =>
  new Promise((res, err) => {
    get(child(ref(database), mainPath + id)).then((snapshot) => {
      if (snapshot.exists()) {
        const userSnap = snapshot.val();
        return res({
          message: "User data fetched",
          data: userSnap,
        });
      } else {
        return err({
          message: "User not found",
        });
      }
    });
  });
