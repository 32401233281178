import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import React, { useEffect, useState } from "react";

import { Line } from "react-chartjs-2";
import { useSiteData } from "../../Contexts/SiteContext";
import moment from "moment";
import { fill, monthHas_N_days, range } from "../../Datas/Functions/common";
import { monthlist } from "../../Datas/SiteData/AllinOne";
import { isIncluded } from "../../Datas/Functions/shareFunctions";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const OrderChart = () => {
  const { orderList } = useSiteData();
  const [selectTagMonthList, setSelectTagMonthList] = useState([]);
  const [selectTagYearList, setSelectTagYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(parseInt(moment().year()));
  const [selectedMonth, setSelectedMonth] = useState(
    parseInt(moment().month())
  );
  const [completedOrderList, setCompletedOrderList] = useState([]);
  const [monthlyLabels, setMonthlyLabels] = useState([]);
  // set only for the completed orderlist
  useEffect(() => {
    const newCompletedOrder = orderList.filter((ord) =>
      isIncluded(ord.order_status, "complete")
    );
    setCompletedOrderList(newCompletedOrder);
  }, [orderList]);
  // set month list for select tag
  useEffect(() => {
    const newMonthlist = range(
      0,
      selectedYear === moment().year() ? moment().month() : 11
    );
    setSelectTagMonthList(newMonthlist);
  }, [selectedYear]);
  useEffect(() => {
    const newYearlist = [
      ...new Set(
        completedOrderList.map((ord) => {
          const year = moment(parseInt(ord.created_at)).year();

          return year;
        })
      ),
    ]
      .filter((item) => item !== undefined)
      .sort((a, b) => b - a);
   // console.log(newYearlist);
    setSelectTagYearList(newYearlist);
  }, [completedOrderList]);
  useEffect(() => {
    let dataList = fill(0, monthHas_N_days(selectedMonth, selectedYear));

    completedOrderList.forEach((ord, i) => {
      const month = moment(parseInt(ord.created_at)).month() === selectedMonth;
      const year = moment(parseInt(ord.created_at)).year() === selectedYear;
      if (year && month) {
        const date = moment(parseInt(ord.created_at)).date();
        dataList[date - 1]++;
      }
    });
    setMonthlyLabels(dataList);
  }, [selectedMonth, selectedYear, completedOrderList]);
  return (
    <div className=" col-span-12 md:col-span-6">
      <div className="box bg-front dark:dark-bg-front dark:dark-text p-4">
        <h2 className="text-lg font-semibold mb-4">Order Tracking by Month</h2>
        {completedOrderList.length > 0 ? (
          <>
            <select
              className="w-full md:w-1/2 px-3 py-2 border rounded-sm"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
            >
              {selectTagMonthList.map((month, i) => (
                <option key={i} value={month}>
                  {monthlist[month]}
                </option>
              ))}
            </select>
            <select
              className="w-full md:w-1/2 px-3 py-2 border rounded-sm"
              value={selectedYear}
              onChange={(e) => setSelectedYear(parseInt(e.target.value))}
            >
              {selectTagYearList.map((year, i) => (
                <option key={i} value={year}>
                  {year}
                </option>
              ))}
            </select>
            <div>
              <Line
                options={{
                  responsive: true,
                  y: {
                    beginAtZero: true,
                  },
                  plugins: {
                    legend: {
                      position: "top",
                    },
                    title: {
                      display: true,
                      text: "Monthly Order Stats of " + selectedYear,
                    },
                  },
                }}
                data={{
                  labels: range(1, monthlyLabels.length),

                  datasets: [
                    {
                      label:
                        "Sells of " +
                        monthlist[selectedMonth] +
                        " " +
                        selectedYear,

                      data: monthlyLabels,
                      borderColor: "rgba(255, 153, 0, 0.827)",
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                    },
                  ],
                }}
              />
            </div>
          </>
        ) : (
          <h1>There's no Sales Yet</h1>
        )}
      </div>
    </div>
  );
};

export default OrderChart;
