import { Tooltip, capitalize } from "@mui/material";
import React, { useEffect, useState } from "react";
import { contactInfo } from "../../Datas/PersonalData/personalInfo";
import { useSiteData } from "../../Contexts/SiteContext";
import { isIncluded, isObjEmpty } from "../../Datas/Functions/shareFunctions";
import { findLink } from "../../Datas/Functions/common";

const FloatingSupport = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const { allSocial } = useSiteData();
  const [whatsapp, setWhatsapp] = useState({});
  useEffect(() => {
    const newData = allSocial?.find((item) => isIncluded(item?.key, "whatsapp"));
    setWhatsapp(newData);
  }, [allSocial]);
  return (
    <div
      className={`h-auto fixed  bottom-20 md:bottom-5 md:right-3 right-1 flex flex-col justify-between items-centers z-10 ${
        isOpen ? "pointer-events-auto" : "pointer-events-none"
      }`}
    >
      {/* <div
        className={`duration-300 origin-bottom ${
          isOpen ? "scale-100 opacity-100" : "scale-0 opacity-0"
        }`}
      >
        <Tooltip title={contacts[0].title} placement="left">
          <button
            onClick={() => window.open(contacts[0].link)}
            className={`floating-support ${contacts[0].bgclass} hover:text-brand text-white`}
          >
            <i
              className={`fab fa-${contacts[0].title.toLowerCase()}`}
              aria-hidden="true"
            ></i>
          </button>
        </Tooltip>
      </div> */}
      {/* <Tooltip title={"Support"} placement="left">
        <button
          onClick={() => setIsOpen((prev) => !prev)}
          className={`floating-support z-40 text-orange-700 dark-bg-front opacity-80 hover:opacity-100 relative pointer-events-auto`}
        >
          {!isOpen && (
            <>
              <span className="absolute -top-1 -right-1 animate-ping w-2 h-2 p-2 bg-orange-500 rounded-full "></span>
              <span className="absolute ring-1 ring-white -top-1 -right-1  w-2 h-2 p-2 bg-orange-500 rounded-full"></span>
            </>
          )}
          {isOpen ? (
            <i className="fa fa-times" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-headphones" aria-hidden="true"></i>
          )}
        </button>
      </Tooltip> */}

      {whatsapp && !isObjEmpty(whatsapp) && (
        <Tooltip
          title={capitalize(whatsapp?.key) + " Support"}
          placement="left"
        >
          <button
            onClick={() => window.open(findLink(whatsapp))}
            className={`border floating-support z-100 text-green-500 hover:bg-green-600 hover:text-white dark-bg-front opacity-80 hover:opacity-100 relative pointer-events-auto  aspect-square p-3 md:w-14 w-10 md:h-14 h-10 animate-bounce hover:animate-none`}
          >
            <>
              <span className="absolute top-0 right-0 animate-ping  hover:animate-none w-full h-full opacity-10 bg-green-500 rounded-full "></span>
            </>
            <i className={`fab fa-${whatsapp?.key}`} aria-hidden="true"></i>
          </button>
        </Tooltip>
      )}
      <Tooltip title={"Scroll to Top"} placement="left">
        <button
          onClick={() =>
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
          }
          className={`floating-support  z-40 text-orange-700 dark-bg-front opacity-80 hover:opacity-100 relative pointer-events-auto border p-3 md:w-14 w-10 md:h-14 h-10`}
        >
          <i className="fa fa-arrow-up" aria-hidden="true"></i>
        </button>
      </Tooltip>
    </div>
  );
};

export default FloatingSupport;
