import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import SectionHeader from "./../../Components/SectionHeader/SectionHeader";
import { notify } from "../../Datas/Functions/toast";
import Textinput from "../../Components/SiteComponants/FormElements/Textinput";
import ImageInput from "../../Components/SiteComponants/FormElements/ImageInput";
import InputSelect from "../../Components/SiteComponants/FormElements/InputSelect";
import InputDescription from "../../Components/SiteComponants/FormElements/InputDescription";
import { uploadImage } from "./../../Datas/Functions/upload.js";
import { useSiteData } from "./../../Contexts/SiteContext";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { TitleSetup } from "../../Datas/Functions/titleSetup";
import { useNavigate, useParams } from "react-router-dom";
import { createProduct, updateProduct } from "../../Firebase/Realtime/Products";
import { emptyProductValues } from "../../Datas/emptyValues";
import NoItemFound from "../../Components/SiteComponants/NoItemFound";

const AddProduct = (meta) => {
  TitleSetup({ ...meta });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      productInfo: [{ title: "", price: "", stock: "Available" }], // Initial productInfo object
      customerInput: [{}], // Initial customInput object
    },
  });
  const [descriptionValue, setDescriptionValue] = useState("");
  // const [howToOrder, setHowToOrder] = useState("");
  // const [howToOrderVisible, setHowToOrderVisible] = useState(false);
  const [imgUrl, setimgUrl] = useState("");
  const [itemEditable, setItemEditable] = useState(true);
  const [uploading, setUploading] = useState(false);
  const { pid } = useParams();
  const navigate = useNavigate();
  const {
    fields: productInfoFields,
    remove: productInfoRemove,
    append: productInfoAppend,
  } = useFieldArray({
    control,
    name: "productInfo",
  });
  const {
    fields: customerInputFields,
    remove: customerInputRemove,
    append: customerInputAppend,
  } = useFieldArray({
    control,
    name: "customerInput",
  });
  const { setIsLoading, categories, products } = useSiteData();

  // image upload section
  const handleUploadImage = async (e) => {
    setUploading(true);
    if (!e.target.files[0]) return;
    const res = await uploadImage(e.target.files[0]);
    if (res.status === 200) setimgUrl(res.data.data.url);
    else notify("Error", "Something Went Wrong", "error");
    //  console.log(res);
    setUploading(false);
  };

  // submit form section to handle
  const onSubmit = async (data) => {
    // console.log({ data, pid });
    if (imgUrl === "") {
      notify("Warning", "Photo is not Uploaded yet", "error");
      return;
    }
    setIsLoading(true);
    data.description = descriptionValue;
    data.photo = imgUrl;
    // data.howToOrder = howToOrderVisible ? howToOrder : "";
    if (pid) {
      // console.log({ data, pid });
      updateProduct(data, data.id)
        .then((res) => {
          // console.log({ productUpdate: res });
          // console.log({ data, pid });
          notify("Success", res.message, "success");
          clearAll();
          setIsLoading(false);
          navigate("/dashboard/add-product", { replace: true });
        })
        .catch((err) => {
          // console.log({ data, pid, err });
          notify("Warning", err.message, "error");
          setIsLoading(false);
        });
    } else {
      createProduct(data)
        .then((res) => {
          // console.log({ productCreation: res });
          notify("Success", res.message, "success");
          clearAll();
          setIsLoading(false);
        })
        .catch((err) => {
          notify("Warning", err.message, "error");
          setIsLoading(false);
        });
    }
  };
  // what ever need to be clear after a product update
  const clearAll = () => {
    setimgUrl("");
    setDescriptionValue("");
    // setHowToOrder("");
    // setHowToOrderVisible(false);
    reset(emptyProductValues);
  };
  useEffect(() => {
    setIsLoading(true);
    const item = products.find((prod) => prod.id === pid);
    if (item && pid) {
      reset(item);
      setDescriptionValue(item.description);
      setimgUrl(item.photo);
      // setHowToOrder(item.howToOrder);
      // if (item.howToOrder) {
      //   setHowToOrderVisible(true);
      // }
    }
    setItemEditable(!pid || (pid && item));
    setIsLoading(false);
  }, [pid, products]);
  return (
    <div className="container md:mx-auto mb-10 ">
      {itemEditable ? (
        <>
          <SectionHeader heading={pid ? "Manage Product" : "Add Product"} />
          <BreadCrumbs
            texts={["Dashboard", pid ? "Manage Product" : "Add Product"]}
          />
          <div className="px-4 py-3 dark:dark-bg-front bg-front box  ">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="md:px-4 grid grid-cols-12 md:gap-4 gap-1">
                <div className="lg:col-span-8 md:col-span-6 col-span-full">
                  <div className="my-4">
                    <Textinput
                      title={"Product Name"}
                      name={"name"}
                      type="text"
                      errors={errors}
                      className={`border rounded w-full py-2 px-4 ${
                        errors.name ? "border-red-500" : "border-orange-300"
                      }`}
                      placeholder="Enter product name"
                      register={register("name", {
                        required: "Product name is required",
                      })}
                    />
                    <InputDescription
                      title={"Description"}
                      value={descriptionValue}
                      setValue={setDescriptionValue}
                      className={`border  placeholder:dark:text-white `}
                      placeholder="Describe the product . . . "
                    />
                  </div>
                  <div className="my-4">
                    <div className="mb-4">
                      <label htmlFor="productInfo" className="font-bold">
                        Product Price Informations
                      </label>
                      {productInfoFields.map((field, idx) => (
                        <div
                          key={field.id}
                          className={`grid grid-cols-12 gap-4 pr-5 my-2 relative  p-2`}
                        >
                          {/* Delete Button */}
                          <span
                            className="absolute top-0 right-0 flex items-center justify-center w-6 h-6 rounded-full border bg-white cursor-pointer"
                            onClick={() =>
                              1 !== productInfoFields.length
                                ? productInfoRemove(idx)
                                : notify(
                                    "Operation Denied",
                                    "You must keep atleast one row",
                                    "error"
                                  )
                            }
                          >
                            <i
                              className="fa fa-trash text-sm  text-red-600"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <div className="col-span-12 sm:col-span-7 md:col-span-12">
                            <label htmlFor={`title${idx + 1}`}>{`Title ${
                              idx + 1
                            }`}</label>
                            <input
                              type="text"
                              id={`Title${idx + 1}`}
                              className={`border rounded w-full py-2 px-4 ${
                                errors.productInfo &&
                                errors.productInfo[idx]?.title
                                  ? "border-red-500"
                                  : "border-orange-300"
                              }`}
                              placeholder="Enter title"
                              {...register("productInfo." + idx + ".title", {
                                required: "Title is required",
                              })}
                            />
                            {errors.productInfo &&
                              errors.productInfo[idx]?.title && (
                                <p className="text-red-500 text-xs mt-1">
                                  {errors.productInfo[idx].title.message}
                                </p>
                              )}
                          </div>
                          <div className="col-span-12 sm:col-span-5 md:col-span-4 md:order-3">
                            <label htmlFor={`Stock${idx + 1}`}>{`Stock ${
                              idx + 1
                            }`}</label>
                            <select
                              id={`Stock${idx + 1}`}
                              className={`border rounded w-full py-2 px-4 ${
                                errors.productInfo &&
                                errors.productInfo[idx]?.stock
                                  ? "border-red-500"
                                  : "border-orange-300"
                              }`}
                              placeholder="Select Availability"
                              {...register("productInfo." + idx + ".stock", {
                                required: "Stock is required",
                              })}
                              defaultValue={"Available"}
                            >
                              <option value={"Available"}>
                                Stock Available
                              </option>
                              <option value={"Unavailable"}>
                                No Stock Available
                              </option>
                            </select>
                            {errors.productInfo &&
                              errors.productInfo[idx]?.stock && (
                                <p className="text-red-500 text-xs mt-1">
                                  {errors.productInfo[idx].stock.message}
                                </p>
                              )}
                          </div>
                          <div className="col-span-12 sm:col-span-6 md:col-span-4 md:order-1">
                            <label
                              htmlFor={`PrevPrice${idx + 1}`}
                            >{`Previous Price ${idx + 1}`}</label>
                            <input
                              type="number"
                              id={`PrevPrice${idx + 1}`}
                              className={`border rounded w-full py-2 px-4 border-orange-300`}
                              placeholder="Enter Previous price in Taka"
                              {...register(
                                "productInfo." + idx + ".prevPrice",
                                { required: false }
                              )}
                            />
                          </div>
                          <div className="col-span-12 sm:col-span-6 md:col-span-4 md:order-2">
                            <label
                              htmlFor={`Price${idx + 1}`}
                            >{`Current Price ${idx + 1}`}</label>
                            <input
                              type="number"
                              onKeyDown={(e) => e.keyCode !== 69}
                              id={`Price${idx + 1}`}
                              className={`border rounded w-full py-2 px-4 ${
                                errors.productInfo &&
                                errors.productInfo[idx]?.price
                                  ? "border-red-500"
                                  : "border-orange-300"
                              }`}
                              placeholder="Enter Current price in Taka"
                              {...register("productInfo." + idx + ".price", {
                                required: "Price is required",
                              })}
                            />
                            {errors.productInfo &&
                              errors.productInfo[idx]?.price && (
                                <p className="text-red-500 text-xs mt-1">
                                  {errors.productInfo[idx].price.message}
                                </p>
                              )}
                          </div>
                        </div>
                      ))}

                      <button
                        type="button"
                        className="py-2 px-4 bg-orange-500 border site-btn rounded-lg"
                        onClick={() =>
                          productInfoAppend({
                            title: "",
                            prevPrice: "",
                            price: "",
                            stock: "Available",
                          })
                        }
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i> Add
                        Row
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-span-full md:col-span-6 lg:col-span-4 divide-x-2">
                  <div className="my-4">
                    {/* select category section  */}
                    <Textinput
                      title={"Category"}
                      name={"category"}
                      type="text"
                      errors={errors}
                      className={`border rounded w-full py-2 px-4 ${
                        errors.category ? "border-red-500" : "border-orange-300"
                      }`}
                      datalist={categories.map((item) => item.title)}
                      placeholder="Enter category"
                      register={register("category", {
                        required: "Category is required",
                      })}
                    />
                    {/* image upload section of product  */}
                    {imgUrl && (
                      <div className="h-40 aspect-video mx-auto p-2 border ">
                        <img
                          className="max-h-full max-w-full mx-auto"
                          src={imgUrl}
                          alt="Selected Img"
                        />
                      </div>
                    )}
                    <ImageInput
                      title={"Product Image"}
                      name={"photo"}
                      errors={errors}
                      className={`border rounded w-full py-2 px-4 ${
                        errors.photo ? "border-red-500" : "border-orange-300"
                      }`}
                      placeholder="Upload Your Photo"
                      register={register("photo", {
                        required: pid ? false : "Photo is required",
                      })}
                      onChange={handleUploadImage}
                      defaultValue={imgUrl}
                    />
                    {/* Product Visibility section of product  */}

                    <InputSelect
                      title={"Product Visibility"}
                      name={"visibility"}
                      errors={errors}
                      className={`border rounded w-full py-2 px-4 ${
                        errors.visibility
                          ? "border-red-500"
                          : "border-orange-300"
                      }`}
                      defaultValue={"Public"}
                      datalist={[
                        {
                          title: "Public",
                          value: "Public",
                        },
                        {
                          title: "Private",
                          value: "Private",
                        },
                      ]}
                      placeholder="Select Product visibility"
                      register={register("visibility", {
                        required: "Select Product visibility",
                      })}
                    />
                    <div className="mb-4">
                      <label className="block my-2 relative cursor-pointer">
                        <h5 className="font-bold my-1">Popularity CheckBox</h5>
                        <div className="flex items-center ring-1 ring-orange-300 bg-back dark:dark-bg-back rounded p-2">
                          <input
                            className="mr-3 outline-double outline-slate-500 inline-block cursor-pointer"
                            type="checkbox"
                            {...register("isPopular")}
                          />
                          <span>Mark as Popular</span>
                        </div>
                      </label>
                    </div>
                    <div className="my-4">
                      <div className="p-2 border-orange-500">
                        <label htmlFor="customerInput" className="font-bold">
                          Input Information : (If needed)
                        </label>
                        {customerInputFields.map((field, idx) => (
                          <div
                            key={field.id}
                            className="grid grid-cols-1 md:grid-cols-2 gap-2 pr-5 my-2 relative "
                          >
                            <div className="col-span-1">
                              <label
                                htmlFor={`title${idx + 1}`}
                              >{`Enter Title ${idx + 1}`}</label>
                              <input
                                type="text"
                                id={`Title${idx + 1}`}
                                className={`border rounded w-full py-2 px-4 ${
                                  errors.customerInput &&
                                  errors.customerInput[idx]?.title
                                    ? "border-red-500"
                                    : "border-orange-300"
                                }`}
                                placeholder="Enter Input Title"
                                {...register(
                                  "customerInput." + idx + ".title",
                                  {
                                    required: "Title is required",
                                  }
                                )}
                              />
                              {errors.customerInput &&
                                errors.customerInput[idx]?.title && (
                                  <p className="text-red-500 text-xs mt-1">
                                    {errors.customerInput[idx].title.message}
                                  </p>
                                )}
                            </div>
                            <div className="col-span-1 ">
                              <label htmlFor={`Type${idx + 1}`}>{`Input Type ${
                                idx + 1
                              }`}</label>
                              <select
                                id={`Stock${idx + 1}`}
                                className={`border rounded w-full py-2 px-4 ${
                                  errors.customerInput &&
                                  errors.customerInput[idx]?.type
                                    ? "border-red-500"
                                    : "border-orange-300"
                                }`}
                                placeholder="Select Input Type"
                                {...register("customerInput." + idx + ".type", {
                                  required: "Type is required",
                                })}
                                defaultValue={"text"}
                              >
                                <option value={"text"}>Text</option>
                                <option value={"number"}>Number</option>
                                <option value={"refer"}>Reference</option>
                                <option value={"pubg_id"}>Pubg ID</option>
                              </select>
                              {errors.customerInput &&
                                errors.customerInput[idx]?.stock && (
                                  <p className="text-red-500 text-xs mt-1">
                                    {errors.customerInput[idx].stock.message}
                                  </p>
                                )}
                            </div>
                            <span
                              className="absolute top-7 -right-2 flex items-center justify-center w-6 h-6 rounded-full border border-gray-400 cursor-pointer"
                              onClick={() => customerInputRemove(idx)}
                            >
                              <i
                                className="fa fa-times text-xl "
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        ))}
                        <br />
                        <button
                          type="button"
                          className="py-2 px-4 bg-orange-500 border site-btn rounded-lg"
                          onClick={() =>
                            customerInputAppend({
                              title: "",
                              type: "text",
                            })
                          }
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i> Add
                          input
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap justify-end items-center my-3 col-span-full ">
                  <button
                    disabled={uploading}
                    type="submit"
                    className={`rounded ml-2 px-7 py-3 my-2 flex items-center disabled:cursor-not-allowed ring-1 ring-orange-500 hover:bg-green-600  justify-between `}
                  >
                    {uploading ? (
                      <>
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline w-4 h-4 mr-3 dark:dark-text animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                        Uploading...
                      </>
                    ) : (
                      <>
                        <i className="fa fa-save mr-3" aria-hidden="true"></i>
                        Save Product
                      </>
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={() => reset()}
                    className="rounded m-2 px-7 py-3 flex items-center border border-orange-500 hover:bg-red-600"
                  >
                    <i className="fa fa-times mr-3" aria-hidden="true"></i>
                    Reset All
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : (
        <NoItemFound msg={"Wrong Product to Edit + Product Not found"} />
      )}
    </div>
  );
};

export default AddProduct;
