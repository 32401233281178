import React, { useEffect, useRef, useState } from "react";
import { TitleSetup } from "../../Datas/Functions/titleSetup";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { clickToCopy, isIncluded } from "../../Datas/Functions/shareFunctions";
import { useSiteData } from "../../Contexts/SiteContext";
import { notify } from "./../../Datas/Functions/toast";
import { useAuthContext } from "../../Contexts/AuthContext";
import { numToBDT } from "../../Datas/Functions/common";
import { Tooltip } from "@mui/material";

const Invoice = (meta) => {
  // setting up title of this page
  TitleSetup({ ...meta });
  //********** */
  const { id } = useParams();
  const printRef = useRef();
  const { setIsLoading, orderList } = useSiteData();
  const [orderItem, setOrderItem] = useState(null);
  const { isAdmin } = useAuthContext();
  useEffect(() => {
    setIsLoading(true);
    const order = orderList?.find((item) => item.id === id);
    // console.log(order);
    setOrderItem(order);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const downloadPDF = () => {
    if (isIncluded(orderItem?.order_status, "canc")) {
      notify("Order has been Canceled");
    }
    const elementToPrint = printRef.current;
    if (elementToPrint) {
      const printWindow = window.open(
        "https://localhost:3000",
        "_blank",
        `width=600,height=300,title=Invoice ${orderItem.id}`
      );
      printWindow.document.open();
      printWindow.document.write(
        `<html><head><title>Print</title><style>body{font-family:'Gill Sans','Gill Sans MT',Calibri,'Trebuchet MS',sans-serif}.bg-front{background-color:#f5f5f5}.dark-bg-front{background-color:#333}.box{border:1px solid #ccc;border-radius:8px;box-shadow:0 2px 4px rgba(0,0,0,.1);padding:20px;margin:20px}.text-center{text-align:center}.text-3xl{font-size:1.875rem}.font-bold{font-weight:700}.text-brand{color:#ff7300}.text-gray-600{color:#666}.mt-4{margin-top:1rem}.flex{display:flex}.justify-between{justify-content:space-between}.justify-end{justify-content:flex-end}.mb-2{margin-bottom:.5rem}.font-semibold{font-weight:600}.text-gray-500{color:#888}.table{display:table;width:100%;border-collapse:collapse}.thead{display:table-header-group}.th{display:table-cell;padding:8px;text-align:left;border-bottom:1px solid #ccc;font-weight:700}.colSpan{colspan:2}.text-xl{font-size:1.25rem}.text-right{text-align:right}.pr-10{padding-right:2.5rem}.text-amber-600{color:#ffb400}.fas{font-family:'Font Awesome 5 Free'}.fa{font-family:'Font Awesome 4'}.text-green-600{color:#00a859}.text-red-700{color:#d9534f}.text-yellow-400{color:#f0ad4e}.text-xl{font-size:1.25rem}.text-amber-600{color:#ffb400}.py-2{padding-top:.5rem;padding-bottom:.5rem}.text-left{text-align:left}.w-full{width:100%}.py-5{padding-top:1.25rem;padding-bottom:1.25rem}.text-right{text-align:right}.border-b{border-bottom:1px solid #ccc}.text-amber-600{color:#ffb400}.border-b-2{border-bottom-width:2px}.font-semibold{font-weight:600}.text-lg{font-size:1.125rem}</style></head><body>`
      );
      printWindow.document.write(elementToPrint.innerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
    } else {
      notify("Permission Denied", "The item can not be printed", "error");
    }
  };
  if (isAdmin || (orderItem && isIncluded(orderItem.order_status, "compl"))) {
    return (
      <div className="container my-5">
        <SectionHeader heading={"Order Details"} />
        <BreadCrumbs texts={["Dashboard", "Order Details"]} />
        <div className="flex justify-end my-3">
          <button onClick={downloadPDF} className="site-btn px-3 py-2">
            Download Invoice
            <i className="fa fa-download ml-2" aria-hidden="true"></i>
          </button>
        </div>
        <div
          ref={printRef}
          className="bg-front dark:dark-bg-front box px-8 py-6"
        >
          <div className="text-center">
            <h1 className="text-3xl font-bold">
              Details of <span className="font-bold">Order</span>
            </h1>
            <p className="text-gray-600">Invoice #{orderItem?.id}</p>
          </div>

          <div className="mt-4">
            <div className="flex justify-between mb-2 flex-wrap">
              <div className="mr-auto">
                <p className="font-bold text-xl underline">Paid By:</p>
                <p className="text-gray-500">
                  {isAdmin ? (
                    <Tooltip title="Click to see Customer Details" arrow>
                      <Link to={"../manage-user/" + orderItem?.customer_id}>
                        {orderItem?.customer_name}
                      </Link>
                    </Tooltip>
                  ) : (
                    orderItem?.customer_name
                  )}
                </p>
                <p className="text-gray-500">{orderItem?.order_account}</p>
              </div>
              <div className="ml-auto">
                <table>
                  <thead>
                    <tr>
                      <th
                        colSpan={2}
                        className="font-bold text-xl text-right underline"
                      >
                        Invoice Details
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="pr-10 text-gray-600">Order Status </td>
                      <td className=" text-right">
                        {isIncluded(orderItem?.order_status, "proc") ? (
                          <span>
                            <i className="fas fa-clock    mr-1 text-amber-600"></i>
                            {orderItem?.order_status}
                          </span>
                        ) : isIncluded(orderItem?.order_status, "comp") ? (
                          <span>
                            <i
                              className="fa fa-check text-green-600 mr-1"
                              aria-hidden="true"
                            ></i>
                            {orderItem?.order_status}
                          </span>
                        ) : (
                          <span>
                            <i
                              className="fa fa-times text-red-700 mr-1"
                              aria-hidden="true"
                            ></i>
                            {orderItem?.order_status}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="pr-10 text-gray-600">Payment Method </td>
                      <td className=" text-right">
                        {orderItem?.order_payment_method}
                      </td>
                    </tr>
                    <tr>
                      <td className="pr-10 text-gray-600">Payment Account </td>
                      <td className=" text-right">
                        {orderItem?.order_account}
                      </td>
                    </tr>
                    <tr>
                      <td className="pr-10 text-gray-600">Purchased at </td>
                      <td className=" text-right">
                        {moment(parseInt(orderItem?.created_at)).format(
                          "MMMM Do, YYYY"
                        )}
                      </td>
                    </tr>
                    {orderItem?.orderCancelReason && (
                      <tr>
                        <td className="pr-10 text-gray-600">Cancel Reason</td>
                        <td className=" text-right">
                          {orderItem?.orderCancelReason}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="mt-4 overflow-auto">
              <h2 className="text-xl font-semibold">Order Details</h2>
              <table className="w-full mt-2 text-left ">
                <thead>
                  <tr className="border-b">
                    <th className="py-2 px-4">Product</th>
                    <th className="py-2 px-4">Ordered</th>
                    <th className="py-2 px-4">Qty</th>
                    <th className="py-2 px-4">Rate</th>
                    <th className="py-2 px-4 text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td className="px-4 py-5">{orderItem?.product_name}</td>
                    <td className="px-4 py-5">{orderItem?.order_name}</td>
                    <td className="px-4 py-5">01</td>
                    <td className="px-4 py-5 ">
                      {numToBDT(orderItem?.order_price)}
                    </td>
                    <td className="px-4 py-5 text-right">
                      {numToBDT(orderItem?.order_price)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="mt-4 flex justify-end">
              <table className="border-b-2">
                <tbody className="pb-3">
                  <tr>
                    <td className="pr-4  font-semibold">Sub Total </td>
                    <td className=" text-right">
                      {numToBDT(orderItem?.order_price)}
                    </td>
                  </tr>
                  <tr>
                    <td className="pr-4 font-semibold">Tax </td>
                    <td className=" text-right">{numToBDT("00")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-4 text-right">
              <p className="font-semibold text-lg">Total Amount:</p>
              <p className="text-xl">{numToBDT(orderItem?.order_price)}</p>
            </div>
          </div>
        </div>
        {orderItem?.userInput?.length > 0 && (
          <div className="bg-front dark:dark-bg-front box px-8 py-6 my-5">
            <h2 className="text-2xl font-semibold text-center my-3">
              User Input Section
            </h2>
            <div className="text-center w-full md:w-1/2 mx-auto">
              {/* {orderItem?.userInput?.map((input, i) => (
              <div
                key={i}
                className="px-10 py-5 border font-semibold inline-block my-4 mx-3 rounded-md"
              >
                <span>{input.key}</span> :
                <span
                  className="text-brand cursor-pointer"
                  onClick={() => {
                    clickToCopy(input.val);
                    notify("", "Text Copied to ClipBoard", "success");
                  }}
                >
                  {input.val}
                </span>
              </div>
            ))} */}
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="text-left">Title</th>
                    <th className="text-center"></th>
                    <th className="text-right">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {orderItem?.userInput?.map((input, i) => (
                    <tr className="">
                      <td className="text-left py-2">{input.key}</td>
                      <td className="text-center py-2">:</td>
                      <td className="text-right py-2">
                        <span
                          className="text-brand cursor-pointer"
                          onClick={() => {
                            clickToCopy(input.val);
                            notify("", "Text Copied to ClipBoard", "success");
                          }}
                        >
                          {input.val}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="bg-front dark:dark-bg-front box px-8 py-10 my-5 text-center">
        <h1>You have no right to enter this page</h1>
      </div>
    );
  }
};

export default Invoice;
