import React from "react";
import { Link } from "react-router-dom";

const Viewmore = ({ link }) => {
  return (
    <div className="my-3 flex items-center">
      <Link to={link} className="mx-auto">
        <button
          className="site-btn px-3 py-1 mx-auto inline-block"
        >
          View more
        </button>
      </Link>
    </div>
  );
};

export default Viewmore;
