import { toast } from "react-hot-toast";
import {
  errorIcon,
  infoIcon,
  successIcon,
} from "../NotificationDatas/toastIcons";

export const notify = (
  title,
  subTitle = "Something Happened",
  mode = "",
  btn = false
) => {
  if (mode)
    toast.custom((t) => (
      <div
        onClick={() => toast.remove(t.id)}
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } cursor-pointer bg-front w-96 dark:dark-bg-front rounded-lg px-3 py-4 shadow-lg border-4 ${
          mode === "success"
            ? "border-green-600"
            : mode === "error"
            ? "border-red-800"
            : "border-orange-600"
        } font-sans`}
      >
        <div className="flex flex-row">
          <div className="px-2">
            {mode === "success"
              ? successIcon
              : mode === "error"
              ? errorIcon
              : mode === "info"
              ? infoIcon
              : ""}
          </div>
          <div className="ml-2 mr-6 ">
            <h1 className="font-bold">{title}</h1>
            <h3 className="block text-gray-800 dark:text-gray-200">
              {subTitle}
            </h3>
          </div>
        </div>
      </div>
    ));
  else toast(title);
};
