import React, { useEffect, useState } from "react";
import { TitleSetup } from "../../Datas/Functions/titleSetup";
import moment from "moment";
import { isIncluded } from "../../Datas/Functions/shareFunctions";
import MenuButton from "../../Components/SiteComponants/MenuButton";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import NoItemFound from "../../Components/SiteComponants/NoItemFound";
import FakeTableComponent from "../../Components/FakeTableComponent/FakeTableComponent";
import Swal from "sweetalert2";
import { useSiteData } from "../../Contexts/SiteContext";
import {
  deleteReview,
  getReviews,
  updateReview,
} from "../../Firebase/Realtime/Review";
import { notify } from "../../Datas/Functions/toast";
import { swalColors } from "../../Datas/SiteData/colorRelated";

const ManageReview = (meta) => {
  // setting up title of this page
  TitleSetup({ ...meta });
  //********** */
  const { isDark } = useSiteData();
  const [searchedText, setSearchedText] = useState("");
  const [selectedItem, setSelectedItem] = useState("name");
  const [datalist, setDatalist] = useState([]);
  const [reviewList, setReviewList] = useState([]);

  const column = [
    {
      name: "User",
      selector: (row) => (
        <div>
          <p>{row.name}</p>
        </div>
      ),
      sortable: "name",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: "email",
    },
    {
      name: "Rating",
      selector: (row) => <span>{row.rating}</span>,
      sortable: "rating",
    },
    {
      name: "Reviewed",
      selector: (row) => (
        <span>{moment(parseInt(row.created_at)).fromNow()}</span>
      ),
      sortable: "created_at",
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          {row.isAccepted ? (
            <span className="flex justify-start items-center">
              <span className="text-green-600">Accepted</span>
              <span
                onClick={() => deletingReview(row)}
                className="text-red-600 hover:text-indigo-500 rounded ml-4 text-xl"
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </span>
            </span>
          ) : (
            <div className="flex flex-wrap">
              <MenuButton text={"Action"}>
                <ul className="cursor-pointer ">
                  <li
                    className="border-y py-2 px-4 flex justify-between hover:bg-gray-300 hover:text-gray-900"
                    onClick={() => viewingReview(row)}
                  >
                    <span>
                      <i className="fa fa-eye text-blue-800" aria-hidden="true"></i>
                    </span>
                    <span>View</span>
                  </li>
                  <li
                    className="border-y py-2 px-4 flex justify-between hover:bg-gray-300 hover:text-gray-900"
                    onClick={() => acceptingReview(row)}
                  >
                    <span>
                      <i
                        className="fa fa-check text-green-500"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <span>Approve</span>
                  </li>
                  <li
                    className="border-y py-2 px-4 flex justify-between hover:bg-gray-300 hover:text-gray-900"
                    onClick={() => deletingReview(row)}
                  >
                    <span>
                      <i
                        className="fa fa-times text-red-500"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <span>Delete</span>
                  </li>
                </ul>
              </MenuButton>
            </div>
          )}
        </div>
      ),
      sortable: "order_status",
    },
  ];

  const selectionOption = [
    {
      title: "Name",
      value: "name",
    },
    {
      title: "Email",
      value: "email",
    },
    {
      title: "Rating",
      value: "rating",
    },
  ];

  useEffect(() => {
    handleReviewUpdateOnMount();
  }, []);
  useEffect(() => {
    setDatalist(reviewList);
  }, [reviewList]);

  // for filtering or searching purpose
  useEffect(() => {
    // search by options on the top of the table
    const newDatalist = reviewList
      .filter(
        (data) =>
          isIncluded(String(data[selectedItem]), searchedText) ||
          searchedText === ""
      )
      .sort((a, b) => parseInt(b.created_at) - parseInt(a.created_at));
    setDatalist(newDatalist);
  }, [searchedText, selectedItem, reviewList]);

  // get all  review everytime this functioin call && or delete or update any product
  const handleReviewUpdateOnMount = () => {
    getReviews()
      .then((res) => {
        setReviewList(res.data);
      })
      .catch((err) => {
        // notify("Warning", err.message, "error");
      });
  };

  // handler function
  const viewingReview = (review) => {
    Swal.fire({
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Accept Review",
      denyButtonText: `Delete Review`,
      cancelButtonText: `Back`,
      background: isDark === "dark" ? swalColors.darkBgFront : "",
      color: isDark === "dark" ? "#ffffff" : "#000000",
      html: `
      <div class="flex flex-col bg-front dark:dark-bg-front p-3 items-center dark:text-dark">
          <div className="my-2">
              <img class="aspect-square rounded-full w-20 mx-auto" src=${review.img} alt=${review.name} />
          </div>
          <h3 class="text-2xl">${review.name}</h3>
          <h5 class="text-lg">${review.email}</h5>
          <h5 class="text-lg">
          Rating : ${review.rating} <i class="fa fa-star text-yellow-500" aria-hidden="true"></i>
          </h5>
          <div class="p-4 border rounded-lg w-full mt-5">
              <h4 class="text-lg text-left" >Reviewed:</h4>
              <p class="text-sm ">
                          ${review.review}
              </p>
          </div>
          
      </div>
  `,
    }).then((result) => {
      if (result.isConfirmed) {
        acceptingReview(review);
      } else if (result.isDenied) {
        deletingReview(review);
      }
    });
  };

  // for deleting review
  const deletingReview = (review) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Review for " + review?.name + " will be deleted",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: `Back`,
      background: isDark === "dark" ? swalColors.darkBgFront : "",
      color: isDark === "dark" ? "#ffffff" : "#000000",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReview(review.id)
          .then((res) => {
            notify("Success", res.message, "success");
            handleReviewUpdateOnMount();
          })
          .catch((err) => {
            notify("Warning", err.message, "error");
          });
      }
    });
  };
  // for accepting review
  const acceptingReview = (review) => {
    updateReview({ isAccepted: true }, review.id)
      .then((res) => {
        notify("Success", res.message, "success");
        handleReviewUpdateOnMount();
      })
      .catch((err) => {
        notify("Warning", err.message, "error");
      });
  };
  return (
    <div className="w-full mx-2 md:container md:mx-auto">
      <SectionHeader heading={"Order List"} />
      <BreadCrumbs texts={["Dashboard", "Reviewlist"]} />

      <FakeTableComponent
        datalist={datalist}
        column={column}
        pagination
        cursorPointer
        rowPerPageItems={[5, 10, 15, 20, 30, 40, 50, 100]}
        tableScroll
        tableScrollHeight={"500px"}
        tableHeaderComponent={
          <h2 className="text-4xl text-center font-semibold">Review List</h2>
        }
        filterComponents={[
          <div className="border-2 rounded-sm my-2 mx-3">
            <select
              className="px-3 py-2 h-full sm:w-auto w-full sm:border-r-2 sm:border-b-0 border-b focus:outline-0 "
              onChange={(e) => setSelectedItem(e.target.value)}
              defaultValue={selectedItem}
            >
              {selectionOption.map((opt, i) => {
                return (
                  opt.title && (
                    <option key={i} value={opt.value}>
                      Seacrh by {opt.title}
                    </option>
                  )
                );
              })}
            </select>
            {selectedItem !== "rating" ? (
              <input
                onChange={(e) => setSearchedText(e.target.value)}
                className="px-3 py-2 w-40 sm:w-52  focus:outline-0"
                placeholder={`Search...🔎`}
              />
            ) : (
              <div className="px-3 py-2 w-40 sm:w-52  focus:outline-0 inline-block items-center flex-wrap flex-row">
                {["1", "2", "3", "4", "5"].map((star, i) => (
                  <span>
                    {" "}
                    <input
                      name="rating"
                      type="radio"
                      className="inline-block ml-2"
                      key={i}
                      value={star}
                      onChange={(e) => setSearchedText(e.target.value)}
                    />{" "}
                    {star}
                  </span>
                ))}
              </div>
            )}
          </div>,
        ]}
        nodataComponent={<NoItemFound msg={"No Review found"} />}
      />
    </div>
  );
};

export default ManageReview;
