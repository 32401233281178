import React, { useEffect, useState } from "react";
import NoItemFound from "../../Components/SiteComponants/NoItemFound";
import { createSearchParams, useNavigate } from "react-router-dom";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import FakeTableComponent from "../../Components/FakeTableComponent/FakeTableComponent";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { TitleSetup } from "../../Datas/Functions/titleSetup";
import MenuButton from "./../../Components/SiteComponants/MenuButton";
import {
  deleteUser,
  getUsers,
  updateUser,
} from "../../Firebase/Realtime/Users";
import { notify } from "../../Datas/Functions/toast";
import { useAuthContext } from "../../Contexts/AuthContext";
import { useSiteData } from "../../Contexts/SiteContext";
import Swal from "sweetalert2";
import { swalColors } from "../../Datas/SiteData/colorRelated";
import { clickToCopy } from "../../Datas/Functions/shareFunctions";
import moment from "moment";
import { Tooltip } from "@mui/material";

const UserList = (meta) => {
  // setting up title of this page
  TitleSetup({ ...meta });
  //********** */
  const navigate = useNavigate();
  const [searchedText, setSearchedText] = useState("");
  const [selectedItem, setSelectedItem] = useState("name");
  const [allUserList, setAllUserList] = useState([]);
  const [datalist, setDatalist] = useState([]);
  const { isSuperAdmin } = useAuthContext();

  const { isDark } = useSiteData();
  useEffect(() => {
    getUsers()
      .then((res) => {
        setAllUserList(res.data);
      })
      .catch((err) => {
        notify("Warning", err.message, "error");
      });
  }, []);
  useEffect(() => {
    setDatalist(allUserList);
  }, [allUserList]);
  useEffect(() => {
    const newDatalist = allUserList.filter((data) =>
      String(data[selectedItem])
        .toLowerCase()
        .includes(searchedText.toLowerCase())
    );
    setDatalist(newDatalist);
  }, [searchedText, selectedItem]);

  const column = [
    {
      name: "ID",
      selector: (row) => (
        <span
          className="nav-item cursor-pointer"
          onClick={() => {
            notify("User ID Copied");
            clickToCopy(row.id);
          }}
        >
          {row.id}
        </span>
      ),
      sortable: "id",
    },
    {
      name: "Name",
      selector: (row) => (
        <span>
          {row.name}{" "}
          {row.auth.isAdmin && (
            <Tooltip
              title={
                row.auth?.isSuperAdmin
                  ? "Super Admin"
                  : row.auth?.isAdmin
                  ? "Admin"
                  : ""
              }
            >
              <sup className="text-brand text-xs px-1 font-semibold rounded-2xl bg-orange-500 text-gray-900 cursor-pointer">
                {row.auth?.isSuperAdmin ? "SA" : row.auth?.isAdmin ? "A" : ""}
              </sup>
            </Tooltip>
          )}
        </span>
      ),
      sortable: "name",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: "email",
    },
    {
      name: "Created",
      selector: (row) => {
        const time = moment(parseInt(row.created_at)).format('MMMM Do YYYY, h:mm:ss');
        return (
          <Tooltip title={time}>
            <span className="cursor-pointer">{moment(row.created_at).fromNow()}</span>
          </Tooltip>
        );
      },
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex flex-wrap">
          <MenuButton text={"Action"}>
            <ul className="cursor-pointer ">
              <li
                className="border-y py-2 px-4 flex justify-between hover:bg-gray-300 hover:text-gray-900"
                onClick={() => navigate("" + row.id)}
              >
                <span>
                  <i className="fa fa-eye text-blue-500" aria-hidden="true"></i>
                </span>
                <span>View Profile</span>
              </li>
              <li
                className="border-y py-2 px-4 flex justify-between hover:bg-gray-300 hover:text-gray-900"
                onClick={() =>
                  navigate({
                    pathname: "../manage-order",
                    search: createSearchParams({
                      search_item: "customer_id",
                      search_value: row.id,
                    }).toString(),
                  })
                }
              >
                <span>
                  <i className="fa fa-eye text-blue-500" aria-hidden="true"></i>
                </span>
                <span>See Orders</span>
              </li>
              {isSuperAdmin && (
                <li
                  className="border-y py-2 px-4 flex justify-between hover:bg-gray-300 hover:text-gray-900"
                  onClick={() => handleAdminAppointment(row)}
                >
                  <span>
                    {row.auth.isAdmin ? (
                      <i
                        className="fa fa-trash text-red-500"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-user text-green-500"
                        aria-hidden="true"
                      ></i>
                    )}
                  </span>
                  <span>
                    {row.auth.isAdmin ? "Remove Admin" : "Appoint as Admin"}
                  </span>
                </li>
              )}
              <li
                className="border-y py-2 px-4 flex justify-between hover:bg-gray-300 hover:text-gray-900"
                onClick={() => handleDeleteUser(row)}
              >
                <span>
                  <i
                    className="fa fa-trash text-red-500"
                    aria-hidden="true"
                  ></i>
                </span>
                <span>Delete User</span>
              </li>
            </ul>
          </MenuButton>
        </div>
      ),
    },
  ];
  const selectionOption = [
    {
      title: "ID",
      value: "id",
    },
    {
      title: "Name",
      value: "name",
    },
    {
      title: "Email",
      value: "email",
    },
  ];
  // handler function
  const handleAdminAppointment = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text:
        "User : " +
        data?.name +
        " will be " +
        (data.auth.isAdmin ? "removed from admin" : "appoint as Admin"),
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: `Back`,
      background: isDark === "dark" ? swalColors.darkBgFront : "",
      color: isDark === "dark" ? "#FFFFFF" : "#000000",
    }).then((result) => {
      if (result.isConfirmed && isSuperAdmin) {
        updateUser(
          {
            auth: {
              isAdmin: !data?.auth?.isAdmin,
            },
          },
          data.id
        )
          .then((res) => {
            notify("Success", res.message, "success");
          })
          .catch((err) => {
            notify(
              "Warning",
              "Access Denied. You dont have enough permission to complete the action",
              "error"
            );
          });
      }
    });
  };
  const handleDeleteUser = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "User : " + data?.name + " will be deleted from database",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: `Back`,
      background: isDark === "dark" ? swalColors.darkBgFront : "",
      color: isDark === "dark" ? "#ffffff" : "#000000",
    }).then((result) => {
      if (result.isConfirmed && !data.auth.isSuperAdmin) {
        deleteUser(data.id)
          .then((res) => {
            notify("Success", res.message, "success");
          })
          .catch((err) => {
            notify("Warning", err.message, "error");
          });
      } else {
        notify("Unauthorised Action", "Superadmin can not be deleted", "error");
      }
    });
  };

  return (
    <div className="container">
      <SectionHeader heading={"User List"} />
      <BreadCrumbs texts={["Dashboard", "Userlist"]} />
      <FakeTableComponent
        datalist={datalist}
        column={column}
        pagination
        rowPerPageItems={[5, 10, 15, 20, 30, 40, 50, 100]}
        tableScroll
        tableScrollHeight={"500px"}
        sortIcon={<i className="fa-solid fa-caret-down"></i>}
        tableHeaderComponent={
          <h2 className="text-4xl text-center font-semibold">User List</h2>
        }
        filterComponents={[
          <div className="border-2 rounded-sm my-2 mx-3 grid grid-cols-1 md:grid-cols-2 ">
            <select
              className="px-3 py-2 h-full col-span-1 sm:border-r-2 sm:border-b-0 border-b focus:outline-0 "
              onChange={(e) => setSelectedItem(e.target.value)}
              defaultValue={selectedItem}
            >
              {selectionOption.map((opt, i) => (
                <option key={i} value={opt.value}>
                  Seacrh by {opt.title}
                </option>
              ))}
            </select>
            <input
              onChange={(e) => setSearchedText(e.target.value)}
              className="px-3 py-2 col-span-1  focus:outline-0"
              placeholder={`Search...🔎`}
            />
          </div>,
        ]}
        nodataComponent={<NoItemFound msg={"No data found"} />}
      />
    </div>
  );
};

export default UserList;
