import React from "react";
import { useAuthContext } from "../../Contexts/AuthContext";
import { useSiteData } from "../../Contexts/SiteContext";
import DashboardMiniCard from "../../Components/SiteComponants/DashboardMiniCard";
import OrderChart from "../../Components/SiteComponants/OrderChart";
import SellPieChart from "../../Components/SiteComponants/SellPieChart";
import SectionHeader from "../../Components/SectionHeader/SectionHeader";
import { TitleSetup } from "../../Datas/Functions/titleSetup";
import { useEffect } from "react";
import { getUsers } from "../../Firebase/Realtime/Users";
import { numToBDT } from "../../Datas/Functions/common";

const DashboardHome = (meta) => {
  TitleSetup({ ...meta });
  const { user, isAdmin, logout } = useAuthContext();
  const { userList, setUserList, products, orderList } = useSiteData();
  useEffect(() => {
    if (isAdmin) {
      getUsers()
        .then((res) => {
          setUserList(res.data);
        })
        .catch((err) => {});
    }
  }, []);
  if (!isAdmin) {
    return (
      <div className="container mb-auto">
        <div className="box border box rounded p-10 my-10 dark:dark-bg-front bg-front text-center">
          <h1 className="text-4xl text-center">Hi, {user?.name}</h1>
          <button className="site-btn px-5 py-3 my-10" onClick={logout}>
            Log Out
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <div className=" box rounded p-10 my-10 dark:dark-bg-front bg-front text-center">
        <h1 className="text-4xl text-center">Hi, {user?.name}</h1>
        <button className="site-btn px-5 py-3 my-10" onClick={logout}>
          Log Out
        </button>
      </div>
      <div className="box rounded grid grid-cols-12 gap-4 p-10 my-10 dark:dark-bg-front bg-front">
        <DashboardMiniCard text={"Total Users:"} value={userList?.length} />
        <DashboardMiniCard text={"Total Products:"} value={products.length} />
        <DashboardMiniCard
          text={"Total Order:"}
          value={
            orderList.filter((item) => item.order_status === "Completed").length
          }
        />
        <DashboardMiniCard
          text={"Total Sells:"}
          value={numToBDT(
            orderList
              .filter((item) => item.order_status === "Completed")
              .reduce((res, ord) => res + parseInt(ord.order_price), 0)
          )}
        />
      </div>
      <SectionHeader heading={"Sells Analytics"} />
      <div className="grid grid-cols-12 gap-4 my-10 ">
        <OrderChart />
        <SellPieChart />
      </div>
    </div>
  );
};

export default DashboardHome;
