import React from "react";
import Textinput from "../SiteComponants/FormElements/Textinput";

const CustomerAccountInfo = ({ customerInput, errors, register }) => {
  return (
    customerInput && (
      <div className="relative my-6 bg-front dark:dark-bg-front box">
        <h1 className="font-bold my-3 py-2 text-lg md:text-xl text-left block border-b-2 border-b-orange-400">
          <i className="fas fa-user text-center ml-2  inline-block p-1 rounded-full bg-orange-500 text-white w-7 aspect-square  "></i>{" "}
          Customer Info
        </h1>
        <div className="p-4">
          {customerInput?.map((inp, i) => (
            <Textinput
              key={i}
              title={inp.title}
              name={inp.title}
              type={
                inp.type === "refer"
                  ? "text"
                  : inp.type === "pubg_id"
                  ? "number"
                  : inp.type
              }
              placeholder={"Enter " + inp.title}
              errors={errors}
              className={`border rounded w-full py-2 px-4 ${
                errors[inp.title] ? "border-red-500" : "border-orange-300"
              }`}
              register={register(inp.title, {
                required:
                  inp.type !== "refer" ? inp.title + " is required" : false,
                pattern: {
                  value: inp.type === "pubg_id" ? /^5[0-9]{4,11}$/ : /./,
                  message: "Please Enter Correct ID",
                },
              })}
            />
          ))}
        </div>
      </div>
    )
  );
};

export default CustomerAccountInfo;
