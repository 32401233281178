import React, { useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import { useSiteData } from "../../../Contexts/SiteContext";

const InputDescription = ({
  title = "",
  value,
  setValue,
  placeholder,
  ...rest
}) => {
  const { isDark } = useSiteData();
  // useEffect(() => {}, []);
  const editor = useRef(null);
  const config = {
    readonly: false,
    theme: "",
    placeholder: placeholder || "Start typings...",
    askBeforePasteHTML: true,
    askBeforePasteFromWord: false,
    width: "auto",
    height: "auto",
    minHeight: 300,
    buttons: [
      "source",
      "|",
      "bold",
      "strikethrough",
      "underline",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      "outdent",
      "indent",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "image",
      "video",
      "table",
      "link",
      "|",
      "align",
      "undo",
      "redo",
      "|",
      "hr",
      "eraser",
      "copyformat",
      "|",
      "fullsize",
    ],
  };

  return (
    <div className="mb-4">
      <label className="inline-block w-full my-2  ">
        {title && <h5 className="font-bold my-1">{title}</h5>}
      </label>
      <span className=" description-output  dark:dark-text focus:text-black cursor-text">
        <JoditEditor
          // style={{ color: "black" }}
          ref={editor}
          value={value}
          config={{
            readonly: false,
            theme: isDark,
            placeholder: placeholder || "Decription...",
            width: "auto",
            height: "auto",
            minHeight: 300,
            buttons: [
              "bold",
              "italic",
              "underline",
              "strikethrough",
              // "|",
              "ul",
              "ol",
              // "|",
              "outdent",
              "indent",
              // "|",
              "font",
              "fontsize",
              "brush",
              "paragraph",
              // "|",
              "image",
              "video",
              "table",
              "link",
              // "|",
              "align",
              "undo",
              "redo",
              // "|",
              "hr",
              "eraser",
              "copyformat",
              // "|",
              "fullsize",
            ],
          }}
          tabIndex={1} 
          onBlur={(txt) => setValue(txt)}
        />
      </span>

      {/* <div
        className="description-output ring-1 ring-orange-500 w-full rounded-lg"
        dangerouslySetInnerHTML={{ __html: value }}
      ></div> */}
    </div>
  );
};

export default InputDescription;
