import React from "react";

const HoverItemText = ({ text = "Click" }) => {
  return (
    <>
      <span className="absolute top-0 left-0 w-full h-full opacity-0 invisible bg-orange-300 group-hover:opacity-50 group-hover:visible  duration-300  font-bold rounded-t"></span>
      <span className="absolute top-0 left-0 opacity-0 duration-300  font-bold text-lg group-hover:opacity-100  w-full h-full flex items-center  justify-center rounded-t text-gray-950">
        {text}
      </span>
    </>
  );
};

export default HoverItemText;
