import {
  child,
  get,
  getDatabase,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import { app } from "./../firebase.config";
import { uuid } from "../../Datas/Functions/common";
import { getAuth } from "firebase/auth";

const database = getDatabase(app);
const auth = getAuth(app);
//console.log(auth.currentUser);

// Faq Craetion create
export const createFaq = (data) => {
  return new Promise((res, err) => {
    const id = uuid({
      start: "faq_",
      len: 25,
      digit: true,
      lower: true,
      upper: true,
      hypen: false,
    });
    data.id = id;
    data.created_at = new Date().getTime();
    try {
      set(ref(database, "faq/" + id), data);
      return res({
        message: "FAQ Successfully uploaded",
      });
    } catch (error) {
      return err({
        message: "Something went wrong",
      });
    }
  });
};

// Faq update
export const updateFaq = (data, id) => {
  return new Promise((res, err) => {
    if (!id) {
      return err({
        message: "Item is not Seleted or not valid",
      });
    }
    try {
      update(ref(database, "faq/" + id), data);
      return res({
        message: "Selected FAQ is Successfully updated",
      });
    } catch (error) {
      return err({
        error,
        message: "Something went wrong",
      });
    }
  });
};

// faq delete
export const deleteFaq = (id) => {
  return new Promise((res, err) => {
    if (!id) {
      return err({
        message: "Item is not Seleted or not valid",
      });
    }
    try {
      remove(ref(database, "faq/" + id));
      return res({
        message: "Selected FAQ is successfully deleted",
      });
    } catch (error) {
      return err({
        message: "Something went wrong",
      });
    }
  });
};

// get all faq
export const getFaqs = () =>
  new Promise((res, err) => {
    get(child(ref(database), "faq/"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const bannerSnap = snapshot.val();
          return res({
            message: "All data fetched",
            data: Object.values(bannerSnap).sort(
              (a, b) => a.created_at - b.created_at
            ),
          });
        } else {
          err({
            message: "No data found",
          });
        }
      })
      .catch((error) => {
        err({
          message: "Something went wrong",
        });
      });
  });
