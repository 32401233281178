export function shareOnline(link, siteName) {
  let url = encodeURIComponent(link); // Replace with your product page URL
  if (siteName === "facebook")
    url = "https://www.facebook.com/sharer/sharer.php?u=" + url;
  else if (siteName === "twitter")
    url = "https://twitter.com/intent/tweet?url=" + url;
  else if (siteName === "linkedin")
    url = "https://www.linkedin.com/sharing/share-offsite/?url=" + url;
  else return;
  window.open(url, "_blank");
}

// check if the object is empty
export const isObjEmpty = (obj = {}) => {
  if (obj) return Object.keys(obj).length === 0;
  else return false;
};

// included in string or not
export const isIncluded = (str = "", pattern = "") => {
  return str.toLowerCase().includes(pattern.toLowerCase());
};

// string to link converter feature functions
export const nameToLinkConverter = (name) => {
  return name.toLowerCase().replace(/\s+/g, "-");
};

// popular product extraction
export const extractPopularProduct = (products) => {
  return products.filter((item) => item.isPopular);
};

// make string upperCase
export const capitalize = (string) => {
  return string[0].toUpperCase() + string.slice(1, string.length).toLowerCase();
};

// to copy String
export const clickToCopy = (string = "") => {
  if (
    typeof navigator !== "undefined" &&
    typeof navigator.clipboard !== "undefined" &&
    navigator.permissions !== "undefined"
  ) {
    return navigator.clipboard.writeText(string);
  } else {
    const textarea = document.createElement("textarea");
    document.body.appendChild(textarea);
    textarea.textContent = string;
    textarea.focus();
    textarea.select();
    textarea.setSelectionRange(0, 9999);
    document.execCommand("copy");
    document.body.removeChild(textarea);
    window.scrollTo(0, 0);
  }
};

// to copy String
export const errorMsgHandler = (string = "") => {
  return string
    .slice(5, string.length)
    .split("-")
    .map((txt) => capitalize(txt))
    .join(" ");
};

// Make a certein product item in front of the list
export const bringItToFront = (array = [], checkerString, type) => {
  return array.sort((a, b) => {
    if (
      isIncluded(a[type], checkerString) &&
      isIncluded(b[type], checkerString)
    ) {
      return 0;
    } else if (isIncluded(a[type], checkerString)) {
      return -1;
    } else if (isIncluded(b[type], checkerString)) {
      return 1;
    } else {
      return 0;
    }
  });
};
