import React from "react";

const ImageInfoBox = () => {
  const imageinfos = [
    {
      img: "./imagebox/email.png",
      title: "Email Delivery",
      subtitle: "Instantly Delivery within 5-15 Minutes",
    },
    {
      img: "./imagebox/pay.png",
      title: "Payment Options",
      subtitle: "Bkash, Nagad, Bank",
    },
    {
      img: "./imagebox/support.png",
      title: "Delivery and Support",
      subtitle: "Available from 8 am to 12 am",
    },
    {
      img: "./imagebox/trust.png",
      title: "Trust ability",
      subtitle: "Check Website and Page reviews for trust issue",
    },
  ];
  return (
    <div className=" text-center px-5 py-10 grid grid-cols-12 my-20 gap-4">
      {imageinfos.map((inf, i) => (
        <div
          key={i}
          className="flex flex-wrap flex-col items-center col-span-12 md:col-span-3 sm:col-span-6 md:my-5 my-0 md:px-4 md:py-4 py-2"
        >
          <img className="w-16" src={inf.img} alt="" />
          <h1 className="font-bold my-2">{inf.title}</h1>
          <h1 className="font-light">{inf.subtitle}</h1>
        </div>
      ))}
    </div>
  );
};

export default ImageInfoBox;
