import React, { useEffect, useState } from "react";
import "./FakeTablePredefinedStyle.css";
const FakeTableComponent = ({
  datalist,
  column,
  hoverable,
  cursorPointer,
  filterComponents,
  tableHeaderComponent,
  nodataComponent,
  tableScroll,
  tableScrollHeight = "250px",
  pagination,
  rowPerPageItems = [5, 10, 15, 20, 50],
  ...rest
}) => {
  const [list, setList] = useState([]);
  const [showlist, setShowList] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const [selectedKey, setSelectedKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [firstIndex, setFirstIndex] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(rowPerPageItems[1]);

  // previous value set
  useEffect(() => {
    setList(datalist);
    setColumnList(column);
  }, [datalist, column]);

  // custom on click sort section
  useEffect(() => {
    let newList = showlist.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[selectedKey] > b[selectedKey] ? 1 : -1;
      } else if (sortOrder === "dec") {
        return a[selectedKey] < b[selectedKey] ? 1 : -1;
      } else return 1;
    });
   // console.log(newList);
    setShowList(newList);
  }, [sortOrder]);

  const handleSortClick = (sortable) => {
    if (sortable !== undefined) {
      setSortOrder((prev) => (prev === "asc" ? "dec" : "asc"));
      setSelectedKey(sortable);
    }
  };

  // pagination section
  useEffect(() => {
    const newShowlist = list.slice(0, rowPerPage);
    setShowList(newShowlist);
  }, [list]);
  useEffect(() => {
    const newShowlist = list.slice(firstIndex, firstIndex + rowPerPage);
    setShowList(newShowlist);
  }, [rowPerPage, firstIndex]);

  return (
    <div className="faketable-wrapper">
      <div className="faketable-header">
        {tableHeaderComponent && (
          <div className="faketable-header-Title-section">
            {tableHeaderComponent}
          </div>
        )}

        {filterComponents && (
          <div className="faketable-header-filter-section z-10 ">
            {filterComponents.map((cmp, i) => (
              <span key={i}>{cmp}</span>
            ))}
          </div>
        )}
      </div>
      <div className={`faketable-wrapper-table no-scrollbar ${tableScroll ? "scrollable" : ""}`}
        style={{
          height: tableScrollHeight && tableScroll ? tableScrollHeight : "auto",
        }}
      >
        <table className="faketable">
          <thead className="faketable-thead-thead">
            <tr className="faketable-thead-tr">
              {columnList.length > 0 &&
                columnList.map((col, i) => {
                  return (
                    col.name && (
                      <th
                        key={i}
                        onClick={() => handleSortClick(col.sortable)}
                        className={`faketable-thead-th group ${
                          col.sortable ? "pointer-cursor" : ""
                        }`}
                      >
                        <span className="flex items-center">
                          <span className="w-auto">{col.name || ""}</span>

                          {col.sortable && (
                            <span
                              className={`ml-2 opacity-0 group-hover:opacity-100  ${
                                sortOrder === "asc" ? "rotate-180" : "rotate-0"
                              }`}
                            >
                              <span className={`flex flex-col justify-start items-start text-sm `}>
                                <i
                                  className={`fa-solid fa-caret-up text-gray-950  inline-block m-0`}
                                ></i>
                                <i className="fa-solid fa-caret-down text-gray-500  inline-block m-0"></i>{" "}
                              </span>
                            </span>
                          )}
                        </span>
                      </th>
                    )
                  );
                })}
            </tr>
          </thead>
          <tbody
            className={`scroll ${cursorPointer ? "pointer" : ""} `}
            // data-scrollheight={tableScroll ? tableScrollHeight : ""}
            // style={{ height: tableScroll ? tableScrollHeight : "auto" }}
          >
            {showlist.map((listItem, i) => (
              <tr
                key={i}
                className={`faketable-tbody-tr ${
                  hoverable ? " table-hover-bg " : ""
                }`}
              >
                {columnList?.length > 0 &&
                  columnList.map((col, i) => {
                    return (
                      col.name && (
                        <td className="faketable-tbody-td" key={i}>
                          {col.selector(listItem)}
                        </td>
                      )
                    );
                  })}
              </tr>
            ))}

            {list.length === 0 && (
              <tr>
                <td colSpan={column?.length || 10000}>
                  {nodataComponent || (
                    <h2>No data available</h2>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {pagination && (
        <div className="faketable-pagination-wrapper">
          <div>
            <p>
              Showing {showlist.length} out of {list.length} results (
              {firstIndex + 1} -{" "}
              {firstIndex + rowPerPage > list.length
                ? list.length
                : firstIndex + rowPerPage}
              )
            </p>
          </div>
          <div className="faketable-pagination">
            <div className="faketable-pagination-btn-group">
              <p>Row per page :</p>
              <select
                defaultValue={rowPerPage}
                onChange={(e) => setRowPerPage(parseInt(e.target.value))}
              >
                {rowPerPageItems.map((itm, i) => (
                  <option key={i} value={itm}>
                    {itm}
                  </option>
                ))}
              </select>
            </div>
            <div className="faketable-pagination-btn-group">
              <button
                disabled={firstIndex === 0}
                onClick={() => setFirstIndex(0)}
                className="faketable-pagination-btn  rotate180"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  role="presentation"
                >
                  <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                </svg>
              </button>
              <button
                disabled={firstIndex === 0}
                onClick={() =>
                  setFirstIndex((prev) =>
                    prev - rowPerPage < 0 ? prev : prev - rowPerPage
                  )
                }
                className="faketable-pagination-btn rotate180"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  role="presentation"
                >
                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </button>
              <button
                disabled={firstIndex + rowPerPage >= list.length}
                onClick={() =>
                  setFirstIndex((prev) =>
                    prev + rowPerPage > list.length ? prev : prev + rowPerPage
                  )
                }
                className="faketable-pagination-btn "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  role="presentation"
                >
                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </button>
              <button
                disabled={firstIndex + rowPerPage >= list.length}
                onClick={() =>
                  setFirstIndex((prev) =>
                    list.length % rowPerPage
                      ? Math.floor(list.length / rowPerPage) * rowPerPage
                      : (Math.floor(list.length / rowPerPage) - 1) * rowPerPage
                  )
                }
                className="faketable-pagination-btn "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  role="presentation"
                >
                  <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FakeTableComponent;
