import { Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { dashboardRoutes } from "../../Datas/RouteDatas/DashboardRoutes";
import { useAuthContext } from "../../Contexts/AuthContext";

const DashboardSivenav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, logout } = useAuthContext();
  return (
    <>
      <div
        onClick={() => setIsOpen(false)}
        className={`fixed duration-300 top-0 left-0  h-screen w-screen bg-black  
        ${isOpen ? "z-30 opacity-60" : "-z-30 opacity-0"}`}
      ></div>
      <div
        className={`top-0 left-0 z-50 w-64 h-screen p-4 transition-transform ${
          isOpen ? "-translate-x-0" : "-translate-x-64"
        } bg-front dark:dark-bg-front fixed shadow-lg border-r-orange-500 border-r-2  `}
      >
        <Tooltip title={isOpen ? "Close Sidebar" : "Open Sidebar"}>
          <button
            onClick={() => setIsOpen((prev) => !prev)}
            type="button"
            className={`click-effect bg-inherit  w-12 h-12 ring-2 ring-orange-500 rounded absolute top-24  -right-14 overflow-hidden flex items-center justify-center duration-500 bg-front dark:dark-bg-front ${
              isOpen ? "rotate-180" : "rotate-0"
            }`}
          >
            <span
              className={`${
                isOpen ? "animate-none bg-transparent" : "animate-ping"
              } hover:animate-none hover:bg-transparent w-full h-full bg-orange-500 absolute rounded-full opacity-50 `}
            ></span>
            {/* <span className="-rotate-90 font-mono text-lg">Sidebar</span> */}
            <i
              className={`fa fa-arrow-right transform ${
                isOpen ? "rotate-0" : "rotate-0"
              }`}
              aria-hidden="true"
            ></i>
          </button>
        </Tooltip>
        <section className=" flex flex-col overflow-y-scroll  no-scrollbar">
          <div className="w-full">
            <h5 className="text-base font-semibold text-gray-500 uppercase dark:text-gray-400 ">
              Dashboard Navbar
            </h5>
            <div className="py-4">
              {dashboardRoutes.map((item, i) => (
                <span key={i}>
                  {(user?.auth?.isAdmin === item.isAdminRoute ||
                    user?.auth?.isAdmin) &&
                    item.isNavListed && (
                      <NavLink
                        className={`${({ isActive }) => {
                          return isActive ? "active font-bold" : "";
                        }} nav-item py-3`}
                        to={item.link}
                      >
                        <span>
                          <i className={item.icon} aria-hidden="true"></i>
                        </span>
                        <span>{item.title}</span>
                      </NavLink>
                    )}
                </span>
              ))}
            </div>
          </div>
          <div className="text-center mb-1 w-full pt-1">
            <button className="site-btn px-4 py-3" onClick={logout}>
              <i className="fa fa-sign-out mr-2" aria-hidden="true"></i>{" "}
              <span>Logout</span>
            </button>
          </div>
        </section>
      </div>
    </>
  );
};

export default DashboardSivenav;
