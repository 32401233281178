import { createContext, useContext, useEffect, useState } from "react";
import { useSiteData } from "./SiteContext";
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
} from "firebase/auth";
import { app } from "../Firebase/firebase.config";
import { getOneUser } from "../Firebase/Realtime/Users";
import { logOutFromDevice } from "../Firebase/Authentication";
import { notify } from "../Datas/Functions/toast";
import { keepOrdertracking } from "../Firebase/Realtime/Common";

export const AuthContext = createContext();

const auth = getAuth(app);

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const { setIsLoading } = useSiteData();
  // updating each value for every mounting
  useEffect(() => {
    setIsLoading(true);
    // console.log({ user });

    // setting logged in ot not status
    setIsLoggedin(!!user);
    // setting admin status
    setIsAdmin(!!user && !!user.auth.isAdmin);

    // setting superadmin status
    setIsSuperAdmin(!!user && !!user.auth.isSuperAdmin);
    setIsLoading(false);
  }, [user]);

  useEffect(() => {
    setIsLoading(true);

    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        const authUser = auth.currentUser;
        if (!authUser) {
          setIsLoading(false);
          return;
        }
        getOneUser(authUser?.uid)
          .then((res) => {
            setUser(res.data);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);
  // self definded functions
  const logout = () => {
    setIsLoading(true);
    logOutFromDevice()
      .then((res) => {
        notify("Success", res.message, "success");
        setUser(null);
        setIsLoggedin(false);
        setIsAdmin(false);
        setIsLoading(false);
      })
      .catch((err) => {
        notify("Warning", err.message, "error");
        setIsLoading(false);
      });
  };

  // order tracking according to new order
  // order tracking section
  useEffect(() => {
    if (user) keepOrdertracking(user);
  }, [user]);
  const value = {
    // user datas
    user,
    setUser,

    // checkers
    isAdmin,
    setIsAdmin,
    isSuperAdmin,
    setIsSuperAdmin,
    isLoggedin,
    setIsLoggedin,

    // log out function
    logout,
    // login,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
