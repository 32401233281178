import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routeDatas } from "./../../Datas/RouteDatas/MainRoutes";
import { getCommon } from "../../Firebase/Realtime/Common";
import { findClass, findLink } from "../../Datas/Functions/common";
import { useSiteData } from "../../Contexts/SiteContext";

const Footer = () => {
  const { allSocial, setAllSocial } = useSiteData();
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 w-full dark-bg-front dark-text ">
      <div className="col-span-full md:col-span-2 px-10 py-3 my-3 font-medium">
        <h1>
          Thanos E-shop বাংলাদেশের অন্যতম একটি অনলাইন গেমিং কারেন্সি ও গিফট
          কার্ড বিক্রয়ের সাইট। নিরাপদ এবং বিশ্বস্ততার মাধ্যমে গেমিং কারেন্সি ও
          গিফট কার্ড কেনার একটি সুপরিচিত প্রতিষ্ঠান হিসেবে এরইমধ্যে বেশ খ্যাতি
          আর সুনাম অর্জন করেছে । আমাদের পণ্যের পরিসর বৃদ্ধি করে আপনার গেমিংকে
          আরো আকর্ষণীয় করে তুলতে আমরা সদা সচেষ্ট। আপনার সেবায় আমরা ২৪/৭ নিয়োজিত।
        </h1>
      </div>
      <div className="px-10 py-3  my-3">
        <h1 className="text-lg font-medium">Contact</h1>
        <ul className="flex flex-col flex-wrap mx-auto">
          {allSocial.length > 0
            ? allSocial.map((inf, i) => (
                <li className="my-1" key={i}>
                  <span
                    className="cursor-pointer text-lg hover:text-brand duration-150 inline-block  capitalize"
                    onClick={() => window.open(findLink(inf))}
                  >
                    <i
                      className={`fab fa-${inf.key} ${findClass(
                        inf.key
                      )}  inline-block mr-2`}
                      aria-hidden="true"
                    ></i>
                    {inf.key}
                  </span>
                </li>
              ))
            : ""}
        </ul>
      </div>
      <div className="px-10 py-3  my-3">
        <h1 className="text-lg font-medium">Quick link</h1>
        <ul className="flex flex-col flex-wrap mx-auto">
          {routeDatas.length > 0 &&
            routeDatas.map((route, i) => (
              <li key={i}>
                {route.isNavbarItem && (
                  <Link className="hover:text-brand" to={route.route}>
                    <span className="nav-item inline-block">{route.title}</span>
                  </Link>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Footer;
