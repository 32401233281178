import { Tooltip } from "@mui/material";
import React from "react";

const FooterBottom = () => {
  return (
    <div className="w-full dark-bg-front bg-opacity-90 dark-text pb-20 md:pb-3 text-center p-2 font-medium">
      <div className="container mx-auto flex justify-between">
        <span>
          &copy; {new Date().getFullYear()}{" "}
          <span className="text-brand mr-1">Thanos eShop</span> All Rights
          Reserved
        </span>

        <span>
          {" "}
          Made with ❤️ by{" "}
          <a
            href="https://github.com/Shariar-Hasan"
            target="_blank"
            rel="noopenner noreferrer"
            className="text-brand"
          >
            Shariar Hasan
          </a>
        </span>
      </div>
    </div>
  );
};

export default FooterBottom;
