import React from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import { notify } from "../../Datas/Functions/toast";
import { useAuthContext } from "../../Contexts/AuthContext";
import { useSiteData } from "../../Contexts/SiteContext";
import { uploadImage } from "../../Datas/Functions/upload";
import Spinner from "../SiteComponants/SmallComponents/Spinner";
import { updateUser } from "../../Firebase/Realtime/Users";

const ProfileRight = ({ user, isEdit, setIsEdit }) => {
  const { user: myProfile } = useAuthContext();
  const {  setIsLoading } = useSiteData();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [imgUrl, setimgUrl] = useState("");
  const [uploading, setUploading] = useState(false);

  // image uploda function
  const handleUploadImage = async (e) => {
    setUploading(true);
    try {
    const res = await uploadImage(e.target.files[0]);
    if (res.status === 200) setimgUrl(res.data.data.url);
    else notify("Error", "Something Went Wrong", "error");
    setUploading(false);
    } catch (error) {
      notify(error.message, "", "error");
      setUploading(false);
      return;
    }
    
  };
  useEffect(() => {
    reset(user);
    setimgUrl(user.img);
  }, [user]);
  const onSubmit = (data) => {
    if (!imgUrl) {
      notify("Image Can not be empty ", "", "error");
    }
    data.img = imgUrl;
    setIsLoading(true);
    updateUser(data, data.id)
      .then((res) => {
        setIsLoading(false);
        setIsEdit(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsEdit(false);
        notify("Profile not updated ", "", "error");
      });
  };

  return (
    <div className="lg:col-span-2 col-span-1 dark:dark-bg-front bg-front p-5 rounded-lg box">
      <h2 className="text-2xl font-bold my-2">Profile Information</h2>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="my-5">
          <label className="capitalize font-bold my-1" htmlFor="name">
            Name :
          </label>
          <div className="flex items-center text-sm ">
            <span className="inline-block w-6 h-6 mr-2">
              <i className="fa fa-user" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              id="name"
              className={`border rounded w-full py-2 px-4 dark:dark-bg-front bg-front outline-none border-1 ${
                !isEdit ? "border-transparent " : "border-orange-300"
              }`}
              disabled={!isEdit}
              defaultValue={user?.name}
              placeholder="Enter your Name"
              {...register("name", {
                required: "Please Enter your Name",
              })}
            />
          </div>
          {errors.name && (
            <p className="text-red-500 text-xs mt-1">{errors.name.message}</p>
          )}
        </div>
        <div className="my-5">
          <label className="capitalize font-bold my-1" htmlFor="email">
            email :
          </label>
          <div
            className="flex items-center text-sm"
            onClick={() => {
              if (isEdit) {
                notify(
                  "Warning",
                  "You are not permitted edit your verified email",
                  "error"
                );
              }
            }}
          >
            <span className="inline-block w-6 h-6 mr-2">
              <i className="fa fa-envelope" aria-hidden="true"></i>
            </span>
            <input
              type="email"
              id="email"
              className={`border rounded w-full py-2 px-4 dark:dark-bg-front bg-front outline-none border-1 ${
                true ? "border-transparent " : "border-orange-300"
              }`}
              disabled={true}
              defaultValue={user?.email}
              placeholder="Enter your email"
            />
          </div>
        </div>
        <div className="my-5">
          <label className="capitalize font-bold my-1" htmlFor="img">
            Change Photo :
          </label>
          <div className="flex items-center   text-sm">
            <span className="inline-block w-6 h-6 mr-2">
              <i className="fa fa-camera" aria-hidden="true"></i>
            </span>
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              id="img"
              disabled={!isEdit}
              onChange={handleUploadImage}
              className={`border rounded w-full py-2 px-4 dark:dark-bg-front bg-front outline-none border-1 ${
                !isEdit ? "border-transparent " : "border-orange-300"
              }`}
            />
          </div>
          {errors.email && (
            <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>
          )}
          {/* {imgInputChange && <img src={imgInputChange} />} */}
        </div>
        {user.id === myProfile.id && (
          <div className="text-center">
            {isEdit && (
              <button
                disabled={uploading}
                type="submit"
                className="bg-green-500 rounded duration-300 click-effect mx-2 my-2 py-2 px-4 click-effect w-full"
              >
                {uploading ? <Spinner /> : <i className="fa fa-save"></i>} Save
                Profile
              </button>
            )}
          </div>
        )}
      </form>
    </div>
  );
};

export default ProfileRight;
