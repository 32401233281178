import React, { useEffect, useState } from "react";
import SectionHeader from "./../Components/SectionHeader/SectionHeader";
import { useParams } from "react-router-dom";
import NoItemFound from "../Components/SiteComponants/NoItemFound";
import ProductDetails from "../Components/ProductItem/ProductDetails";
import { TitleSetup } from "./../Datas/Functions/titleSetup";
import BreadCrumbs from "../Components/BreadCrumbs/BreadCrumbs";
import { useSiteData } from "../Contexts/SiteContext";
import LoadingBox from "../Components/SiteComponants/LoadingBox";
import { getSingleProducts } from "../Firebase/Realtime/Products";

const ProductItem = (meta) => {
  const [productItem, setProductItem] = useState(null);
  const { setIsLoading, } = useSiteData();
  const { pid } = useParams();

  useEffect(() => {
    setIsLoading(true);
    getSingleProducts(pid)
      .then((res) => {
        setProductItem(res.data);
        console.log(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [pid, setIsLoading]);
  TitleSetup({
    title: "Product : " + productItem?.name,
    description: productItem?.description,
    keywords: `${productItem?.name}, ${productItem?.category}`,
  });
  return (
    <div className="container my-0 md:my-6 font-sans">
      {productItem ? (
        <div className="py-5 w-full md:w-9/12 mx-auto">
          <SectionHeader heading={productItem?.name} />
          <BreadCrumbs texts={["Shop", "Products", productItem?.name]} />
          <ProductDetails productItem={productItem} />
        </div>
      ) : productItem === null ? (
        <LoadingBox />
      ) : (
        <NoItemFound msg={"Product Id is not correct"} />
      )}
    </div>
  );
};

export default ProductItem;
