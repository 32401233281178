import React, { useEffect, useState } from "react";
import { useSiteData } from "../../Contexts/SiteContext";
import { Link, useNavigate } from "react-router-dom";
import { isIncluded } from "../../Datas/Functions/shareFunctions";

const SearchItemSection = () => {
  const { products } = useSiteData();
  const navigate = useNavigate();
  const [showdatalist, setShowdatalist] = useState([]);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    const searchresult = products.filter(
      (prod) => isIncluded(prod.name, searchText) && searchText !== ""
    );
    setShowdatalist(searchresult);
    // console.log(searchresult);
  }, [searchText, products]);
  return (
    <div className="w-full  z-50   block py-6 lg:text-lg text-sm ">
      <div className="w-full md:container mx-auto p-4 flex flex-row items-center justify-center">
        <div
          className={`w-full md:w-1/2 relative`}
        >
          <div className="overflow-hidden box grid grid-cols-12 rounded-md border relative">
            <div className="hidden  h-full md:col-span-4 col-span-12 md:flex items-center justify-center bg-gray-700 text-orange-500 rounded-l-md capitalize">
              <span>Search Shop Products</span>
            </div>
            <input
              className="py-3 px-3 mx-0 col-span-12 md:col-span-8 focus:outline-none "
              type="text"
              placeholder="Search Product Names...🔎"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <span
              onClick={() => setSearchText("")}
              className="absolute top-3 right-1 opacity-50 hover:opacity-100 px-3 cursor-pointer"
            >
              {searchText ? (
                <i className="fa fa-times" aria-hidden="true"></i>
              ) : (
                <i className="fas fa-search"></i>
              )}
            </span>
          </div>
          <div
            className={`rounded z-100 absolute top-full left-0 w-full border transform duration-500 ${
              searchText && showdatalist.length >= 0
                ? "translate-y-0 opacity-100"
                : "translate-y-20 opacity-0"
            } `}
          >
            {showdatalist.length > 0
              ? showdatalist.map((item, i) => (
                  <div
                    className="bg-front dark:dark-bg-front border-b border-t p-3 duration-50 hover:text-orange-500 hover:shadow-lg hover:bg-back dark:hover:dark-bg-back grid grid-cols-12 gap-2  justify-center items-center group cursor-pointer"
                    onClick={() => navigate("/products/" + item.id)}
                  >
                    <div className="h-12 col-span-2">
                      <img
                        className=" max-h-full max-w-full mx-auto rounded-md"
                        src={item.photo}
                        alt={item.name}
                      />
                    </div>
                    <div className="col-span-10 flex-grow">
                      <h1 className="text-sm md:text-lg font-semibold flex items-center justify-between capitalize">
                        <span>{item.name}</span>{" "}
                        <span className="text-sm font-thin text-gray-400">
                          ({item.category})
                        </span>
                      </h1>
                      {/* <h1
                        className="text-xs md:text-sm description-output opacity-0 group-hover:opacity-100 font-thin flex items-center justify-between duration-50"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></h1> */}
                    </div>
                  </div>
                ))
              : searchText && (
                  <div className="bg-front dark:dark-bg-front border-b p-3 font-ser if flex flex-auto grid-col-12  justify-center items-center">
                    <h1 className="text-lg font-semibold flex items-center justify-between">
                      <i
                        className="fa fa-times-circle mr-2 inline-block text-red-600"
                        aria-hidden="true"
                      ></i>{" "}
                      <span>No such product found</span>
                    </h1>
                  </div>
                )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchItemSection;
