import React, { useEffect, useState } from "react";
import { TitleSetup } from "../Datas/Functions/titleSetup";
import ItemSection from "../Components/ItemSection/ItemSection";
import { useSiteData } from "../Contexts/SiteContext";
import SearchItemSection from "../Components/SearchItemSection/SearchItemSection";
import BreadCrumbs from "../Components/BreadCrumbs/BreadCrumbs";
import SectionHeader from "../Components/SectionHeader/SectionHeader";
import NoItemFound from "../Components/SiteComponants/NoItemFound";

const Products = (meta) => {
  const { products } = useSiteData();
  const [showProducts, setShowProducts] = useState([]);
  useEffect(() => {
    setShowProducts(products);
  }, [products]);
  TitleSetup({ ...meta });
  return (
    <>
      <SearchItemSection />
      <div className="container">
        <SectionHeader heading={"Products"} />
        <BreadCrumbs texts={["Shop", "Products"]} />
      </div>
      {products?.length > 0 ? (
        <ItemSection
          heading={""}
          prods={products}
          showAll={true}
          showCount={-1}
        />
      ) : (
        <NoItemFound msg={"No Product available"} />
      )}
    </>
  );
};

export default Products;
