import React, { useEffect, useState } from "react";
import SectionHeader from "../SectionHeader/SectionHeader";
import { useSiteData } from "../../Contexts/SiteContext";
import { isIncluded } from "../../Datas/Functions/shareFunctions";
import moment from "moment";
import { Tooltip } from "@mui/material";

const Last5Order = () => {
  const { orderList } = useSiteData();
  const [showLastFive, setShowLastFive] = useState([]);
  useEffect(() => {
    const newShowlist = orderList
      ?.filter((order) => isIncluded(order.order_status, "compl"))
      .sort((a, b) => parseInt(b.created_at) - parseInt(a.created_at))
      .slice(0, 5);
    setShowLastFive(newShowlist);
  }, [orderList]);
  return showLastFive.length > 0 ? (
    <div className="container my-20">
      <SectionHeader
        heading={"Recent Completed Orders"}
        className={"text-center"}
      />

      <div className="w-full   mx-auto">
        <div className="box  capitalize overflow-auto">
          <table className="table w-full text-left ">
            <thead>
              <tr className="border-b border-b-orange-500 ">
                <th className="pl-2 pr-5 md:py-5 py-2">Order No</th>
                <th className="pl-2 pr-5 md:py-5 py-2 text-center">Cutomer</th>
                <th className="pl-2 pr-5 md:py-5 py-2 text-center">Product</th>
                <th className="pl-2 pr-5 md:py-5 py-2 text-right">Purchased</th>
              </tr>
            </thead>
            <tbody className="">
              {showLastFive.map((order, i) => (
                <tr
                  key={i}
                  className="border-b last:border-b-0 border-b-orange-500"
                >
                  <td className="pl-2 pr-5 md:py-5 py-2">{i + 1}</td>
                  <td className="pl-2 pr-5 md:py-5 py-2 text-center">
                    {order.customer_name}
                  </td>
                  <td className="pl-2 pr-5 md:py-5 py-2 text-center">
                    <Tooltip title={<span>{order.product_name}</span>} arrow placement="top">
                      {order.order_name}
                    </Tooltip>
                  </td>
                  <td className="pl-2 pr-5 md:py-5 py-2  text-right">
                    {moment(parseInt(order.created_at)).format(
                      "DD/MM/YYYY hh:mm A"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default Last5Order;
