import React from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { notify } from "../../Datas/Functions/toast";
import ratingImg from "./../../Imgs/rating.png";
import { useAuthContext } from "../../Contexts/AuthContext";
import { Tooltip } from "@mui/material";
import { createReview } from "../../Firebase/Realtime/Review";

const ReviewTop = () => {
  const [rating, setRating] = useState(5);
  const { isLoggedin, user } = useAuthContext();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (!isLoggedin) {
      notify(
        "Operation Denied",
        "Please log in first, then try again",
        "error"
      );
      return;
    }

    data.rating = rating;
    data.name = user.name;
    data.userId = user.id;
    data.email = user.email;
    data.img = user.img;
    createReview(data)
      .then((res) => {
        notify("Success", res.message, "success");
      })
      .catch((err) => {
        notify("Warning", err.message, "error");
      });
    reset();
  };
  return (
    <div className="bg-front dark:dark-bg-front mb-4 shadow-lg rounded-lg p-4 grid grid-cols-1 md:grid-cols-2 md:gap-4 py-20">
      <div className="col-span-1 flex items-center justify-center">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col justify-center items-center gap-3 w-full"
        >
          <h2 className="text-4xl font-bold mb-2 text-center">Rate Us</h2>
          <div className="flex items-center justify-center ml-0 md:ml-4 my-3 ">
            {[1, 2, 3, 4, 5].map((value) => (
              <Tooltip
                key={value}
                title={
                  <span>
                    {value}
                    <i className="fa fa-star relative" aria-hidden="true"></i>
                  </span>
                }
                arrow
                placement="top"
              >
                <button
                  type="button"
                  onClick={() => setRating(value)}
                  className={`mr-1 focus:outline-none text-3xl md:text-2xl ${
                    value <= rating ? "text-yellow-500" : "text-gray-400"
                  }`}
                >
                  <i className="fa fa-star relative" aria-hidden="true">
                    <span className="absolute top-0 left-0 right-0 bottom-0 mx-auto mt-1 text-gray-900  text-xs ">
                      {value}
                    </span>
                  </i>
                </button>
              </Tooltip>
            ))}
          </div>
          <div className="flex flex-col justify-between my-6 md:my-0 w-full md:w-8/12 aspect-auto">
            <textarea
              type="text"
              placeholder="Please Write a Short Review..."
              className="font-mono no-scrollbar text-lg p-4 w-full md:w-auto focus:outline-none  border-orange-500 border my-2 rounded-lg"
              {...register("review", {
                required: "Please write a review",
              })}
            ></textarea>
            {errors.review && (
              <p className="text-red-500 text-xs mt-1">
                {errors.review.message}
              </p>
            )}
          </div>

          <button type="submit" className="site-btn py-2 px-4 mt-2 md:mt-0">
            Submit Review{" "}
            <i className="fa fa-paper-plane" aria-hidden="true"></i>
          </button>
        </form>
      </div>

      <div className="col-span-1 overflow-hidden w-full md:w-full mx-auto rounded-2xl md:flex justify-center items-center md:order-none py-4  hidden">
        <img className="" src={ratingImg} alt="Rating" />
      </div>
    </div>
  );
};

export default ReviewTop;
