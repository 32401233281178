import axios from "axios";

export const uploadImage = async (file) => {
  const formData = new FormData();
  formData.append("image", file);
  return await axios.post(
    `https://api.imgbb.com/1/upload?key=2e1357fecdbc012462246c5e0c29fb1c`,
    formData
  );
};
