import React from "react";

const SectionHeader = ({ heading, className }) => {
  return (
    heading && (
      <div className={" mb-5 mx-auto " + className}>
        <h2 className="text-[25px] md:text-[35px]  font-bold relative pb-[2px] pr-16 after:absolute after:bottom-0 after:left-0 inline-block after:w-full after:h-[3px] after:bg-gradient-to-r after:from-orange-500 after:to-transparent">
          {heading}
        </h2>
      </div>
    )
  );
};

export default SectionHeader;
