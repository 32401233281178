import React from "react";

const NoItemFound = ({ msg }) => {
  return (
    <div className="my-10 bg-front dark:dark-bg-front text-center md:text-4xl text-lg  md:w-1/2 md:mx-auto  mx-2 md:py-40 py-20 px-3 text-brand  block col-span-full ">
      <h1>{msg}</h1>
    </div>
  );
};

export default NoItemFound;
