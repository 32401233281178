// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// check project config
// const firebaseConfig = {
//   apiKey: "AIzaSyDjfakmWCpRedIcBcuUffAry9qz4ubWJj4",
//   authDomain: "chekerproject.firebaseapp.com",
//   databaseURL: "https://chekerproject-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "chekerproject",
//   storageBucket: "chekerproject.appspot.com",
//   messagingSenderId: "762368884028",
//   appId: "1:762368884028:web:feea932d4f02be8a833239"
// };

// thanos eshop er config
const firebaseConfig = {
  apiKey: "AIzaSyA9_QtsCrHdYbEb7fBIH5OlZN8tqHeuyec",
  authDomain: "thanos-eshop.firebaseapp.com",
  databaseURL: "https://thanos-eshop-default-rtdb.firebaseio.com",
  projectId: "thanos-eshop",
  storageBucket: "thanos-eshop.appspot.com",
  messagingSenderId: "602164689318",
  appId: "1:602164689318:web:8cd06cf33d4fc45d95f767",
  measurementId: "G-HCLDYFDEBB",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
