import {
  child,
  get,
  getDatabase,
  onChildChanged,
  ref,
  update,
} from "firebase/database";
import { app } from "./../firebase.config";
//   import { uuid } from "../../Datas/Functions/common";
import { getAuth } from "firebase/auth";
import { notifyMe } from "../../Datas/Functions/BrowserNotify";

const database = getDatabase(app);
// eslint-disable-next-line no-unused-vars
const auth = getAuth(app);
const mainPath = "common/";

// Notice Creation create (this function used only once while development to set the id )
// i have used it, now it is commented out
// export const createCommon = (data, id) => {
//   return new Promise((res, err) => {
//     try {
//       set(ref(database, mainPath + id), data);
//       return res({
//         message: "Successfully uploaded",
//       });
//     } catch (error) {
//       return err({
//         message: "Something went wrong",
//       });
//     }
//   });
// };

// Common update
export const updateCommon = (data, id) => {
  return new Promise((res, err) => {
    try {
      update(ref(database, mainPath + id), data);
      return res({
        message: "Notice Successfully updated",
      });
    } catch (error) {
      return err({
        error,
        message: "Something went wrong",
      });
    }
  });
};

// Notice delete
//   export const deleteNotice = (id) => {
//     return new Promise((res, err) => {
//       if (!id) {
//         return err({
//           message: "Item is not Seleted or not valid",
//         });
//       }
//       try {
//         remove(ref(database, mainPath + id));
//         return res({
//           message: "Selected Notice is successfully deleted",
//         });
//       } catch (error) {
//         return err({
//           message: "Something went wrong",
//         });
//       }
//     });
//   };

// get The Common
export const getCommon = (id) =>
  new Promise((res, err) => {
    get(child(ref(database), mainPath + id))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const dataSnap = snapshot.val();
          return res({
            message: "Common data fetched",
            data: dataSnap,
          });
        } else {
          err({
            message: "No data found",
          });
        }
      })
      .catch((error) => {
        err({
          message: "Something went wrong",
        });
      });
  });

// get The order track
export const keepOrdertracking = (user) => {
  onChildChanged(ref(getDatabase(), "common/trackOrder"), (data) => {
    if (user?.auth.isAdmin) {
      notifyMe("Dear " + user?.name + ", New Order Arrived", data.val());
    }
  });
};
