import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./Components/SiteComponants/CommonCss.css";
import Navbar from "./Components/Navbar/Navbar";
import { routeDatas } from "./Datas/RouteDatas/MainRoutes";
import FooterBottom from "./Components/FooterBottom/FooterBottom";
import { createContext, useEffect } from "react";
import Footer from "./Components/Footer/Footer";
import { dashboardRoutes } from "./Datas/RouteDatas/DashboardRoutes";
import { useSiteData } from "./Contexts/SiteContext";
import AllExtraComponantInOne from "./Components/SiteComponants/AllExtraComponantInOne";
import { getDatabase, onValue, ref } from "firebase/database";
import { app } from "./Firebase/firebase.config";
import { bringItToFront } from "./Datas/Functions/shareFunctions";
export const ProductContext = createContext();
function App() {
  const { isReloading, setIsReloading, setProducts, setOrderList } =
    useSiteData();
  useEffect(() => {
    setIsReloading(false);
  }, [isReloading]);
  // product loaded
  useEffect(() => {
    return onValue(ref(getDatabase(app), "products/"), (snapshot) => {
      if (snapshot.exists()) {
        const productSnap = snapshot.val();
        const newProducts = bringItToFront(
          Object.values(productSnap).sort(
            (a, b) => a.created_at - b.created_at
          ),
          "pubg",
          "category"
        );
        setProducts(newProducts);
      } else {
        // console.log("Products Not Found");
      }
    });
  }, []);
  useEffect(() => {
    return onValue(ref(getDatabase(app), "orders/"), (snapshot) => {
      if (snapshot.exists()) {
        const orderSnap = snapshot.val();
        setOrderList(
          Object.values(orderSnap).sort((a, b) => b.created_at - a.created_at)
        );
      } else {
        // console.log("Orders Not Found");
      }
    });
  }, []);
  return (
    <div className=" dark:dark-text">
      <AllExtraComponantInOne />
      <Navbar />
      <Routes>
        {/* all routes */}
        {routeDatas.map((route) => {
          return (
            route.visibility && (
              <Route key={route.route} path={route.route} element={route.child}>
                {route.route === "/dashboard" &&
                  dashboardRoutes.map((ot) => (
                    <Route key={ot.link} path={ot.link} element={ot.comp} />
                  ))}
              </Route>
            )
          );
        })}
      </Routes>
      <Footer />
      <FooterBottom />
    </div>
  );
}

export default App;
