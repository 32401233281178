import moment from "moment";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { isIncluded } from "./shareFunctions";

// create array of fill a certein item
export const fill = (fillValue, count) => {
  let ans = [];
  for (let index = 0; index < count; index++) {
    ans.push(fillValue);
  }
  return ans;
};

// create array of days in months
export const monthHas_N_days = (month, year) => {
  let data = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) data[1]++;
  return data[month];
};

// create array using range
export const range = (start, end) => {
  let ans = [];
  for (let index = start; index <= end; index++) {
    ans.push(index);
  }
  return ans;
};

// creating taka
export const numToBDT = (num) => {
  return parseInt(num).toLocaleString("en-US", {
    currency: "BDT",
    style: "currency",
    maximumFractionDigits: 0,
  });
};

// create array using range
export const uuid = (options = {}) => {
  const {
    start = "",
    end = "",
    len = 25,
    digit = true,
    lower = true,
    upper = true,
    hypen = false,
  } = options;

  const digits = "0123456789";
  const alphabetsUpper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const alphabetsLower = "abcdefghijklmnopqrstuvwxyz";

  let uuid = start;
  let i = 0;
  let strList = "";
  strList += digit ? digits : "";
  strList += lower ? alphabetsLower : "";
  strList += upper ? alphabetsUpper : "";
  const finalList = strList.split("").sort((a, b) => Math.random() - 0.5);
  const modulo = finalList.length;
  while (i < len) {
    if (
      hypen &&
      (i === Math.floor(len / 4) || i === Math.floor((len * 2) / 3))
    ) {
      uuid += "-";
    }
    const randomIndex =
      Math.floor(Math.random() * Math.random() * modulo) % modulo;
    uuid += finalList[randomIndex];
    ++i;
  }

  uuid += end;
  return uuid;
};

// social class name maker
export const findClass = (name = "") => {
  return isIncluded(name, "whatsapp")
    ? "text-green-700"
    : isIncluded(name, "telegram")
    ? "text-cyan-600"
    : "text-blue-700";
};
// social link maker
export const findLink = (info) => {
  return info
    ? isIncluded(info?.key, "whatsapp")
      ? "https://wa.me/" + info?.value
      : info?.value
    : "";
};
