import React from "react";
import noAvatar from "./../../Imgs/favicon.png";
import { useNavigate } from "react-router-dom";
// import { useSiteData } from "../../Contexts/SiteContext";
import { useAuthContext } from "../../Contexts/AuthContext";
import { notify } from "../../Datas/Functions/toast";
const ProfileLeft = ({ user = {}, isEdit, setIsEdit }) => {
  // const { isAdmin } = useSiteData();
  const { user: myProfile } = useAuthContext();
  const navigate = useNavigate();
  return (
    <div className="col-span-1 p-3 dark:dark-bg-front bg-front rounded-lg box">
      <h2 className="text-2xl font-bold mb-2 text-center">Profile Picture</h2>

      <img
        src={user?.img || noAvatar}
        alt="Profile"
        className="rounded-3xl w-24 md:w-32 lg:w-40 mx-auto h-auto my-5"
        title={
          user?.img ? "" : "Temporary PlaceHolder for Your Porfile Picture"
        }
      />

      <h2 className="text-xl font-thin my-5 text-center overflo w-auto no-scrollbar">
        User ID <br />
        <span
          className="text-brand mr-1 cursor-pointer font-bold "
          onClick={() => {
            navigator.clipboard.writeText(user?.id);
            notify("User ID Copied");
          }}
        >
          {user?.id.slice(0,15)}
        </span>
      </h2>
      {myProfile?.id === user?.id && (
        <div className="rounded p-3">
          <h1 className="text-lg capitalize font-bold my-2">
            Profile Options :
          </h1>
          <ul>
            {[
              {
                title: "Change Password",
                icon: <i className="fa fa-lock" aria-hidden="true"></i>,
                onclickFunction: () => navigate("/dashboard/change-password"),
              },
              {
                title: isEdit ? "Cancel Edit" : "Edit Profile",
                icon: isEdit ? (
                  <i className="fa fa-times"></i>
                ) : (
                  <i className="fa fa-edit "></i>
                ),
                onclickFunction: () => setIsEdit((prev) => !prev),
              },
            ].map((li, i) => (
              <li
                key={i}
                onClick={li.onclickFunction}
                className="hover:bg-orange-500 duration-150 py-3 px-2 flex flex-wrap justify-between items-center border first:rounded-t last:rounded-b cursor-pointer"
              >
                <span>{li.icon}</span>
                <span>{li.title}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProfileLeft;
