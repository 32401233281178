import { createContext, useContext } from "react";
import { useEffect, useState } from "react";
import { app } from "../Firebase/firebase.config";
import { getCommon } from "../Firebase/Realtime/Common";

const SiteContext = createContext();

export const useSiteData = () => {
  return useContext(SiteContext);
};

export const SiteProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(localStorage.getItem("theme") || "dark");

  // Site Data section
  const [cart, setCart] = useState(null);
  const [products, setProducts] = useState([]);
  const [userList, setUserList] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [allSocial, setAllSocial] = useState([]);

  // converted data section
  const [categories, setCategories] = useState([]);
  const [isReloading, setIsReloading] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const [metaInfo, setMetaInfo] = useState({});
  const [checkoutData, setCheckoutData] = useState(null);

  // **************
  // theme section
  // **************
  useEffect(() => {
    if (isDark === "dark") document.body.classList.add("dark");
    else document.body.classList.remove("dark");
    setIsDark(localStorage.getItem("theme"));
  }, [isDark]);
  useEffect(() => {
    if (localStorage.getItem("theme") === null)
      localStorage.setItem("theme", "dark");
  }, []);

  // Social loaded
  useEffect(() => {
    getCommon("social")
      .then((res) => {
        const newSocial = Object.entries(res.data).map(([key, value]) => ({
          key,
          value,
        }));
        setAllSocial(newSocial);
        // console.log({ newSocial });
      })
      .catch((err) => {});
  }, []);
  // **************
  //   Products & categories values
  // **************
  useEffect(() => {
    //categories section

    const newObject = products?.reduce((obj, prd) => {
      if (obj?.hasOwnProperty(prd.category)) {
        obj[prd.category].push(prd);
      } else {
        obj[prd.category] = [prd];
      }

      return obj;
    }, {});
    let newCategories = Object.entries(newObject).map(([title, prods]) => {
      return { title, prods };
    });
    setCategories(newCategories);
  }, [products]);

  // **************
  //   final values
  // **************
  const value = {
    // theme
    isDark,
    setIsDark,
    // theme
    cart,
    setCart,
    // product infos
    products,
    setProducts,
    // user info
    userList,
    setUserList,
    // order info
    orderList,
    setOrderList,

    // review info
    reviewList,
    setReviewList,

    // social links
    allSocial,
    setAllSocial,

    // checkout info
    checkoutData,
    setCheckoutData,

    // category
    categories,

    // loading criteria
    isloading,
    setIsLoading,
    isReloading,
    setIsReloading,

    // SEO meta tag info
    metaInfo,
    setMetaInfo,
  };
  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
};
