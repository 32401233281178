import React, { useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

import { useSiteData } from "../../Contexts/SiteContext";
import { useEffect } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { isIncluded } from "../../Datas/Functions/shareFunctions";
ChartJS.register(ArcElement, Tooltip, Legend);

const SellPieChart = () => {
  const { orderList } = useSiteData();
  const [completedOrderList, setCompletedOrderList] = useState([]);
  const [showSellList, setShowSellList] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: 1262336820000,
    endDate: new Date().getTime(),
  });

  useEffect(() => {
    const newCompletedOrder = orderList.filter((ord) =>
      isIncluded(ord.order_status, "complete")
    );
    setCompletedOrderList(newCompletedOrder);
  }, [orderList]);

  const getChartData = () => {
    // console.log({ completedOrderList });
    const newSellList = completedOrderList.reduce((res, ord) => {
      const newStartDate = new Date(dateRange.startDate).getTime();
      const newEndDate = new Date(dateRange.endDate).getTime();
      //    console.log({
      //   ordedata: ord,
      //   newStartDate,
      //   newEndDate,
      // });
      if (
        newStartDate <= parseInt(ord.created_at) &&
        newEndDate >= parseInt(ord.created_at)
      ) {
        if (!res[ord.product_name]) {
          res[ord.product_name] = {
            title: ord.product_name,
            profit: parseInt(ord.order_price),
            sellCount: 1,
          };
        } else {
          res[ord.product_name].sellCount++;
          res[ord.product_name].profit += parseInt(ord.order_price);
        }
      }
      return res;
    }, {});
    const newShowList = Object.entries(newSellList).map(
      ([heading, object]) => ({
        title: object.title + " - Sell " + object.sellCount,
        profit: object.profit,
      })
    );
    return newShowList;
  };
  useEffect(() => {
    const newdatalist = getChartData();
    setShowSellList(newdatalist);
  }, [dateRange, completedOrderList]);
  return (
    <div className=" col-span-12 md:col-span-6">
      <div className="box bg-front dark:dark-bg-front dark:dark-text p-4">
        <h2 className="text-lg font-semibold mb-4">Sells in Date Range</h2>
        <Datepicker 
          useRange={false}
          showFooter={true}
          placeholder={"Select a Date Range"}
          minDate={new Date("2001-01-01").getTime()}
          maxDate={new Date().getTime() + 3600 * 1000 * 24}
          value={dateRange}
          onChange={(d) => setDateRange(d)}
          showShortcuts={true}
          displayFormat={"DD MMMM, YYYY"}
          containerClassName="relative border-2 rounded-md"
        />
        {showSellList.length > 0 ? (
          <div>
            <Pie
              data={{
                labels: showSellList.map((sell) => sell.title) || ["0"],
                datasets: [
                  {
                    label: "Profit in BDT ",
                    data: showSellList.map((sell) => sell.profit) || [0],
                    backgroundColor: [
                      "rgba(255, 99, 132, 0.2)",
                      "rgba(54, 162, 235, 0.2)",
                      "rgba(255, 206, 86, 0.2)",
                      "rgba(233, 192, 192, 0.2)",
                      "rgba(153, 102, 255, 0.2)",
                      "rgba(255, 159, 244, 0.2)",
                      "rgba(255, 206, 86, 0.2)",
                      "rgba(75, 244, 192, 0.2)",
                      "rgba(153, 102, 255, 0.2)",
                      "rgba(178, 58, 244, 0.2)",
                      "rgba(153, 102, 255, 0.2)",
                      "rgba(137, 199, 154, 1)",
                      "rgba(132, 206, 86, 0.2)",
                      "rgba(75, 154, 192, 0.2)",
                      "rgba(153, 165, 188, 0.2)",
                      "rgba(188, 58, 244, 0.2)",
                    ],
                    borderColor: [
                      "rgba(255, 99, 132, 1)",
                      "rgba(99, 255, 132, 1)",
                      "rgba(132, 99, 255, 1)",
                      "rgba(255, 132, 99, 1)",
                      "rgba(99, 132, 255, 1)",
                      "rgba(255, 255, 99, 1)",
                      "rgba(99, 255, 255, 1)",
                      "rgba(255, 99, 255, 1)",
                      "rgba(255, 0, 0, 1)",
                      "rgba(0, 255, 0, 1)",
                      "rgba(0, 0, 255, 1)",
                      "rgba(255, 128, 0, 1)",
                      "rgba(0, 255, 128, 1)",
                      "rgba(128, 0, 255, 1)",
                      "rgba(255, 0, 128, 1)",
                      "rgba(128, 255, 0, 1)",
                      "rgba(0, 128, 255, 1)",
                      "rgba(128, 128, 0, 1)",
                      "rgba(0, 128, 128, 1)",
                      "rgba(128, 0, 128, 1)",
                      "rgba(192, 192, 192, 1)",
                      "rgba(128, 128, 128, 1)",
                      "rgba(0, 0, 0, 1)",
                      "rgba(255, 0, 0, 0.5)",
                      "rgba(0, 255, 0, 0.5)",
                      "rgba(0, 0, 255, 0.5)",
                      "rgba(255, 128, 0, 0.5)",
                      "rgba(0, 255, 128, 0.5)",
                      "rgba(128, 0, 255, 0.5)",
                      "rgba(255, 0, 128, 0.5)",
                      "rgba(128, 255, 0, 0.5)",
                      "rgba(0, 128, 255, 0.5)",
                      "rgba(128, 128, 0, 0.5)",
                      "rgba(0, 128, 128, 0.5)",
                      "rgba(128, 0, 128, 0.5)",
                      "rgba(192, 192, 192, 0.5)",
                      "rgba(128, 128, 128, 0.5)",
                      "rgba(0, 0, 0, 0.5)",
                      "rgba(255, 0, 0, 0.2)",
                      "rgba(0, 255, 0, 0.2)",
                      "rgba(0, 0, 255, 0.2)",
                      "rgba(255, 128, 0, 0.2)",
                      "rgba(0, 255, 128, 0.2)",
                      "rgba(128, 0, 255, 0.2)",
                      "rgba(255, 0, 128, 0.2)",
                      "rgba(128, 255, 0, 0.2)",
                      "rgba(0, 128, 255, 0.2)",
                      "rgba(128, 128, 0, 0.2)",
                      "rgba(0, 128, 128, 0.2)",
                      "rgba(128, 0, 128, 0.2)",
                      "rgba(192, 192, 192, 0.2)",
                      "rgba(128, 128, 128, 0.2)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
        ) : (
          <h1 className="my-5 text-center p-3 text-xl">
            {!(dateRange.startDate && dateRange.endDate)
              ? "Select Date range to Show Sells"
              : "No Sale found in this date range"}
          </h1>
        )}
      </div>
    </div>
  );
};

export default SellPieChart;
