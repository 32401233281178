import {
  child,
  get,
  getDatabase,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import { app } from "./../firebase.config";
import { uuid } from "../../Datas/Functions/common";
import { getAuth } from "firebase/auth";
import {
  notifyInMail,
} from "../../Datas/Functions/BrowserNotify";

const database = getDatabase(app);
const auth = getAuth(app);
//console.log(auth.currentUser);
const mainPath = "orders/";

// Product create
export const createOrder = (data) => {
  return new Promise((res, err) => {
    const id = uuid({
      start: "ord_",
      len: 25,
      digit: true,
      lower: true,
      upper: true,
      hypen: false,
    });
    data.id = id;
    data.created_at = new Date().getTime();
    try {
      set(ref(database, mainPath + id), data);
      update(ref(database, "common/trackOrder"), { track: data.id });
      notifyInMail(data);
      return res({
        message: "Thanks for Ordering!",
        data,
      });
    } catch (error) {
      //console.log({ productUpdateError: error });
      return err({
        message: "Product Order : Something went wrong while ordering",
      });
    }
  });
};

// ORder update
export const updateOrder = (data, id) => {
  return new Promise((res, err) => {
    if (!id) {
      return err({
        message: "Item is not Seleted or not valid",
      });
    }
    try {
      update(ref(database, mainPath + id), data);
      return res({
        message: "Order Successfully Updated",
      });
    } catch (error) {
      return err({
        error,
        message: "Order Update : Something went wrong",
      });
    }
  });
};

// Order delete
export const deleteOrder = (id) => {
  return new Promise((res, err) => {
    if (!id) {
      return err({
        message: "Item is not Seleted or not valid",
      });
    }
    try {
      remove(ref(database, mainPath + id));
      return res({
        message: "The Order is successfully deleted",
      });
    } catch (error) {
      return err({
        message: "Delete Order : Something went wrong",
      });
    }
  });
};

// get a single Product
export const getSingleOrder = (id) =>
  new Promise((res, err) => {
    get(child(ref(database), mainPath + id))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const orderSnap = snapshot.val();
          return res({
            message: "The Order is fetched",
            data: orderSnap,
          });
        } else {
          err({
            message: "No data found",
          });
        }
      })
      .catch((error) => {
        err({
          message: "Single Order : Something went wrong",
        });
      });
  });

// get all Orders
export const getOrders = () =>
  new Promise((res, err) => {
    get(child(ref(database), mainPath))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const productSnap = snapshot.val();
          return res({
            message: "All Orders fetched",
            data: Object.values(productSnap).sort(
              (a, b) => b.created_at - a.created_at
            ),
          });
        } else {
          err({
            message: "No Orders found",
          });
        }
      })
      .catch((error) => {
        err({
          message: "Something went wrong",
        });
      });
  });
