import React from "react";
import { useSiteData } from "../../Contexts/SiteContext";
import { Helmet } from "react-helmet-async";
import img from "./../../Imgs/brand_logo.png"
const DynamicMetatag = () => {
  const { metaInfo, setMetaInfo } = useSiteData();
  const fakeDescription =
    "ThanosE-shop is a safe & trusted Gaming currency and online Subcription selling site";
  const fakeTitle = "Thanos Gaming Shop";
  const fakeKeywords = "PUBG, Game, Subscription, Gift Card";

  return (
    <Helmet>
      <title>{metaInfo.title || fakeTitle}</title>
      <meta
        name="description"
        content={metaInfo.description || fakeDescription}
      />
      <meta name="keywords" content={fakeKeywords} />
      <meta property="og:title" content="Thanos E-shop" />
      <meta property="og:type" content="site.shopping" />
      <meta property="og:url" content="https://thanoseshop.com" />
      <meta
        property="og:image"
        content={img}
      />
    </Helmet>
  );
};

export default DynamicMetatag;
